import { PRIVACYKEY_SET_PAGEABLE_PRIVACYKEYS } from './constants';

export const initialState = {
	pageablePrivacykeys: {
		content: [],
	},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case PRIVACYKEY_SET_PAGEABLE_PRIVACYKEYS: {
			return {
				...state,
				pageablePrivacykeys: action.pageablePrivacykeys,
			};
		}
		default:
			return state;
	}
};

import {
	ACCOUNT_SET_TOKEN_DATA,
	ACCOUNT_REQUEST_MY_USER_DATA,
	ACCOUNT_SET_MY_USER_DATA,
	ACCOUNT_SET_MY_ALTERNATIVE_ROLE_VIEW,
} from './constants';

// ########## ########## ########## ##########
// saga actions

export const requestMyUserDataAction = () => {
	//[DEBUG] console.log('requestMyUserDataAction start');
	return {
		type: ACCOUNT_REQUEST_MY_USER_DATA,
	};
};

// ########## ########## ########## ##########
// redux actions

export const setTokenDataAction = myUserData => {
	//[DEBUG] console.log('setTokenDataAction userData =', myUserData);
	return {
		type: ACCOUNT_SET_TOKEN_DATA,
		myUserId: myUserData.myUserId,
		myCompanyId: myUserData.myCompanyId,
	};
};

export const setMyUserDataAction = myUserData => {
	//[DEBUG] console.log('setMyUserDataAction myUserData = ', myUserData);
	return {
		type: ACCOUNT_SET_MY_USER_DATA,
		myUserData,
	};
};

export const setMyAlternativeRoleView = alternativeRoleView => {
	//[DEBUG] console.log('setMyAlternativeRoleView alternativeRoleView = ', alternativeRoleView);
	return {
		type: ACCOUNT_SET_MY_ALTERNATIVE_ROLE_VIEW,
		alternativeRoleView,
	};
};

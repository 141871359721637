import request from '../request';
import {
	AUTH_URL_LOGOUT_USER,
	AUTH_URL_REQUEST_TOKEN,
	AUTH_URL_ACCEPT_TERMS_FOR_COMPANY_func,
	AUTH_URL_REVOKE_TERMS_FOR_COMPANY_func,
} from '../constants/url-paths';
import { authToken } from '../session-helpers';

export const logoutUserAPI = () => {
	//[DEBUG] console.log('logoutUserAPI start');
	return request(AUTH_URL_LOGOUT_USER, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authToken()}`,
		},
	}).then(response => Promise.resolve(response));
};

export const requestTokenAPI = () => {
	//[DEBUG] console.log('requestTokenAPI start');
	return request(AUTH_URL_REQUEST_TOKEN, {
		method: 'GET',
		credentials: 'include',
	}).then(response => Promise.resolve(response));
};

export const requestTokenAuthorizatedAPI = () => {
	//[DEBUG] console.log('requestTokenAuthorizatedAPI start');
	return request(AUTH_URL_REQUEST_TOKEN, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authToken()}`,
		},
	}).then(response => Promise.resolve(response));
};

export const acceptTermsForCompanyAPI = payload => {
	//[DEBUG] console.log('acceptTermsForCompanyAPI payload = ', payload);
	const { companyObject } = payload;
	const { companyId } = companyObject;
	return request(AUTH_URL_ACCEPT_TERMS_FOR_COMPANY_func({ companyId }), {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authToken()}`,
		},
	}).then(response => Promise.resolve(response));
};

export const revokeTermsForCompanyAPI = payload => {
	//[DEBUG] console.log('revokeTermsForCompanyAPI payload = ', payload);
	const { companyObject } = payload;
	const { companyId } = companyObject;
	return request(AUTH_URL_REVOKE_TERMS_FOR_COMPANY_func({ companyId }), {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authToken()}`,
		},
	}).then(response => Promise.resolve(response));
};

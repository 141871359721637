import {
	FEATURES_GET_PAGEABLE_FEATURES,
	// FEATURES_GET_ALL_FEATURES,
	FEATURES_SET_ALL_FEATURES,
} from './constants';

export const getPageableFeaturesAction = payload => {
	return {
		type: FEATURES_GET_PAGEABLE_FEATURES,
		payload,
	};
};

export const getAllFeaturesAction = () => {
	const pageableParameters = {
		page: 0,
		size: 999,
		sortBy: 'feature',
		order: 'ASC',
		deleted: 0,
	};

	return getPageableFeaturesAction({ pageableParameters });
};

export const setAllFeaturesAction = features => {
	return {
		type: FEATURES_SET_ALL_FEATURES,
		features,
	};
};

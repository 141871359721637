import { FEATURES_SET_ALL_FEATURES } from './constants';

const initialState = {
	features: [],
	/**
	 * Example:
	 * [
	 *  {
	 *  	id: '504684641396285009',
	 *  	deleted: false,
	 *  	feature: 'AUTHENTICATION_SERVICE',
	 *  	permissions: [
	 *  		{
	 *  			id: '1004302725057699409',
	 *  			deleted: false,
	 *  			permission: 'USER_COMPANY_ADMIN',
	 *  		},
	 *  		{
	 *  			id: '1076360319095627345',
	 *  			deleted: false,
	 *  			permission: 'USER_COMPANY_VIEWER',
	 *  		},
	 *  		{
	 *  			id: '144396671206645329',
	 *  			deleted: false,
	 *  			permission: 'AS_AUTHENTICATION',
	 *  		},
	 *  	],
	 *  	label: null,
	 *  },
	 * ... ]
	 */
};

export default (state = initialState, action) => {
	switch (action.type) {
		case FEATURES_SET_ALL_FEATURES: {
			return {
				...state,
				features: action.features,
			};
		}
		default:
			return {
				...state,
			};
	}
};

// import { AUTH_SET_AUTHENTICATED, AUTH_SET_TOKEN_DATA } from './constants';
import { AUTH_SET_AUTHENTICATED } from './constants';

const initialState = {
	authenticated: false,
	// myUserId: '',
	// myCompanyId: '',
};

export default (state = initialState, action) => {
	switch (action.type) {
		case AUTH_SET_AUTHENTICATED: {
			return {
				...state,
				authenticated: action.authenticated,
			};
		}
		/*
		case AUTH_SET_TOKEN_DATA: {
			console.log('AUTH_SET_TOKEN_DATA action.myUserId =', action.myUserId);
			console.log('AUTH_SET_TOKEN_DATA action.myCompanyId =', action.myCompanyId);
			return {
				...state,
				myUserId: action.myUserId,
				myCompanyId: action.myCompanyId,
			};
		}
		*/
		default:
			return {
				...state,
			};
	}
};

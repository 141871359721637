import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
	palette: {
		primary: { main: '#0079B3' },
		secondary: { main: '#F2B334' },
		grey: {
			main: '#B1BAC5',
			dark: '#4C677E',
			light: '#D1D8E1',
		},
		background: {
			main: '#EEF4F7',
		},
	},
	status: {
		success: '#99D360',
		error: '#EC5C57',
		info: '#FAC922',
	},
	typography: {
		useNextVariants: true,
		fontFamily: ['Lato', 'Roboto', 'Arial', 'sans-serif'].join(','),
		color: '#9BA5B7',
		fontSize: '13px',
		lineHeight: '16px',
		h1: {
			fontSize: '18px',
			fontWeight: 'bold',
			lineHeight: '22px',
			color: '#4C677E',
		},
		h2: {
			fontSize: '16px',
			fontWeight: 'bold',
			lineHeight: '19px',
			color: '#4C677E',
		},
		h3: {
			fontSize: '16px',
			lineHeight: '19px',
			color: '#4C677E',
		},
		h4: {
			fontSize: '14px',
			lineHeight: '17px',
			color: '#4C677E',
		},
		h5: {
			fontSize: '12px',
			lineHeight: '15px',
			color: '#4C677E',
		},
		h6: {
			fontSize: '14px',
			lineHeight: '17px',
			fontWeight: 'bold',
			color: '#4C677E',
		},
		subtitle1: {
			fontSize: '13px',
			color: '#FFF',
			// color: '#9ba5b7',
			margin: 0,
		},
		subtitle2: {
			fontSize: '12px',
			color: '#FFF',
			margin: 0,
		},
		caption: {
			display: 'inline-block',
			width: '300px',
			color: '#808fa3',
		},
	},
	overrides: {
		MuiTooltip: {
			tooltip: {
				color: '#fff',
				fontSize: '12px',
				fontFamily: 'Lato',
				background: '#414449',
				opacity: 1,
				fontWeight: 'normal',
				lineHeight: 'normal',
				maxWidth: '310px',
			},
		},
		MuiButton: {
			text: {
				color: 'white',
				background: '#0081C5',
				borderRadius: '2px',
				'&:hover': {
					background: '#0081C5',
					opacity: '0.7',
				},
			},
			outlined: {
				color: '#0081C5',
				borderColor: '#0081C5',
				borderRadius: '2px',
				'&:hover': {
					borderColor: '#0081C5',
					opacity: '0.7',
				},
			},
			sizeSmall: {
				height: '40px',
				width: '162px',
			},
			root: {
				'&$disabled': {
					color: '#ffff',
					opacity: '0.6',
				},
			},
		},
		MuiFormControl: {
			root: {
				marginTop: 10,
				marginBottom: 2,
			},
		},
		MuiInput: {
			root: {
				backgroundColor: '#ffffff',
				boxSizing: 'border-box',
				borderRadius: 3,
				border: '1px solid #cecece',
				'&:before, &:after': {
					borderBottom: 'none !important',
				},
			},
			input: {
				padding: 14,
				color: '#111111',
			},
			error: {
				border: '1px solid #ec5c57',
			},
		},
		MuiInputLabel: {
			root: {
				color: '#808fa3',
			},
		},
		MuiIconButton: {
			root: {
				cursor: 'pointer',
				color: '#9ba5b7',
			},
		},
		MuiTab: {
			textColorInherit: {
				opacity: 1,
				color: '#4C677E',
			},
			root: {
				maxWidth: 'unset',
				'&:hover': {
					color: '#0079B3',
				},
				'&$selected': {
					color: '#0079B3',
					lineHeight: '17px',
					fontWeight: 'bold',
					textAlign: 'center',
				},
			},
			label: {
				textTransform: 'none',
			},
			labelContainer: {
				justifyContent: 'center',
				display: 'flex',
				paddingTop: '0 !important',
				paddingBottom: '0 !important',
			},
			wrapper: {
				borderLeft: '1px solid #4C677E',
				margin: '5px 0',
			},
		},
		MuiSnackbar: {
			root: {
				background: '#FFFFFF',
				height: '37px',
				width: '416px',
				borderRadius: '3px',
			},
		},
		MuiDrawer: {
			root: {
				color: '#FFFFFF',
			},
			paper: {
				backgroundColor: '#0079B3',
				color: '#FFFFFF',
			},
		},
		MuiFormControlLabel: {
			root: {
				marginLeft: '-10px',
			},
			label: {
				color: '#9BA5B7',
				fontSize: 12,
			},
		},
		MuiLink: {
			root: {
				fontFamily: 'Lato',
				fontSize: 12,
				color: '#0081c5',
				cursor: 'pointer',
			},
		},
		MuiSwitch: {
			root: {
				width: 51,
				height: 31,
				marginLeft: 10,
			},
			bar: {
				opacity: '1 !important',
				width: 51,
				height: 31,
				borderRadius: '15.5px',
				backgroundColor: '#cdd6e4',
			},
			iconChecked: {
				marginRight: -10,
				marginLeft: 0,
			},
			icon: {
				width: 29,
				height: 29,
				backgroundColor: '#ffffff',
				boxShadow: 'none',
				marginLeft: 2,
			},
		},
		MuiTableHead: {
			root: {
				fontFamily: 'Lato',
				fontSize: '11px',
				color: '#808fa3',
			},
		},
		MuiTableBody: {
			root: {
				backgroundColor: '#ffffff',
				fontFamily: 'Lato',
				color: '#4c677e',
				fontSize: '12px',
			},
		},
		MuiTableRow: {
			root: {
				'&:hover': {
					backgroundColor: '#f7f9fc',
				},
			},
		},
		MuiTableCell: {
			root: {
				paddingLeft: '10px',
				paddingTop: '13px',
				paddingBottom: '13px',
				whiteSpace: 'nowrap',
				'@media (max-width: 600px)': {
					whiteSpace: 'normal',
				},
			},
		},
		MuiFormHelperText: {
			error: {
				fontSize: '12px',
			},
		},
		MuiCheckbox: {
			root: {
				'&$checked': {
					color: '#0079b3 !important',
				},
			},
		},
		MuiBackdrop: {
			root: { backgroundColor: 'rgba(0, 0, 0, 0.2)' },
		},
	},
});

export default theme;

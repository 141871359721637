import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';

import { setGeneralErrorMessageAction } from '../../../saga-redux-modules/general/action';

import NXPPopup from '../popup'; // Depends on NXPPopup which has redux state!!!

const GeneralErrorDialog = ({ generalErrorObject, setGeneralErrorMessage }) => {
	// const { t } = useTranslation(['form', 'general']);

	const title =
		generalErrorObject && (generalErrorObject.title || 'General error message on API');
	const subtitle = generalErrorObject && generalErrorObject.subtitle;
	const open = generalErrorObject && Object.keys(generalErrorObject).length > 0;
	const actionButtonLabel =
		generalErrorObject && (generalErrorObject.buttonLabel || 'Confirm');
	const hint =
		generalErrorObject &&
		(generalErrorObject.hint || 'Please report the problem to the support.');

	const text = generalErrorObject && generalErrorObject.text;

	return (
		<NXPPopup
			title={title}
			subtitle={subtitle}
			description={<span>{text}</span>}
			open={open}
			onCancel={x => x}
			onConfirm={x => x}
			action={setGeneralErrorMessage}
			actionButtonLabel={actionButtonLabel}
			hint={hint}
			hideCloseElements
			colorRed={false}
			buttonStyle={{ width: 115 }}
		/>
	);
};

GeneralErrorDialog.propTypes = {
	generalErrorObject: PropTypes.object.isRequired,
	setGeneralErrorMessage: PropTypes.func.isRequired,
};

export const mapStateToProps = ({ generalReducer }) => {
	return {
		generalErrorObject: generalReducer.generalErrorObject,
	};
};

export const mapDispatchToProps = dispatch => {
	return {
		setGeneralErrorMessage: generalErrorObject =>
			dispatch(setGeneralErrorMessageAction(generalErrorObject)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralErrorDialog);

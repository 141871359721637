import {
	PRIVACYKEY_GET_PAGEABLE_PRIVACYKEYS,
	PRIVACYKEY_SET_PAGEABLE_PRIVACYKEYS,
	PRIVACYKEY_REGISTER_PRIVACYKEY,
	PRIVACYKEY_UPDATE_PRIVACYKEY,
	PRIVACYKEY_DELETE_PRIVACYKEY,
} from './constants';

// ########## ########## ########## ##########
// saga actions

export const getPageablePrivacykeysAction = payload => {
	//[DEBUG] console.log('getPageablePrivacykeysAction, payload =', payload);
	return {
		type: PRIVACYKEY_GET_PAGEABLE_PRIVACYKEYS,
		payload,
	};
};

export const registerPrivacykeyAction = payload => {
	//[DEBUG] console.log('registerPrivacykeyAction, payload =', payload);
	return {
		type: PRIVACYKEY_REGISTER_PRIVACYKEY,
		payload,
	};
};

export const updatePrivacykeyAction = payload => {
	//[DEBUG] console.log('updatePrivacykeyAction, payload =', payload);
	return {
		type: PRIVACYKEY_UPDATE_PRIVACYKEY,
		payload,
	};
};

export const deletePrivacykeyAction = payload => {
	//[DEBUG] console.log('deletePrivacykeyAction, payload =', payload);
	return {
		type: PRIVACYKEY_DELETE_PRIVACYKEY,
		payload,
	};
};

// ########## ########## ########## ##########
// redux actions

export const setPageablePrivacykeysAction = pageablePrivacykeys => {
	/*[DEBUG]
	console.log('setPageablePrivacykeysAction, pageablePrivacykeys =', pageablePrivacykeys);
	*/
	return {
		type: PRIVACYKEY_SET_PAGEABLE_PRIVACYKEYS,
		pageablePrivacykeys,
	};
};

import {
	CAMPAIGN_GET_PAGEABLE_CAMPAIGNS,
	CAMPAIGN_SET_PAGEABLE_CAMPAIGNS,
	CAMPAIGN_REGISTER_CAMPAIGN,
	CAMPAIGN_UPDATE_CAMPAIGN,
	CAMPAIGN_DELETE_CAMPAIGN,
	// CAMPAIGN_PUBLISH_CAMPAIGN,
	CAMPAIGN_GET_PAGEABLE_ACTIVITIES_OF_CAMPAIGN,
	CAMPAIGN_SET_PAGEABLE_ACTIVITIES_OF_CAMPAIGN,
	// definitions
	CAMPAIGN_GET_DEFINITIONS,
	CAMPAIGN_SET_DEFINITIONS,
	CAMPAIGN_REGISTER_DEFINITIONS,
	CAMPAIGN_UPDATE_DEFINITIONS,
	CAMPAIGN_DELETE_DEFINITIONS,
	// register
	CAMPAIGN_GET_OUTCOMES,
	CAMPAIGN_SET_OUTCOMES,
	CAMPAIGN_REGISTER_OUTCOMES,
	CAMPAIGN_UPDATE_OUTCOMES,
	CAMPAIGN_DELETE_OUTCOMES,
	CAMPAIGN_SET_TAGS_PAGE_CAMPAIGN,
	CAMPAIGN_UPDATE_DEFAULT_OUTCOMES,
	CAMPAIGN_SET_DEFAULT_OUTCOMES,
	CAMPAIGN_GET_DEFAULT_OUTCOMES,
} from './constants';
import { PUBLISHED, INACTIVE } from '../../utils/constants/campaigns';

// ########## ########## ########## ##########
// saga actions

export const getPageableCampaignsAction = payload => {
	//[DEBUG] console.log('getPageableCampaignsAction, payload =', payload);
	return {
		type: CAMPAIGN_GET_PAGEABLE_CAMPAIGNS,
		payload,
	};
};

export const registerCampaignAction = payload => {
	//[DEBUG] console.log('registerCampaignAction, payload =', payload);
	return {
		type: CAMPAIGN_REGISTER_CAMPAIGN,
		payload,
	};
};

export const updateCampaignAction = payload => {
	//[DEBUG] console.log('updateCampaignAction, payload =', payload);
	return {
		type: CAMPAIGN_UPDATE_CAMPAIGN,
		payload,
	};
};

export const deleteCampaignAction = payload => {
	//[DEBUG] console.log('deleteCampaignAction, payload =', payload);
	return {
		type: CAMPAIGN_DELETE_CAMPAIGN,
		payload,
	};
};

export const publishCampaignAction = payload => {
	//[DEBUG] console.log('publishCampaignAction, payload =', payload);
	return {
		type: CAMPAIGN_UPDATE_CAMPAIGN,
		payload: {
			campaignObject: { ...payload.campaignObject, campaignStatus: PUBLISHED },
			afterCalls: payload.afterCalls,
		},
	};
};

export const inactivateCampaignAction = payload => {
	//[DEBUG] console.log('inactivateCampaignAction, payload =', payload);
	return {
		type: CAMPAIGN_UPDATE_CAMPAIGN,
		payload: {
			campaignObject: { ...payload.campaignObject, campaignStatus: INACTIVE },
			afterCalls: payload.afterCalls,
		},
	};
};

export const getPageableActivitiesOfCampaignAction = payload => {
	//[DEBUG] console.log('getPageableActivitiesOfCampaignAction payload =', payload);
	return {
		type: CAMPAIGN_GET_PAGEABLE_ACTIVITIES_OF_CAMPAIGN,
		payload,
	};
};

export const getDefinitionsAction = payload => {
	//[DEBUG] console.log('getDefinitionsAction, payload =', payload);
	return {
		type: CAMPAIGN_GET_DEFINITIONS,
		payload,
	};
};

export const registerDefinitionsAction = payload => {
	//[DEBUG] console.log('registerDefinitionsAction, payload =', payload);
	return {
		type: CAMPAIGN_REGISTER_DEFINITIONS,
		payload,
	};
};

export const updateDefinitionsAction = payload => {
	//[DEBUG] console.log('updateDefinitionsAction, payload =', payload);
	return {
		type: CAMPAIGN_UPDATE_DEFINITIONS,
		payload,
	};
};

export const deleteDefinitionAndOutcomeAction = payload => {
	//[DEBUG] console.log('deleteDefinitionAndOutcomeAction, payload =', payload);
	return {
		type: CAMPAIGN_DELETE_DEFINITIONS,
		payload,
	};
};

export const getOutcomesAction = payload => {
	//[DEBUG] console.log('getOutcomesAction, payload =', payload);
	return {
		type: CAMPAIGN_GET_OUTCOMES,
		payload,
	};
};

export const registerOutcomesAction = payload => {
	//[DEBUG] console.log('registerOutcomesAction, payload =', payload);
	return {
		type: CAMPAIGN_REGISTER_OUTCOMES,
		payload,
	};
};

export const updateOutcomesAction = payload => {
	//[DEBUG] console.log('updateOutcomesAction, payload =', payload);
	return {
		type: CAMPAIGN_UPDATE_OUTCOMES,
		payload,
	};
};

export const deleteOutcomeAction = payload => {
	//[DEBUG] console.log('deleteOutcomeAction, payload =', payload);
	return {
		type: CAMPAIGN_DELETE_OUTCOMES,
		payload,
	};
};

export const getDefaultOutcomesAction = () => {
	//[DEBUG] console.log('getDefaultOutcomesAction, payload = _');
	return {
		type: CAMPAIGN_GET_DEFAULT_OUTCOMES,
	};
};

export const updateDefaultOutcomesAction = payload => {
	//[DEBUG] console.log('updateDefaultOutcomesAction, payload =', payload);
	return {
		type: CAMPAIGN_UPDATE_DEFAULT_OUTCOMES,
		payload,
	};
};

// ########## ########## ########## ##########
// redux actions

export const setPageableCampaignsAction = pageableCampaigns => {
	//[DEBUG] console.log('setPageableCampaignsAction, pageableCampaigns =', pageableCampaigns);
	return {
		type: CAMPAIGN_SET_PAGEABLE_CAMPAIGNS,
		pageableCampaigns,
	};
};

export const setPageableActivitiesOfCampaignAction = ({
	campaignObject,
	pageableActivities,
}) => {
	/*[DEBUG]
	/*/ console.log(
		'setPageableActivitiesOfCampaignAction ({ campaignObject, pageableActivities }) =',
		{
			campaignObject,
			pageableActivities,
		}
	);
	//*/
	return {
		type: CAMPAIGN_SET_PAGEABLE_ACTIVITIES_OF_CAMPAIGN,
		campaignId: campaignObject.campaignId,
		pageableActivities,
	};
};

export const setDefinitionsAction = ({ campaignObject, definitionsObject }) => {
	/*[DEBUG]
	/*/ console.log(
		'setDefinitionsAction, ({ campaignObject, definitionsObject }) =',
		{
			campaignObject,
			definitionsObject,
		}
	);
	//*/
	return {
		type: CAMPAIGN_SET_DEFINITIONS,
		campaignType: campaignObject.campaignType,
		campaignId: campaignObject.campaignId,
		definitionsObject,
	};
};

export const setOutcomesAction = ({ campaignObject, outcomesObject }) => {
	//*[DEBUG]
	/*/ console.log('setOutcomesAction, ({ campaignObject, outcomesObject }) =', {
		campaignObject,
		outcomesObject,
	});
	//*/
	return {
		type: CAMPAIGN_SET_OUTCOMES,
		campaignType: campaignObject.campaignType,
		campaignId: campaignObject.campaignId,
		outcomesObject,
	};
};

export const setTagsPageCampaignAction = campaignObject => {
	//[DEBUG] console.log('setTagsPageCampaignAction, campaignObject =', campaignObject);
	return {
		type: CAMPAIGN_SET_TAGS_PAGE_CAMPAIGN,
		tagsPageCampaign: campaignObject,
	};
};

export const setDefaultOutcomesAction = ({ defaultOutcomesObject }) => {
	/*[DEBUG]
	console.log(
		'setDefaultOutcomesAction, defaultOutcomesObject =',
		defaultOutcomesObject
	);
	//*/
	const { redirection, forwarding } = defaultOutcomesObject;
	return {
		type: CAMPAIGN_SET_DEFAULT_OUTCOMES,
		defaultOutcomesObject: {
			defaultRedirection: redirection,
			defaultForwarding: forwarding,
		},
	};
};

import { takeLeading, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { setAppStatusAction } from './action';
import { requestAppStatusAPI, registerGlobalAdminAPI } from '../../utils/api/bootstrap';

import {
	BOOTSTRAP_REQUEST_APP_STATUS,
	BOOTSTRAP_REGISTER_GLOBAL_ADMIN,
} from './constants';

import { SERVER_UNAVAILABLE } from '../../utils/request';
import { bootstrapStatus } from '../../utils/session-helpers';
import { toggleLoaderAction } from '../general/action';

export function* requestAppStatusWorkerSaga() {
	//[DEBUG] console.log('requestAppStatusWorkerSaga start');
	try {
		yield put(toggleLoaderAction(true));
		const response = yield call(requestAppStatusAPI);
		sessionStorage.setItem('bootstrapstatus', response.stage);
		yield put(setAppStatusAction(bootstrapStatus()));
		yield put(toggleLoaderAction(false));
	} catch (error) {
		//[DEBUG] console.log('Error requestAppStatusWorkerSaga');
		//[DEBUG] console.log('Error = ', error);
		if (error === SERVER_UNAVAILABLE) {
			// yield put(push('error-server-not-available'));
		}
		// eslint-disable-next-line
		console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);
	}
	yield put(toggleLoaderAction(false));
}

export function* registerGlobalAdminWorkerSaga({ payload }) {
	//[DEBUG] console.log('registerGlobalAdminWorkerSaga', payload);
	yield put(toggleLoaderAction(true));
	try {
		yield call(registerGlobalAdminAPI, payload);
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('SHOULD BE REDIRECTED');
		yield put(push('/initialize-app-success'));
	} catch (error) {
		yield put(toggleLoaderAction(false));
		// eslint-disable-next-line
		console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);
	}
	yield put(toggleLoaderAction(false));
}

export function* requestAppStatusSaga() {
	yield takeLeading(BOOTSTRAP_REQUEST_APP_STATUS, requestAppStatusWorkerSaga);
}

export function* registerGlobalAdminSaga() {
	yield takeLeading(BOOTSTRAP_REGISTER_GLOBAL_ADMIN, registerGlobalAdminWorkerSaga);
}

import {
	CAMPAIGN_SET_PAGEABLE_CAMPAIGNS,
	CAMPAIGN_SET_PAGEABLE_ACTIVITIES_OF_CAMPAIGN,
	CAMPAIGN_SET_DEFINITIONS,
	CAMPAIGN_SET_OUTCOMES,
	CAMPAIGN_SET_TAGS_PAGE_CAMPAIGN,
	CAMPAIGN_SET_DEFAULT_OUTCOMES,
} from './constants';

export const initialState = {
	pageableCampaigns: {
		content: [],
	},
	pageableActivitiesByCampaign: {},
	campaignDefinitionsByCampaign: {},
	campaignOutcomesByCampaign: {},
	tagsPageCampaign: {},
	defaultOutcomesObject: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CAMPAIGN_SET_PAGEABLE_CAMPAIGNS: {
			return {
				...state,
				pageableCampaigns: action.pageableCampaigns,
			};
		}
		case CAMPAIGN_SET_PAGEABLE_ACTIVITIES_OF_CAMPAIGN: {
			return {
				...state,
				pageableActivitiesByCampaign: {
					...state.pageableActivitiesByCampaign,
					[action.campaignId]: action.pageableActivities,
				},
			};
		}
		case CAMPAIGN_SET_DEFINITIONS: {
			// {} -> campaignId -> campaignType -> definitionsObject
			return {
				...state,
				campaignDefinitionsByCampaign: {
					...state.campaignDefinitionsByCampaign,
					[action.campaignId]: {
						...state.campaignDefinitionsByCampaign[action.campaignId],
						[action.campaignType]: action.definitionsObject,
					},
				},
			};
		}
		case CAMPAIGN_SET_OUTCOMES: {
			// {} -> campaignId -> campaignType -> outcomes
			return {
				...state,
				campaignOutcomesByCampaign: {
					...state.campaignOutcomesByCampaign,
					[action.campaignId]: {
						...state.campaignOutcomesByCampaign[action.campaignId],
						[action.campaignType]: action.outcomesObject,
					},
				},
			};
		}
		case CAMPAIGN_SET_TAGS_PAGE_CAMPAIGN: {
			return {
				...state,
				tagsPageCampaign: action.tagsPageCampaign,
			};
		}
		case CAMPAIGN_SET_DEFAULT_OUTCOMES: {
			return {
				...state,
				defaultOutcomesObject: action.defaultOutcomesObject,
			};
		}
		default:
			return state;
	}
};

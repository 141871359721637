import {
	BOOTSTRAP_REQUEST_APP_STATUS,
	BOOTSTRAP_SET_APP_STATUS,
	BOOTSTRAP_REGISTER_GLOBAL_ADMIN,
} from './constants';

export const requestAppStatusAction = () => {
	//[DEBUG] console.log('requestAppStatusAction start');
	return {
		type: BOOTSTRAP_REQUEST_APP_STATUS,
	};
};

export const setAppStatusAction = status => {
	//[DEBUG] console.log('setAppStatusAction status = ', status);
	return {
		type: BOOTSTRAP_SET_APP_STATUS,
		status,
	};
};

export const registerGlobalAdminAction = payload => {
	return {
		type: BOOTSTRAP_REGISTER_GLOBAL_ADMIN,
		payload,
	};
};

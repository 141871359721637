import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import {
	downloadDiversifyKeyJobReportAPI,
	downloadPasswordGeneratorJobReportAPI,
} from '../../utils/api/file';
// import { push } from 'connected-react-router';

import {
	getPageableKeysAPI,
	registerKeyAPI,
	updateKeyAPI,
	deleteKeyAPI,
	operationKeyCompanyAssignAPI,
	operationKeyCompanyUnassignAPI,
	getPageableCompaniesOfKeyAPI,
} from '../../utils/api/key';
import { toggleLoaderAction, setGeneralErrorMessageAction } from '../general/action';
import { setPageableKeysAction, setPageableCompaniesOfKeyAction } from './action';
import {
	KEY_GET_PAGEABLE_KEYS,
	KEY_REGISTER_KEY,
	KEY_UPDATE_KEY,
	KEY_OPERATION_KEY_COMPANY_ASSIGN,
	KEY_OPERATION_KEY_COMPANY_UNASSIGN,
	KEY_DELETE_KEY,
	KEY_GET_PAGEABLE_COMPANIES_OF_KEY,
	KEY_DOWNLOAD_DIVERSIFY_KEY_JOB_REPORT,
	KEY_DOWNLOAD_GENERATED_PASSWORD_JOB_REPORT,
} from './constants';

export function* getPageableKeysWorkerSaga({ payload }) {
	//[DEBUG] console.log('getPageableKeysWorkerSaga start, payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		const keys = yield call(getPageableKeysAPI, payload);
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('getPageableKeysWorkerSaga keys =', keys);
		yield put(setPageableKeysAction(keys));

		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on get pageable keys',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* registerKeyWorkerSaga({ payload }) {
	//[DEBUG] console.log('registerKeyWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(registerKeyAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on add key',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* updateKeyWorkerSaga({ payload }) {
	//[DEBUG] console.log('updateKeyWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(updateKeyAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success updateKeyWorkerSaga');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error updateKeyWorkerSaga');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on update key',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* deleteKeyWorkerSaga({ payload }) {
	//[DEBUG] console.log('deleteKeyWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(deleteKeyAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success deleteKeyWorkerSaga');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error deleteKeyWorkerSaga');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on delete key',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* operationKeyCompanyAssignWorkerSaga({ payload }) {
	//[DEBUG] console.log('operationKeyCompanyAssignWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(operationKeyCompanyAssignAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error assign key',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* operationKeyCompanyUnassignWorkerSaga({ payload }) {
	//[DEBUG] console.log('operationKeyCompanyUnassignWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(operationKeyCompanyUnassignAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on unassign key',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* getPageableCompaniesOfKeyWorkerSaga({ payload }) {
	//[DEBUG] console.log('getPageableCompaniesOfKeyWorkerSaga payload =', payload);
	const { keyObject /*, pageableParameters*/ } = payload;

	try {
		yield put(toggleLoaderAction(true));
		const pageableCompanies = yield call(getPageableCompaniesOfKeyAPI, payload);
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('getPageableCompaniesOfKeyAPI payload =', payload);

		yield put(setPageableCompaniesOfKeyAction({ keyObject, pageableCompanies }));
		//[DEBUG] console.log('Success getPageableCompaniesOfKeyWorkerSaga');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error getPageableCompaniesOfKeyWorkerSaga');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on get pageable companies of key',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* downloadDiversifyKeyJobReportWorkerSaga({ payload }) {
	//[DEBUG] console.log('downloadDiversifyKeyJobReportWorkerSaga payload =', payload);
	yield put(toggleLoaderAction(true));
	try {
		yield call(downloadDiversifyKeyJobReportAPI, payload);
	} catch (error) {
		yield put(toggleLoaderAction(false));

		const generalErrorObject = {
			title: 'API error on diversify',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* downloadPasswordGeneratorJobReportWorkerSaga({ payload }) {
	//[DEBUG] console.log('downloadPasswordGeneratorJobReportWorkerSaga payload =', payload);
	yield put(toggleLoaderAction(true));
	try {
		yield call(downloadPasswordGeneratorJobReportAPI, payload);
	} catch (error) {
		yield put(toggleLoaderAction(false));

		const generalErrorObject = {
			title: 'API error on password generation',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* getPageableKeysSaga() {
	yield takeLatest(KEY_GET_PAGEABLE_KEYS, getPageableKeysWorkerSaga);
}

export function* registerKeySaga() {
	yield takeLatest(KEY_REGISTER_KEY, registerKeyWorkerSaga);
}

export function* updateKeySaga() {
	yield takeLatest(KEY_UPDATE_KEY, updateKeyWorkerSaga);
}

export function* deleteKeySaga() {
	yield takeLatest(KEY_DELETE_KEY, deleteKeyWorkerSaga);
}

export function* operationKeyCompanyAssignSaga() {
	yield takeLatest(KEY_OPERATION_KEY_COMPANY_ASSIGN, operationKeyCompanyAssignWorkerSaga);
}

export function* operationKeyCompanyUnassignSaga() {
	yield takeLatest(
		KEY_OPERATION_KEY_COMPANY_UNASSIGN,
		operationKeyCompanyUnassignWorkerSaga
	);
}

export function* getPageableCompaniesOfKeySaga() {
	yield takeEvery(KEY_GET_PAGEABLE_COMPANIES_OF_KEY, getPageableCompaniesOfKeyWorkerSaga);
}

export function* downloadDiversifyKeyJobReportSaga() {
	yield takeEvery(
		KEY_DOWNLOAD_DIVERSIFY_KEY_JOB_REPORT,
		downloadDiversifyKeyJobReportWorkerSaga
	);
}

export function* downloadPasswordGeneratorJobReportSaga() {
	console.log('downloadPasswordGeneratorJobReportSaga');
	yield takeEvery(
		KEY_DOWNLOAD_GENERATED_PASSWORD_JOB_REPORT,
		downloadPasswordGeneratorJobReportWorkerSaga
	);
}

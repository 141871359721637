import { all } from 'redux-saga/effects';

import { requestMyUserDataSaga } from './saga-redux-modules/account/saga';

import {
	showApikeyInPageableGetSaga,
	getPageableApikeysSaga,
	registerApikeySaga,
	deleteApikeySaga,
} from './saga-redux-modules/apikey/saga';

import {
	requestBasicDataSaga,
	requestTokenAuthorizedSaga,
	acceptTermsForCompanySaga,
	revokeTermsForCompanySaga,
	logoutUserSaga,
} from './saga-redux-modules/auth/saga';

import {
	requestAppStatusSaga,
	registerGlobalAdminSaga,
} from './saga-redux-modules/bootstrap/saga';
import { requestDashboardsSaga } from './saga-redux-modules/dashboard/saga';
import { getPageableFeaturesSaga } from './saga-redux-modules/features/saga';

import {
	getPageableKeysSaga,
	registerKeySaga,
	updateKeySaga,
	deleteKeySaga,
	operationKeyCompanyAssignSaga,
	operationKeyCompanyUnassignSaga,
	getPageableCompaniesOfKeySaga,
	downloadDiversifyKeyJobReportSaga,
	downloadPasswordGeneratorJobReportSaga,
} from './saga-redux-modules/key/saga';

import {
	getPageableTagsSaga,
	registerTagSaga,
	updateTagSaga,
	deleteTagSaga,
	getTagCountersSaga,
	updateTagCountersSaga,
	assignTagsToCampaignSaga,
	unassignTagsSaga,
	bulkTagStatusChangeSaga,
	deleteTagsSaga,
	getTagOutcomesSaga,
	registerTagOutcomesSaga,
	// import job
	getTagsImportJobReportSaga,
} from './saga-redux-modules/tag/saga';
import {
	// -> campaign
	getPageableCampaignsSaga,
	registerCampaignSaga,
	updateCampaignSaga,
	deleteCampaignSaga,
	// -> definition
	getDefinitionsSaga,
	registerDefinitionsSaga,
	updateDefinitionsSaga,
	deleteDefinitionAndOutcomeSaga,
	// -> outcome
	registerOutcomesSaga,
	updateOutcomesSaga,
	deleteOutcomeSaga,
	getOutcomesSaga,
	// -> default outcomes
	getDefaultOutcomesSaga,
	updateDefaultOutcomesSaga,
} from './saga-redux-modules/campaign/saga';

import {
	// -> company
	getPageableCompaniesSaga,
	registerCompanySaga,
	updateCompanySaga,
	getCompanyByIdSaga,
	deleteCompanySaga,
	getPageableUsersOfCompanySaga,
	// -> user
	registerUserSaga,
	updateUserSaga,
	getPageableUsersSaga,
	deleteUserSaga,
} from './saga-redux-modules/user/saga';
import {
	getAttributionsJsonSaga,
	getPrivacyHtmlSaga,
	getTermsHtmlSaga,
} from './saga-redux-modules/file/saga';

import {
	getPageablePrivacykeysSaga,
	registerPrivacykeySaga,
	updatePrivacykeySaga,
	deletePrivacykeySaga,
} from './saga-redux-modules/privacykey/saga';

export default function* rootSaga() {
	yield all([
		// account
		requestMyUserDataSaga(),
		// apikeys
		showApikeyInPageableGetSaga(),
		getPageableApikeysSaga(),
		registerApikeySaga(),
		deleteApikeySaga(),
		// auth
		requestBasicDataSaga(),
		requestTokenAuthorizedSaga(),
		acceptTermsForCompanySaga(),
		revokeTermsForCompanySaga(),
		logoutUserSaga(),
		// bootstrap
		requestAppStatusSaga(),
		registerGlobalAdminSaga(),
		// dashboard
		requestDashboardsSaga(),
		// features
		getPageableFeaturesSaga(),
		// key
		getPageableKeysSaga(),
		registerKeySaga(),
		updateKeySaga(),
		deleteKeySaga(),
		operationKeyCompanyAssignSaga(),
		operationKeyCompanyUnassignSaga(),
		getPageableCompaniesOfKeySaga(),
		downloadDiversifyKeyJobReportSaga(),
		downloadPasswordGeneratorJobReportSaga(),
		// tag
		getPageableTagsSaga(),
		registerTagSaga(),
		updateTagSaga(),
		deleteTagSaga(),
		getTagCountersSaga(),
		updateTagCountersSaga(),
		assignTagsToCampaignSaga(),
		unassignTagsSaga(),
		bulkTagStatusChangeSaga(),
		deleteTagsSaga(),
		getTagOutcomesSaga(),
		registerTagOutcomesSaga(),
		getTagsImportJobReportSaga(),
		// campaign
		// -> campaign
		getPageableCampaignsSaga(),
		registerCampaignSaga(),
		updateCampaignSaga(),
		deleteCampaignSaga(),
		// -> definition
		getDefinitionsSaga(),
		registerDefinitionsSaga(),
		updateDefinitionsSaga(),
		deleteDefinitionAndOutcomeSaga(),
		// -> outcome
		getOutcomesSaga(),
		registerOutcomesSaga(),
		updateOutcomesSaga(),
		deleteOutcomeSaga(),
		// -> default outcomes
		getDefaultOutcomesSaga(),
		updateDefaultOutcomesSaga(),
		// user
		// -> company
		getPageableCompaniesSaga(),
		registerCompanySaga(),
		updateCompanySaga(),
		getCompanyByIdSaga(),
		deleteCompanySaga(),
		getPageableUsersOfCompanySaga(),
		// -> user
		registerUserSaga(),
		updateUserSaga(),
		getPageableUsersSaga(),
		deleteUserSaga(),
		// file
		getTermsHtmlSaga(),
		getPrivacyHtmlSaga(),
		getAttributionsJsonSaga(),
		// privacykey
		getPageablePrivacykeysSaga(),
		registerPrivacykeySaga(),
		updatePrivacykeySaga(),
		deletePrivacykeySaga(),
	]);
}

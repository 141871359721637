import {
	GENERAL_TOGGLE_LOADER,
	GENERAL_SET_NEXT_PAGE,
	GENERAL_SET_DIALOG_ERROR,
	GENERAL_SET_GENERAL_ERROR_MESSAGE,
} from './constants';

export const initialState = {
	showLoader: false,
	nextPage: '/',
	dialogError: {
		type: '',
		message: '',
	},
	generalErrorObject: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GENERAL_TOGGLE_LOADER: {
			return {
				...state,
				showLoader: action.showLoader,
			};
		}
		case GENERAL_SET_NEXT_PAGE: {
			return {
				...state,
				nextPage: action.nextPage,
			};
		}
		case GENERAL_SET_DIALOG_ERROR: {
			return {
				...state,
				dialogError: {
					type: action.payload.type,
					message: action.payload.message,
				},
			};
		}
		case GENERAL_SET_GENERAL_ERROR_MESSAGE: {
			return {
				...state,
				generalErrorObject: action.generalErrorObject,
			};
		}
		default:
			return state;
	}
};

import { DASHBOARD_REQUEST_DASHBOARDS, DASHBOARD_SET_DASHBOARD } from './constants';

// ########## ########## ########## ##########
// saga actions

export const requestDashboardsAction = payload => {
	//[DEBUG] console.log('requestDashboardsAction, payload =', payload);
	return {
		type: DASHBOARD_REQUEST_DASHBOARDS,
		payload,
	};
};

// ########## ########## ########## ##########
// redux actions

export const setDashboardAction = ({ dashboardType, dashboardUrl }) => {
	//*[DEBUG]
	/*/ console.log('setDashboardAction, ({ dashboardType, dashboardUrl }) =', {
		dashboardType,
		dashboardUrl,
	});
	//*/
	return {
		type: DASHBOARD_SET_DASHBOARD,
		dashboardType,
		dashboardUrl,
	};
};

import {
	VALID,
	INVALID,
	TAG_TAMPER,
	TAG_TAMPER_NOW_CLOSED,
	NOT_ORIGINAL,
	CAMPAIGN_INACTIVE,
	COUNTER_EXPIRED,
	COUNTER_INVALID,
} from '../constants/tags/index.const';

export const transformOutcomeSubtypeToInitialized = ({ outcomeSubtype }) => {
	switch (outcomeSubtype) {
		case VALID:
			return 'initializedValid';
		case TAG_TAMPER:
			return 'initializedTagTamper';
		case NOT_ORIGINAL:
			return 'initializedNotOriginal';
		case INVALID:
			return 'initializedInvalid';
		case CAMPAIGN_INACTIVE:
			return 'initializedCampaignInactive';
		case COUNTER_INVALID:
			return 'initializedCounterInvalid';
		case COUNTER_EXPIRED:
			return 'initializedCounterExpired';
		default:
			return '';
	}
};

export const transformOutcomeSubtypeToDefinitionsList = ({ outcomeSubtype }) => {
	switch (outcomeSubtype) {
		case VALID:
			return ['initializedValid'];
		case NOT_ORIGINAL:
			return ['uidEccCheck', 'sunCheck', 'checksumCheck'];
		case TAG_TAMPER:
			return ['tamperCheck'];
		case TAG_TAMPER_NOW_CLOSED:
			return ['tamperNowClosedCheck'];
		case INVALID:
			return ['initializedInvalid'];
		case CAMPAIGN_INACTIVE:
			return ['initializedInvalid'];
		case COUNTER_INVALID:
			return ['nfcCounterCheck'];
		case COUNTER_EXPIRED:
			return ['usedCountCheck'];
		default:
			return [];
	}
};

export const transformOutcomeSubtypeToAction = ({ outcomeSubtype }) => {
	switch (outcomeSubtype) {
		case VALID:
			return 'valid';
		case TAG_TAMPER:
			return 'tagTamper';
		case TAG_TAMPER_NOW_CLOSED:
			return 'tagTamperNowClosed';
		case NOT_ORIGINAL:
			return 'notOriginal';
		case INVALID:
			return 'invalid';
		case CAMPAIGN_INACTIVE:
			return 'campaignInactive';
		case COUNTER_INVALID:
			return 'counterInvalid';
		case COUNTER_EXPIRED:
			return 'counterExpired';
		default:
			return '';
	}
};

export const transformOutcomeSubtypeToWebhook = ({ outcomeSubtype }) => {
	switch (outcomeSubtype) {
		case VALID:
			return 'validWebHooks';
		case TAG_TAMPER:
			return 'tagTamperWebHooks';
		case TAG_TAMPER_NOW_CLOSED:
			return 'tagTamperNowClosedWebHooks';
		case NOT_ORIGINAL:
			return 'notOriginalWebHooks';
		case INVALID:
			return 'invalidWebHooks';
		case CAMPAIGN_INACTIVE:
			return 'campaignInactiveWebHooks';
		case COUNTER_INVALID:
			return 'counterInvalidWebHooks';
		case COUNTER_EXPIRED:
			return 'counterExpiredWebHooks';
		default:
			return '';
	}
};

// ACTION CONSTANTS

export const KEY_GET_PAGEABLE_KEYS = 'KEY_GET_PAGEABLE_KEYS';
export const KEY_SET_PAGEABLE_KEYS = 'KEY_SET_PAGEABLE_KEYS';

export const KEY_REGISTER_KEY = 'KEY_REGISTER_KEY';
export const KEY_UPDATE_KEY = 'KEY_UPDATE_KEY';
export const KEY_DELETE_KEY = 'KEY_DELETE_KEY';
export const KEY_OPERATION_KEY_COMPANY_ASSIGN = 'KEY_OPERATION_KEY_COMPANY_ASSIGN';
export const KEY_OPERATION_KEY_COMPANY_UNASSIGN = 'KEY_OPERATION_KEY_COMPANY_UNASSIGN';

export const KEY_GET_PAGEABLE_COMPANIES_OF_KEY = 'KEY_GET_PAGEABLE_COMPANIES_OF_KEY';
export const KEY_SET_PAGEABLE_COMPANIES_OF_KEY = 'KEY_SET_PAGEABLE_COMPANIES_OF_KEY';

export const KEY_DOWNLOAD_DIVERSIFY_KEY_JOB_REPORT =
	'KEY_DOWNLOAD_DIVERSIFY_KEY_JOB_REPORT';
export const KEY_DOWNLOAD_GENERATED_PASSWORD_JOB_REPORT =
	'KEY_DOWNLOAD_GENERATED_PASSWORD_JOB_REPORT';

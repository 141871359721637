import React from 'react';
// import { requestTokenAuthorizedAction } from '../../../saga-redux-modules/auth/action';
import { AUTH_URL_LOGOUT_USER_ON_CAS } from '../../../../utils/constants/url-paths';

const LogoutPage = ({ location }) => {
	// this has a priority now
	window.location = `${AUTH_URL_LOGOUT_USER_ON_CAS}`;

	console.log('LogoutPage location', location);

	// return <h1>Not authenticated (please login with NXP account).</h1>;
	return <div></div>;
};

export default LogoutPage;

import request from '../request';
import { authToken } from '../session-helpers';
import {
	CAMPAIGN_URL_GET_PAGEABLE_CAMPAIGNS_QUERY_func,
	CAMPAIGN_URL_REGISTER_CAMPAIGN,
	CAMPAIGN_URL_UPDATE_CAMPAIGN,
	CAMPAIGN_URL_DELETE_CAMPAIGN_WITH_ID_func,
	// CAMPAIGN_URL_GET_PAGEABLE_ACTIVITIES_OF_CAMPAIGN_ID_QUERY_func,
	CAMPAIGN_URL_GET_OUTCOMES_func,
	CAMPAIGN_URL_UPDATE_OUTCOMES_func,
	CAMPAIGN_URL_GET_DEFINITIONS_func,
	CAMPAIGN_URL_UPDATE_DEFINITIONS_func,
	CAMPAIGN_URL_GET_DEFAULT_OUTCOMES,
	CAMPAIGN_URL_UPDATE_DEFAULT_OUTCOMES,
} from '../constants/url-paths';

export const getPageableCampaignsAPI = payload => {
	//[DEBUG] console.log('getPageableCampaignsAPI, payload = ', payload);
	const { pageableParameters, filterParameters } = payload;

	return request(
		CAMPAIGN_URL_GET_PAGEABLE_CAMPAIGNS_QUERY_func({
			pageableParameters,
			filterParameters,
		}),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

export const registerCampaignAPI = payload => {
	//[DEBUG] console.log('registerCampaignAPI payload = ', payload);
	const { campaignObject } = payload;
	const { campaignName, campaignTagType } = campaignObject;

	const body = JSON.stringify({
		name: campaignName,
		tagType: campaignTagType,
	});

	//[DEBUG] console.log('CAMPAIGN_URL_REGISTER_CAMPAIGN, body =', body);
	return request(CAMPAIGN_URL_REGISTER_CAMPAIGN, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${authToken()}`,
			'Content-Type': 'application/json',
		},
		body,
	}).then(response => Promise.resolve(response));
};

export const updateCampaignAPI = payload => {
	//[DEBUG] console.log('updateCampaignAPI payload = ', payload);
	const { campaignObject } = payload;
	const { campaignId, campaignName, campaignStatus } = campaignObject;

	const body = JSON.stringify({
		id: campaignId,
		name: campaignName,
		status: campaignStatus,
	});

	//[DEBUG] console.log('CAMPAIGN_URL_UPDATE_CAMPAIGN, body =', body);
	return request(CAMPAIGN_URL_UPDATE_CAMPAIGN, {
		method: 'PUT',
		headers: {
			Authorization: `Bearer ${authToken()}`,
			'Content-Type': 'application/json',
		},
		body,
	}).then(response => Promise.resolve(response));
};

export const deleteCampaignAPI = payload => {
	//[DEBUG] console.log('deleteCampaignAPI payload = ', payload);
	const { campaignObject } = payload;
	const { campaignId } = campaignObject;

	//[DEBUG] console.log('CAMPAIGN_URL_DELETE_CAMPAIGN_WITH_ID_func, campaignId =', campaignId);
	return request(CAMPAIGN_URL_DELETE_CAMPAIGN_WITH_ID_func({ campaignId: campaignId }), {
		method: 'DELETE',
		headers: {
			Authorization: `Bearer ${authToken()}`,
		},
	}).then(response => Promise.resolve(response));
};

export const getDefinitionsAPI = payload => {
	//[DEBUG] console.log('getDefinitionsAPI, payload = ', payload);
	const { campaignObject } = payload;
	const { campaignId, campaignType } = campaignObject;

	return request(
		CAMPAIGN_URL_GET_DEFINITIONS_func({
			campaignId,
			campaignType,
		}),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

export const updateDefinitionsAPI = payload => {
	//[DEBUG] console.log('updateDefinitionsAPI payload = ', payload);
	const { campaignObject, definitionsObject } = payload;
	const { campaignId, campaignType } = campaignObject;

	const body = JSON.stringify(definitionsObject);

	//[DEBUG] console.log('CAMPAIGN_URL_UPDATE_DEFINITIONS_func, body =', body);
	return request(CAMPAIGN_URL_UPDATE_DEFINITIONS_func({ campaignId, campaignType }), {
		method: 'PUT',
		headers: {
			Authorization: `Bearer ${authToken()}`,
			'Content-Type': 'application/json',
		},
		body,
	}).then(response => Promise.resolve(response));
};

export const getOutcomesAPI = payload => {
	//[DEBUG] console.log('getOutcomesAPI, payload = ', payload);
	const { campaignObject } = payload;
	const { campaignId, campaignType } = campaignObject;

	return request(
		CAMPAIGN_URL_GET_OUTCOMES_func({
			campaignId,
			campaignType,
		}),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

export const updateOutcomesAPI = payload => {
	//[DEBUG] console.log('updateOutcomesAPI payload = ', payload);
	const { campaignObject, outcomesObject } = payload;
	const { campaignId, campaignType } = campaignObject;

	const body = JSON.stringify(outcomesObject);

	//[DEBUG] console.log('CAMPAIGN_URL_UPDATE_OUTCOMES_func, body =', body);
	return request(
		CAMPAIGN_URL_UPDATE_OUTCOMES_func({
			campaignId,
			campaignType,
		}),
		{
			method: 'PUT',
			headers: {
				Authorization: `Bearer ${authToken()}`,
				'Content-Type': 'application/json',
			},
			body,
		}
	).then(response => Promise.resolve(response));
};

export const getDefaultOutcomesAPI = () => {
	//[DEBUG] console.log('getDefaultOutcomesAPI, payload = _');

	return request(CAMPAIGN_URL_GET_DEFAULT_OUTCOMES, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authToken()}`,
		},
	}).then(response => Promise.resolve(response));
};

export const updateDefaultOutcomesAPI = payload => {
	//[DEBUG] console.log('updateDefaultOutcomesAPI payload = ', payload);
	const { defaultOutcomesObject } = payload;
	const { defaultRedirection, defaultForwarding } = defaultOutcomesObject;

	const body = JSON.stringify({
		redirection: defaultRedirection,
		forwarding: defaultForwarding,
	});

	return request(CAMPAIGN_URL_UPDATE_DEFAULT_OUTCOMES, {
		method: 'PUT',
		headers: {
			Authorization: `Bearer ${authToken()}`,
			'Content-Type': 'application/json',
		},
		body,
	}).then(response => Promise.resolve(response));
};

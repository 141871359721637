import request from '../request';
import { authToken } from '../session-helpers';
import {
	PRIVACYKEY_URL_GET_PRIVACYKEY_BY_ID_func,
	PRIVACYKEY_URL_GET_PAGEABLE_PRIVACYKEYS_QUERY_func,
	PRIVACYKEY_URL_REGISTER_PRIVACYKEY,
	PRIVACYKEY_URL_UPDATE_PRIVACYKEY,
	PRIVACYKEY_URL_DELETE_PRIVACYKEY_WITH_ID_func,
} from '../constants/url-paths';

// -> from saga

export const getPrivacykeyByIdAPI = id => {
	//[DEBUG] console.log('getPrivacykeyByIdAPI start, param = ', id);

	return request(PRIVACYKEY_URL_GET_PRIVACYKEY_BY_ID_func({ keyId: id }), {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authToken()}`,
		},
	}).then(response => Promise.resolve(response));
};

export const getPageablePrivacykeysAPI = payload => {
	//[DEBUG] console.log('getPageablePrivacykeysAPI payload = ', payload);
	const { pageableParameters, filterParameters } = payload;

	return request(
		PRIVACYKEY_URL_GET_PAGEABLE_PRIVACYKEYS_QUERY_func({
			pageableParameters,
			filterParameters,
		}),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

export const registerPrivacykeyAPI = payload => {
	//[DEBUG] console.log('registerPrivacykeyAPI payload = ', payload);
	const { privacykeyObject } = payload;
	const {
		privacykeyVersion,
		privacykeyName,
		privacykeyMetaAccess,
		privacykeyMetaAccessName,
		privacykeyFileAccess,
		privacykeyFileAccessName,
		diversified,
	} = privacykeyObject;

	const body = JSON.stringify({
		versionParam: privacykeyVersion,
		name: privacykeyName,
		metaReadKeyId: privacykeyMetaAccess,
		metaReadKeyName: privacykeyMetaAccessName,
		fileReadKeyId: privacykeyFileAccess,
		fileReadKeyName: privacykeyFileAccessName,
		diversified,
	});

	//[DEBUG] console.log('PRIVACYKEY_URL_REGISTER_PRIVACYKEY, body =', body);
	return request(PRIVACYKEY_URL_REGISTER_PRIVACYKEY, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${authToken()}`,
			'Content-Type': 'application/json',
		},
		body,
	}).then(response => Promise.resolve(response));
};

export const updatePrivacykeyAPI = payload => {
	//[DEBUG] console.log('updatePrivacykeyAPI payload = ', payload);
	const { privacykeyObject } = payload;
	const {
		privacykeyId,
		privacykeyName,
		privacykeyMetaAccess,
		privacykeyMetaAccessName,
		privacykeyFileAccess,
		privacykeyFileAccessName,
		diversified,
	} = privacykeyObject;

	const body = JSON.stringify({
		id: privacykeyId,
		name: privacykeyName,
		metaReadKeyId: privacykeyMetaAccess,
		metaReadKeyName: privacykeyMetaAccessName,
		fileReadKeyId: privacykeyFileAccess,
		fileReadKeyName: privacykeyFileAccessName,
		diversified,
	});

	//[DEBUG] console.log('PRIVACYKEY_URL_UPDATE_PRIVACYKEY, body =', body);
	return request(PRIVACYKEY_URL_UPDATE_PRIVACYKEY, {
		method: 'PUT',
		headers: {
			Authorization: `Bearer ${authToken()}`,
			'Content-Type': 'application/json',
		},
		body,
	}).then(response => Promise.resolve(response));
};

export const deletePrivacykeyAPI = payload => {
	//[DEBUG] console.log('deletePrivacykeyAPI payload = ', payload);
	const { privacykeyObject } = payload;
	const { privacykeyId } = privacykeyObject;

	//[DEBUG] console.log('PRIVACYKEY_URL_DELETE_PRIVACYKEY_WITH_ID_func, keyId =', keyId);
	return request(
		PRIVACYKEY_URL_DELETE_PRIVACYKEY_WITH_ID_func({ privacykeyId: privacykeyId }),
		{
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

import {
	TAG_GET_PAGEABLE_TAGS,
	TAG_SET_PAGEABLE_TAGS,
	TAG_REGISTER_TAG,
	TAG_UPDATE_TAG,
	TAG_DELETE_TAG,
	TAG_GET_TAG_COUNTERS,
	TAG_SET_TAG_COUNTERS,
	TAG_UPDATE_TAG_COUNTERS,
	// TAG_ASSIGN_TAG_TO_CAMPAIGN,
	TAG_ASSIGN_TAGS_TO_CAMPAIGN,
	TAG_UNASSIGN_TAGS,
	TAG_BULK_TAG_STATUS_CHANGE,
	TAG_DELETE_TAGS,
	TAG_GET_TAG_OUTCOMES,
	TAG_SET_TAG_OUTCOMES,
	TAG_REGISTER_TAG_OUTCOMES,
	TAG_GET_TAGS_IMPORT_JOB_REPORT,
	TAG_SET_TAGS_IMPORT_JOB_REPORT,
} from './constants';

// ########## ########## ########## ##########
// saga actions

export const getPageableTagsAction = payload => {
	//[DEBUG] console.log('getPageableTagsAction, payload =', payload);
	return {
		type: TAG_GET_PAGEABLE_TAGS,
		payload,
	};
};

export const registerTagAction = payload => {
	//[DEBUG] console.log('registerTagAction, payload =', payload);
	return {
		type: TAG_REGISTER_TAG,
		payload,
	};
};

export const updateTagAction = payload => {
	//[DEBUG] console.log('updateTagAction, payload =', payload);
	return {
		type: TAG_UPDATE_TAG,
		payload,
	};
};

export const deleteTagAction = payload => {
	//[DEBUG] console.log('deleteTagAction, payload =', payload);
	return {
		type: TAG_DELETE_TAG,
		payload,
	};
};

export const getTagCountersAction = payload => {
	//[DEBUG] console.log('getTagCountersAction, payload =', payload);
	return {
		type: TAG_GET_TAG_COUNTERS,
		payload,
	};
};

export const updateTagCountersAction = payload => {
	//[DEBUG] console.log('updateTagCountersAction, payload =', payload);
	return {
		type: TAG_UPDATE_TAG_COUNTERS,
		payload,
	};
};

export const assignTagsToCampaignAction = payload => {
	//[DEBUG] console.log('assignTagsToCampaignAction, payload =', payload);
	return {
		type: TAG_ASSIGN_TAGS_TO_CAMPAIGN,
		payload,
	};
};

export const unassignTagsAction = payload => {
	//[DEBUG] console.log('unassignTagsAction, payload =', payload);
	return {
		type: TAG_UNASSIGN_TAGS,
		payload,
	};
};

export const bulkTagStatusChangeAction = payload => {
	//[DEBUG] console.log('bulkTagStatusChangeAction, payload =', payload);
	return {
		type: TAG_BULK_TAG_STATUS_CHANGE,
		payload,
	};
};

export const deleteTagsAction = payload => {
	//[DEBUG] console.log('deleteTagsAction, payload =', payload);
	return {
		type: TAG_DELETE_TAGS,
		payload,
	};
};

export const getTagOutcomesAction = payload => {
	//[DEBUG] console.log('getOutcomesAction, payload =', payload);
	return {
		type: TAG_GET_TAG_OUTCOMES,
		payload,
	};
};

export const registerTagOutcomesAction = payload => {
	//[DEBUG] console.log('registerTagOutcomesAction, payload =', payload);
	return {
		type: TAG_REGISTER_TAG_OUTCOMES,
		payload,
	};
};

export const getTagsImportJobReportAction = payload => {
	//[DEBUG] console.log('getTagsImportJobReportAction, payload =', payload);
	return {
		type: TAG_GET_TAGS_IMPORT_JOB_REPORT,
		payload,
	};
};

// ########## ########## ########## ##########
// redux actions

export const setPageableTagsAction = pageableTags => {
	//[DEBUG] console.log('setPageableTagsAction, pageableTags =', pageableTags);
	return {
		type: TAG_SET_PAGEABLE_TAGS,
		pageableTags,
	};
};

export const setTagCountersAction = ({ tagObject, tagCounters }) => {
	/*[DEBUG]
	/*/ console.log('setTagCountersAction, ({ tagObject, tagCounters }) =', {
		tagObject,
		tagCounters,
	});
	//*/
	return {
		type: TAG_SET_TAG_COUNTERS,
		tagId: tagObject.tagId,
		tagCounters,
	};
};

export const setTagOutcomesAction = ({ tagObject, outcomesObject }) => {
	/*[DEBUG]
	/*/ console.log('setTagOutcomesAction, ({ tagObject, outcomesObject }) =', {
		tagObject,
		outcomesObject,
	});
	//*/
	return {
		type: TAG_SET_TAG_OUTCOMES,
		definitionType: 'REDIRECTION',
		tagId: tagObject.tagId,
		outcomesObject,
	};
};

export const setTagsImportJobReportAction = ({
	campaignImportJob,
	importTagsReportData,
}) => {
	//[DEBUG] console.log('getTagsImportJobReportAction, payload =', payload);
	return {
		type: TAG_SET_TAGS_IMPORT_JOB_REPORT,
		jobId: campaignImportJob.jobId,
		importTagsReportData,
	};
};

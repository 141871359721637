import {
	AUTH_REQUEST_BASIC_DATA,
	// AUTH_SET_TOKEN_DATA,
	AUTH_ACCEPT_TERMS_FOR_COMPANY,
	AUTH_REVOKE_TERMS_FOR_COMPANY,
	AUTH_LOGOUT_USER,
	AUTH_REQUEST_TOKEN_AUTHORIZED,
	AUTH_SET_AUTHENTICATED,
} from './constants';

// ########## ########## ########## ##########
// saga actions

export const requestBasicDataAction = () => {
	//[DEBUG] console.log('requestBasicDataAction start');
	return {
		type: AUTH_REQUEST_BASIC_DATA,
	};
};

/*
export const setTokenDataAction = myTokenData => {
	console.log('setTokenDataAction myTokenData =', myTokenData);
	return {
		type: AUTH_SET_TOKEN_DATA,
		myUserId: myTokenData.myUserId,
		myCompanyId: myTokenData.myCompanyId,
	};
};
*/

export const requestTokenAuthorizedAction = () => {
	//[DEBUG] console.log('requestTokenAuthorizedAction start');
	return {
		type: AUTH_REQUEST_TOKEN_AUTHORIZED,
	};
};

export const acceptTermsForCompanyAction = payload => {
	//[DEBUG] console.log('acceptTermsForCompanyAction payload =', payload);
	return {
		type: AUTH_ACCEPT_TERMS_FOR_COMPANY,
		payload,
	};
};

export const revokeTermsForCompanyAction = payload => {
	//[DEBUG] console.log('revokeTermsForCompanyAction payload =', payload);
	return {
		type: AUTH_REVOKE_TERMS_FOR_COMPANY,
		payload,
	};
};

export const logoutUserAction = () => {
	return {
		type: AUTH_LOGOUT_USER,
	};
};

// ########## ########## ########## ##########
// redux actions

export const setAuthenticatedAction = authenticated => {
	//[DEBUG] console.log('setAuthenticatedAction authenticated = ', authenticated);
	return {
		type: AUTH_SET_AUTHENTICATED,
		authenticated,
	};
};

import {
	APIKEY_GET_PAGEABLE_APIKEYS,
	APIKEY_SET_PAGEABLE_APIKEYS,
	APIKEY_REGISTER_APIKEY,
	APIKEY_DELETE_APIKEY,
	APIKEY_SHOW_APIKEY_IN_PAGEABLE_GET,
	APIKEY_SHOW_APIKEY_IN_PAGEABLE_SET,
} from './constants';

// ########## ########## ########## ##########
// saga actions

export const showApikeyInPageableGetAction = payload => {
	//[DEBUG] console.log('showApikeyInPageableGetAction, payload =', payload);
	return {
		type: APIKEY_SHOW_APIKEY_IN_PAGEABLE_GET,
		payload,
	};
};

export const getPageableApikeysAction = payload => {
	//[DEBUG] console.log('getPageableApikeysAction, payload =', payload);
	return {
		type: APIKEY_GET_PAGEABLE_APIKEYS,
		payload,
	};
};

export const registerApikeyAction = payload => {
	//[DEBUG] console.log('registerApikeyAction, payload =', payload);
	return {
		type: APIKEY_REGISTER_APIKEY,
		payload,
	};
};

export const deleteApikeyAction = payload => {
	//[DEBUG] console.log('deleteApikeyAction, payload =', payload);
	return {
		type: APIKEY_DELETE_APIKEY,
		payload,
	};
};

// ########## ########## ########## ##########
// redux actions

export const setPageableApikeysAction = pageableApikeys => {
	//[DEBUG] console.log('setPageableApikeysAction, pageableApikeys =', pageableApikeys);
	return {
		type: APIKEY_SET_PAGEABLE_APIKEYS,
		pageableApikeys,
	};
};

export const showApikeyInPageableSetAction = apikeyObject => {
	//[DEBUG] console.log('showApikeyInPageableSetAction, apikeyObject =', apikeyObject);
	return {
		type: APIKEY_SHOW_APIKEY_IN_PAGEABLE_SET,
		apikeyObject,
	};
};

import request from '../request';
import { authToken } from '../session-helpers';
import { DASHBOARD_URL_REQUEST_DASHBOARD_func } from '../constants/url-paths';

export const requestDashboardAPI = ({ tabType }) => {
	//[DEBUG] console.log('requestDashboardAPI, tabType = tabType');

	return request(DASHBOARD_URL_REQUEST_DASHBOARD_func({ tabType }), {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authToken()}`,
		},
	}).then(response => Promise.resolve(response));
};

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';

import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import IdleTimerPopup from './components/statefull-reduxed/idle-timer-popup';
import * as WorkerTimers from 'worker-timers';
import Loader from './components/statefull-reduxed/loader';
import NXPNotifications from './components/statefull-reduxed/notifications';
import GeneralErrorDialog from './components/statefull-reduxed/general-error-dialog';

import {
	ProtectedRoutes,
	UnprotectedRoutes,
	InitialRoutes,
} from './pages-dialogs/routes';
import {
	requestBasicDataAction,
	requestTokenAuthorizedAction,
	logoutUserAction,
	setAuthenticatedAction,
} from './saga-redux-modules/auth/action';
import { setAppStatusAction } from './saga-redux-modules/bootstrap/action';
import { authenticatedUser } from './utils/session-helpers';

//[IMPROVE] shorten this line some day
//[THINK] maybe check all eslint-disable and try to remove some
// eslint-disable-next-line max-len
import RequestStatusInProgress from './pages-dialogs/pages/common-special/request-status-in-progress';
import theme from './theme';

export class App extends Component {
	secondsToShowBeforeLogout = 90; // timer shown before user gets logged out
	secondsBeforeRefreshToken = 120;
	secondsBeforeFirstRefreshToken = 30;
	idleTime = 1000 * 60 * 12; // 12 minutes until user is idle (1000 * 60 * 12)
	idleTimer = null;

	state = {
		permissionsUpToDate: false,
		secondsRemainingBeforeLogout: this.secondsToShowBeforeLogout,
		showAutoLogout: false,
	};

	onAction = e => {
		//[DEBUG] console.log('User did something e =', e);
		if (!this.refreshTokenTimer && !this.state.showAutoLogout) {
			this.refreshTokenTimer = true;
			WorkerTimers.setTimeout(() => {
				this.refreshTokenTimer = WorkerTimers.setInterval(() => {
					this.props.requestTokenAuthorized();
				}, this.secondsBeforeRefreshToken * 1000);
			}, this.secondsBeforeFirstRefreshToken * 1000); // no first quick refresh
		}
	};

	onActive = confirm => {
		if (confirm === 'confirm' || !this.state.showAutoLogout) {
			this.setState({
				showAutoLogout: false,
			});
			this.setState({
				secondsRemainingBeforeLogout: this.secondsToShowBeforeLogout,
			});
			if (this.countDownTimer) {
				WorkerTimers.clearInterval(this.countDownTimer);
				this.countDownTimer = null;
			}
		}
	};

	onIdle = () => {
		if (this.refreshTokenTimer) {
			WorkerTimers.clearInterval(this.refreshTokenTimer);
			this.refreshTokenTimer = null;
		}

		this.setState({ showAutoLogout: true });
		this.countDownTimer = WorkerTimers.setInterval(() => {
			const { secondsRemainingBeforeLogout } = this.state;
			if (secondsRemainingBeforeLogout === 0) {
				this.props.logoutUser();
			} else if (secondsRemainingBeforeLogout < 0) {
				this.props.history.push('/logout-error');
				this.setState({ logoutError: true });
			} else {
				this.setState(prevState => ({
					secondsRemainingBeforeLogout: prevState.secondsRemainingBeforeLogout - 1,
				}));
			}
		}, 1000);
	};

	renderRoutes = () => {
		const { location, authenticated, status, requestBasicData } = this.props;

		if (!this.state.logoutError && (authenticated || authenticatedUser())) {
			//[DEBUG] console.log('App.renderRoutes AUTHENTICATED');
			return (
				<Fragment>
					<NXPNotifications />
					<IdleTimer
						ref={ref => {
							this.idleTimer = ref;
						}}
						element={document}
						onAction={this.onAction}
						debounce={200} // machine protection, continuous mouse move removal
						onActive={this.onActive}
						onIdle={this.onIdle}
						timeout={this.idleTime}
					/>
					<Fragment>
						{this.state.showAutoLogout && (
							<IdleTimerPopup
								open={this.state.showAutoLogout}
								onCancel={() => null}
								onConfirm={() => null}
								action={() => this.onActive('confirm')}
								time={this.state.secondsRemainingBeforeLogout}
							/>
						)}
						<ProtectedRoutes location={location} breadcrumbs={this.defineBreadcrumbs()} />
					</Fragment>
				</Fragment>
			);
		} else {
			switch (status) {
				case 'CREATED':
					//[DEBUG] console.log('App.renderRoutes CREATED');
					return UnprotectedRoutes(location);
				case 'CLEAR':
					//[DEBUG] console.log('App.renderRoutes CLEAR');
					return InitialRoutes(location);
				default:
					//[DEBUG] console.log('App.renderRoutes requestBasicData');
					requestBasicData();
					return <RequestStatusInProgress />;
			}
		}
	};

	//[IMPROVE] move this to main layout
	defineBreadcrumbs = () => {
		const breadcrumbs = this.props.location.pathname
			.split('/')
			.filter(item => item !== '');

		/** don't show breadcrumbs if on the top route - don't need to link to itself */
		return breadcrumbs.length < 2 ? [] : breadcrumbs;
	};

	render() {
		return (
			<MuiThemeProvider theme={theme}>
				<CssBaseline />
				<div className="App">
					<Loader />
					<GeneralErrorDialog />
					{this.renderRoutes()}
				</div>
			</MuiThemeProvider>
		);
	}
}

App.propTypes = {
	location: PropTypes.object,
};

export const mapStateToProps = ({
	router,
	authReducer,
	accountReducer,
	bootstrapReducer,
}) => {
	//[DEBUG] console.log('authReducer.authenticated =', authReducer.authenticated);
	return {
		location: router.location,
		myUserRole: accountReducer.myUserData.role,
		authenticated: authReducer.authenticated,
		status: bootstrapReducer.status,
	};
};

export const mapDispatchToProps = dispatch => {
	return {
		requestBasicData: () => dispatch(requestBasicDataAction()),
		requestTokenAuthorized: () => dispatch(requestTokenAuthorizedAction()),
		logoutUser: () => dispatch(logoutUserAction()),
		setAppStatus: status => dispatch(setAppStatusAction(status)),
		setAuthenticated: authenticated => dispatch(setAuthenticatedAction(authenticated)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

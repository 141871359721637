import {
	ACCOUNT_SET_TOKEN_DATA,
	ACCOUNT_SET_MY_USER_DATA,
	ACCOUNT_SET_MY_ALTERNATIVE_ROLE_VIEW,
} from './constants';

export const initialState = {
	myUserData: {
		company: {
			features: {},
		},
		email: '',
		role: '', // 'USER_COMPANY_VIEWER',
	},
	alternativeRoleView: '',
	// no actual program dependency on this one
	// myBackendAccessRole: 'USER_COMPANY_VIEWER', (is in my user Data)
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ACCOUNT_SET_TOKEN_DATA: {
			return {
				...state,
				myUserId: action.myUserId,
				myCompanyId: action.myCompanyId,
			};
		}
		case ACCOUNT_SET_MY_USER_DATA: {
			return {
				...state,
				myUserData: action.myUserData,
			};
		}
		case ACCOUNT_SET_MY_ALTERNATIVE_ROLE_VIEW: {
			return {
				...state,
				alternativeRoleView: action.alternativeRoleView,
			};
		}
		/*
		case ACCOUNT_SET_MY_BACKEND_ACCESS_ROLE: {
			return {
				...state,
				myBackendAccessRole: action.myBackendAccessRole,
			};
		}
		*/
		default:
			return state;
	}
};

import { makeStyles } from '@material-ui/styles';

const createStyles = makeStyles({
	header: {
		fontWeight: 600,
		display: 'flex',
		alignItems: 'center',
	},
	timer: {
		background: '#f4f5f7',
		borderBottom: '3px solid #E3EDF1',
		fontSize: 20,
		padding: '0 8px',
		marginRight: 7,
	},
});

export default createStyles;

/* eslint-disable */
/*
export const PUBLISHED = 'PUBLISHED';
export const INVALID_CONFIG = 'INVALID_CONFIG';
export const NEEDS_OUTCOME = 'NEEDS_OUTCOME';
export const PUBLISHING = 'PUBLISHING';
export const DELETED = 'DELETED';
*/

export const DRAFT = 'DRAFT';
export const PUBLISHED = 'PUBLISHED';
export const INACTIVE = 'INACTIVE';

export const APPLICATION = 'APPLICATION';
export const REDIRECTION = 'REDIRECTION';
export const BOTH = 'BOTH';

export const OUTCOME_CONDITION_VALID = 'OUTCOME_CONDITION_VALID';
export const OUTCOME_CONDITION_UNIQUE_TAG = 'OUTCOME_CONDITION_UNIQUE_TAG';
export const OUTCOME_CONDITION_AUTHENTIC_CHIP = 'OUTCOME_CONDITION_AUTHENTIC_CHIP';
export const OUTCOME_CONDITION_AUTHENTIC_PRODUCT = 'OUTCOME_CONDITION_AUTHENTIC_PRODUCT';
export const OUTCOME_CONDITION_AUTHENTIC_DIVERSIFIED = 'OUTCOME_CONDITION_AUTHENTIC_DIVERSIFIED';
export const OUTCOME_CONDITION_CAMPAIGN_INACTIVE = 'OUTCOME_CONDITION_CAMPAIGN_INACTIVE';
export const OUTCOME_CONDITION_NFC_COUNTER = 'OUTCOME_CONDITION_NFC_COUNTER';
export const OUTCOME_CONDITION_TAG_TAPS_LIMIT = 'OUTCOME_CONDITION_TAG_TAPS_LIMIT';
export const OUTCOME_CONDITION_TAMPER_NOW_CLOSED = 'OUTCOME_CONDITION_TAMPER_NOW_CLOSED';

export const ALL_CAMPAIGN_STATUSES = [DRAFT, PUBLISHED, INACTIVE];

/*
export const VALID = 'VALID'; // # top level
export const INVALID = 'INVALID'; // # top level
export const TAG_TAMPER = 'TAG_TAMPER'; // # subtype of VALID
export const NOT_ORIGINAL = 'NOT_ORIGINAL'; // # subtype of INVALID
export const CAMPAIGN_INACTIVE = 'CAMPAIGN_INACTIVE'; // # subtype of INVALID
export const COUNTER_EXPIRED = 'COUNTER_EXPIRED'; // # subtype of INVALID
export const COUNTER_INVALID = 'COUNTER_INVALID'; // # subtype of INVALID
*/

//TagImportStatus.status
export const IMPORT_IN_PROGRESS = "IMPORT_IN_PROGRESS";
export const IMPORT_DONE = "IMPORT_DONE";
export const IMPORT_ERROR = "IMPORT_ERROR";

import request from '../request';
import {
	BOOTSTRAP_URL_REQUEST_APP_STATUS,
	BOOTSTRAP_URL_REGISTER_APP,
} from '../constants/url-paths';

export const requestAppStatusAPI = () => {
	return request(BOOTSTRAP_URL_REQUEST_APP_STATUS, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	}).then(response => Promise.resolve(response));
};

export const registerGlobalAdminAPI = globalAdminObject => {
	const { email } = globalAdminObject;

	/*
	const company = {
		id: '',
		name: '',
		address: '',
		features: [],
	};
	*/

	const companyAdmin = {
		// id: '',
		email,
		// roles: [],
		// company,
	};

	const body = JSON.stringify({
		...companyAdmin,
	});

	return request(BOOTSTRAP_URL_REGISTER_APP, {
		method: 'POST',
		headers: {
			// no authorization for this one
			'Content-Type': 'application/json',
		},
		body,
	}).then(response => Promise.resolve(response));
};

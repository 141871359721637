import {
	APIKEY_SET_PAGEABLE_APIKEYS,
	APIKEY_SHOW_APIKEY_IN_PAGEABLE_SET,
} from './constants';

export const initialState = {
	pageableApikeys: {
		content: [],
	},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case APIKEY_SHOW_APIKEY_IN_PAGEABLE_SET: {
			return {
				...state,
				pageableApikeys: {
					...state.pageableApikeys,
					content: state.pageableApikeys.content.map(k =>
						k.role !== action.apikeyObject.role ? k : action.apikeyObject
					),
				},
			};
		}
		case APIKEY_SET_PAGEABLE_APIKEYS: {
			return {
				...state,
				pageableApikeys: action.pageableApikeys,
			};
		}
		default:
			return state;
	}
};

import { USER_NXP_ADMIN } from '../constants/user';

export const myCurrentRoleView = accountReducer => {
	return accountReducer.alternativeRoleView || accountReducer.myUserData.role;
};

export const signedOnPaperLicence = accountReducer => {
	return (
		accountReducer.myUserData.company &&
		accountReducer.myUserData.company.licenceType === 'SIGNED_ON_PAPER'
	);
};

export const isMyUserGlobalAdmin = accountReducer => {
	return myCurrentRoleView(accountReducer) === USER_NXP_ADMIN;
};

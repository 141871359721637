import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import accountReducer from './saga-redux-modules/account/redux';
import apikeyReducer from './saga-redux-modules/apikey/redux';
import authReducer from './saga-redux-modules/auth/redux';
import bootstrapReducer from './saga-redux-modules/bootstrap/redux';
import dashboardReducer from './saga-redux-modules/dashboard/redux';
import featuresReducer from './saga-redux-modules/features/redux';
import generalReducer from './saga-redux-modules/general/redux';
import keyReducer from './saga-redux-modules/key/redux';
import userReducer from './saga-redux-modules/user/redux';
import campaignReducer from './saga-redux-modules/campaign/redux';
import tagReducer from './saga-redux-modules/tag/redux';
import privacykeyReducer from './saga-redux-modules/privacykey/redux';
import fileReducer from './saga-redux-modules/file/redux';

export default history =>
	combineReducers({
		router: connectRouter(history),
		accountReducer,
		apikeyReducer,
		authReducer,
		bootstrapReducer,
		dashboardReducer,
		featuresReducer,
		generalReducer,
		keyReducer,
		userReducer,
		campaignReducer,
		tagReducer,
		privacykeyReducer,
		fileReducer,
	});

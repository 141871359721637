const styles = {
	titleWrapper: {},
	title: {
		fontSize: 20,
		color: '#4C677E',
	},
	subtitle: {
		fontSize: 12,
		color: '#9BA5B7',
	},
	dialogWrapper: {
		width: 450,
		minWidth: 450,
		minHeight: 250,
	},
	description: {
		borderLeft: '2px solid #0086C8',
		marginBottom: 34,
		paddingLeft: 15,
		color: '#4C677E',
	},
	confirmationText: {
		color: '#4C677E',
		fontWeight: 300,
	},
	content: {
		color: '#4C677E',
		fontSize: 12,
	},
	actionRow: {
		height: 70,
		marginLeft: -55,
		marginRight: -55,
		width: 'calc(100% + 110px)',
		backgroundColor: '#F4F5F7',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: 55,
		paddingRight: 55,
		marginTop: 30,
	},
	confirmButton: {
		height: 30,
		backgroundColor: '#0086C8',
		padding: '0 16px',
		color: '#FFF',
		marginRight: 10,
		fontSize: 11,
		borderRadius: 3,
		textTransform: 'none',
		width: 100,
		boxShadow: `rgba(0, 0, 0, 0.2) 0px 1px 8px 0px,
		rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px -2px`,
	},
	cancelButton: {
		border: 'none',
		textTransform: 'capitalize',
		height: 30,
		padding: 0,
		fontSize: 12,
		color: '#0086C8',
		backgroundColor: 'transparent',
		display: 'flex',
		justifyContent: 'flex-end',
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'&:disabled': {
			color: '#9BA5B7',
		},
	},
	loadingBtn: {
		boxShadow: `rgba(0, 0, 0, 0.2) 0px 1px 8px 0px,
			rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px -2px`,
		backgroundColor: '#0086C8',
		color: '#FFF !important',
		border: 'none',
		fontWeight: 'bold',
	},
	successBtn: {
		boxShadow: `rgba(0, 0, 0, 0.2) 0px 1px 8px 0px,
			rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px -2px`,
		backgroundColor: '#99D360',
		color: '#FFF',
		border: 'none',
		fontWeight: 'bold',
		'&:hover': {
			backgroundColor: '#99D360',
		},
	},
	failBtn: {
		boxShadow: `rgba(0, 0, 0, 0.2) 0px 1px 8px 0px,
			rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 3px 3px -2px`,
		backgroundColor: '#EC5C57',
		color: '#FFF',
		border: 'none',
		fontWeight: 'bold',
		'&:hover': {
			backgroundColor: '#EC5C57',
		},
	},
	'@keyframes spin': {
		from: { transform: 'rotate(0deg)' },
		to: { transform: 'rotate(360deg)' },
	},
	loader: {
		border: '1px solid #FFF',
		borderRadius: '100%',
		height: 10,
		width: 10,
		borderLeft: 'none',
		animation: '$spin 500ms linear infinite',
		marginRight: 5,
		marginLeft: 5,
	},
	customContent: {
		padding: '30px 55px 0 55px',
	},
	dialogHeader: {
		paddingLeft: 29,
		paddingRight: 55,
	},
	actionBtnIcon: {
		height: 15,
		width: 15,
		color: '#FFF',
		marginRight: 3,
	},
	btnDisabled: {
		color: '#FFF !important',
	},
	btnText: {
		'&:hover': {
			background: '#0086C8',
			opacity: 1,
		},
	},
	backgroundRed: {
		background: '#EC5C57',
		'&:hover': {
			background: '#EC5C57',
		},
	},
	backgroundHoverRed: {
		'&:hover': {
			background: '#EC5C57',
		},
	},
	colorRed: {
		color: '#EC5C57',
	},
};

export default styles;

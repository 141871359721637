import {
	GENERAL_TOGGLE_LOADER,
	GENERAL_SET_DIALOG_ERROR,
	GENERAL_SET_NEXT_PAGE,
	GENERAL_SET_GENERAL_ERROR_MESSAGE,
} from './constants';

export const toggleLoaderAction = (showLoader = false) => {
	return {
		type: GENERAL_TOGGLE_LOADER,
		showLoader,
	};
};

export const setNextPageAction = nextPage => {
	return {
		type: GENERAL_SET_NEXT_PAGE,
		nextPage,
	};
};

export const setDialogErrorAction = payload => ({
	type: GENERAL_SET_DIALOG_ERROR,
	payload,
});

export const setGeneralErrorMessageAction = generalErrorObject => ({
	type: GENERAL_SET_GENERAL_ERROR_MESSAGE,
	generalErrorObject,
});

import {
	USER_GET_PAGEABLE_USERS_MY_COMPANY,
	USER_SET_PAGEABLE_USERS_MY_COMPANY,
	USER_REGISTER_USER,
	USER_UPDATE_USER,
	USER_DELETE_USER_WITH_ID,
} from '../constants';

// ########## ########## ########## ##########
// saga actions

export const getPageableUsersAction = payload => {
	//[DEBUG] console.log('getPageableUsersAction payload =', payload);
	return {
		type: USER_GET_PAGEABLE_USERS_MY_COMPANY,
		payload,
	};
};

export const registerUserAction = payload => {
	//[DEBUG] console.log('registerUserAction payload = ', payload);
	return {
		type: USER_REGISTER_USER,
		payload,
	};
};

export const updateUserAction = payload => {
	//[DEBUG] console.log('updateUserAction payload = ', payload);
	return {
		type: USER_UPDATE_USER,
		payload,
	};
};

export const deleteUserAction = payload => {
	//[DEBUG] console.log('deleteUserAction payload = ', payload);
	return {
		type: USER_DELETE_USER_WITH_ID,
		payload,
	};
};

// ########## ########## ########## ##########
// redux actions

export const setPageableUsersAction = pageableUsers => {
	//[DEBUG] console.log('setPageableUsersAction pageableUsers =', pageableUsers);
	return {
		type: USER_SET_PAGEABLE_USERS_MY_COMPANY,
		pageableUsers,
	};
};

// ACTION CONSTANTS

export const TAG_GET_PAGEABLE_TAGS = 'TAG_GET_PAGEABLE_TAGS';
export const TAG_SET_PAGEABLE_TAGS = 'TAG_SET_PAGEABLE_TAGS';

export const TAG_REGISTER_TAG = 'TAG_REGISTER_TAG';
export const TAG_UPDATE_TAG = 'TAG_UPDATE_TAG';
export const TAG_DELETE_TAG = 'TAG_DELETE_TAG';

export const TAG_GET_TAG_COUNTERS = 'TAG_GET_TAG_COUNTERS';
export const TAG_SET_TAG_COUNTERS = 'TAG_SET_TAG_COUNTERS';

export const TAG_UPDATE_TAG_COUNTERS = 'TAG_UPDATE_TAG_COUNTERS';

// export const TAG_ASSIGN_TAG_TO_CAMPAIGN = 'TAG_ASSIGN_TAG_TO_CAMPAIGN';
export const TAG_ASSIGN_TAGS_TO_CAMPAIGN = 'TAG_ASSIGN_TAGS_TO_CAMPAIGN';
export const TAG_UNASSIGN_TAGS = 'TAG_UNASSIGN_TAGS';
export const TAG_BULK_TAG_STATUS_CHANGE = 'TAG_BULK_TAG_STATUS_CHANGE';
export const TAG_DELETE_TAGS = 'TAG_DELETE_TAGS';

export const TAG_GET_TAG_OUTCOMES = 'TAG_GET_TAG_OUTCOMES';
export const TAG_REGISTER_TAG_OUTCOMES = 'TAG_REGISTER_TAG_OUTCOMES';
export const TAG_SET_TAG_OUTCOMES = 'TAG_SET_TAG_OUTCOMES';

export const TAG_GET_TAGS_IMPORT_JOB_REPORT = 'TAG_GET_TAGS_IMPORT_JOB_REPORT';
export const TAG_SET_TAGS_IMPORT_JOB_REPORT = 'TAG_SET_TAGS_IMPORT_JOB_REPORT';

// export const TAG_SET_PAGEABLE_OUTCOMES_OF_TAG = 'TAG_SET_PAGEABLE_OUTCOMES_OF_TAG';

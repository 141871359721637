import {
	USER_SET_PAGEABLE_COMPANIES,
	USER_SET_PAGEABLE_USERS_OF_COMPANY,
	USER_SET_FORM_ERROR,
	USER_SET_PAGEABLE_USERS_MY_COMPANY,
} from './constants';

export const initialState = {
	pageableCompanies: {
		content: [],
	},
	pageableUsers: {
		content: [],
	},
	userData: null,
	formError: {
		type: '',
		message: '',
	},
	userFormErrors: null,
	pageableUsersByCompanies: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case USER_SET_PAGEABLE_COMPANIES: {
			return {
				...state,
				pageableCompanies: action.pageableCompanies,
			};
		}
		case USER_SET_PAGEABLE_USERS_MY_COMPANY: {
			return {
				...state,
				pageableUsers: action.pageableUsers,
			};
		}
		case USER_SET_FORM_ERROR: {
			return {
				...state,
				formError: {
					type: action.payload.type,
					message: action.payload.message,
				},
			};
		}
		case USER_SET_PAGEABLE_USERS_OF_COMPANY: {
			return {
				...state,
				pageableUsersByCompanies: {
					...state.pageableUsersByCompanies,
					[action.companyId]: action.pageableUsers,
				},
			};
		}
		default:
			return state;
	}
};

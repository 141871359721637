import {
	IMPORT_JOB_REPORT_NAME,
	PRIVACY_PDF_NAME,
	TERMS_PDF_NAME,
	TAG_EXPORT_CSV_NAME,
	ATTRIBUTIONS_JSON_NAME,
	DIVERSIFY_JOB_RESPONSE,
	PASSWORD_GENERATOR_JOB_RESPONSE,
} from '../constants/constants';
import {
	HTML_PRIVACY,
	HTML_TERMS,
	JSON_ATTRIBUTIONS,
	PDF_PRIVACY,
	PDF_TERMS,
} from '../constants/file';
import {
	FILE_DOWNLOAD_URL_DOCUMENT_BY_TYPE_func,
	KEY_URL_GET_DIVERSIFY_KEY_JOB_REPORT_func,
	KEY_URL_GET_PASSWORD_GENERATOR_JOB_REPORT_func,
	TAG_URL_GET_TAGS_CSV_EXPORT_func,
	TAG_URL_GET_TAGS_IMPORT_JOB_REPORT_func,
} from '../constants/url-paths';
import request from '../request';
import { authToken } from '../session-helpers';

const createPdfBlob = base64 => {
	const text = atob(base64);
	const byteNumbers = Array.from({ length: text.length });
	for (let i = 0; i < text.length; i++) {
		byteNumbers[i] = text.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);

	return new Blob([byteArray], { type: 'PDF' });
};

const createCsvBlob = text => {
	const byteNumbers = Array.from({ length: text.length });
	for (let i = 0; i < text.length; i++) {
		byteNumbers[i] = text.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);

	return new Blob([byteArray], { type: 'CSV' });
};

const createAscBlob = base64 => {
	const text = atob(base64);
	const byteNumbers = Array.from({ length: text.length });
	for (let i = 0; i < text.length; i++) {
		byteNumbers[i] = text.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);

	return new Blob([byteArray], { type: 'ASC' });
};

const createJsonBlob = base64 => {
	const text = atob(base64);
	const byteNumbers = Array.from({ length: text.length });
	for (let i = 0; i < text.length; i++) {
		byteNumbers[i] = text.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);

	return new Blob([byteArray], { type: 'JSON' });
};

/**
 * Gets terms and conditions HTML file
 */
export const getTermsHtmlAPI = () => {
	return request(
		FILE_DOWNLOAD_URL_DOCUMENT_BY_TYPE_func({
			documentType: HTML_TERMS,
		}),
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/html',
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

/**
 * Downloads the terms and conditions in the PDF format
 */
export const downloadTermsFileAPI = () => {
	return request(
		FILE_DOWNLOAD_URL_DOCUMENT_BY_TYPE_func({
			documentType: PDF_TERMS,
		}),
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => {
		const url = window.URL.createObjectURL(createPdfBlob(response.payload));
		const a = document.createElement('a');
		a.href = url;
		a.download = TERMS_PDF_NAME;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		a.remove();
		setTimeout(() => window.URL.revokeObjectURL(url), 100);
	});
};

/**
 * Gets privacy policy HTML file
 */
export const getPrivacyHtmlAPI = () => {
	return request(
		FILE_DOWNLOAD_URL_DOCUMENT_BY_TYPE_func({
			documentType: HTML_PRIVACY,
		}),
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/html',
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

/**
 * Downloads the privacy policy in the PDF format
 */
export const downloadPrivacyFileAPI = () => {
	return request(
		FILE_DOWNLOAD_URL_DOCUMENT_BY_TYPE_func({
			documentType: PDF_PRIVACY,
		}),
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => {
		const url = window.URL.createObjectURL(createPdfBlob(response.payload));
		const a = document.createElement('a');
		a.href = url;
		a.download = PRIVACY_PDF_NAME;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		a.remove();
		setTimeout(() => window.URL.revokeObjectURL(url), 100);
	});
};

export const getAttributionsJsonAPI = () => {
	return request(
		FILE_DOWNLOAD_URL_DOCUMENT_BY_TYPE_func({
			documentType: JSON_ATTRIBUTIONS,
		}),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

export const downloadAttributionsJsonAPI = () => {
	return request(
		FILE_DOWNLOAD_URL_DOCUMENT_BY_TYPE_func({
			documentType: JSON_ATTRIBUTIONS,
		}),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => {
		const url = window.URL.createObjectURL(createJsonBlob(response.payload));
		const a = document.createElement('a');
		a.href = url;
		a.download = ATTRIBUTIONS_JSON_NAME;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		a.remove();
		setTimeout(() => window.URL.revokeObjectURL(url), 100);
	});
};

export const downloadTagsImportJobReportAPI = payload => {
	//[DEBUG] console.log('getTagsImportJobReportAPI payload = ', payload);
	const { campaignImportJob } = payload;
	const { jobId } = campaignImportJob;

	return request(
		TAG_URL_GET_TAGS_IMPORT_JOB_REPORT_func({
			jobId,
		}),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => {
		const url = window.URL.createObjectURL(createCsvBlob(response));
		const a = document.createElement('a');
		a.href = url;
		a.download = IMPORT_JOB_REPORT_NAME;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		a.remove();
		setTimeout(() => window.URL.revokeObjectURL(url), 100);
	});
};

export const getTagsCsvExportAPI = payload => {
	//[DEBUG] console.log('getTagsCsvExportAPI payload = ', payload);

	const { pageableParameters, filterParameters } = payload;

	return request(
		TAG_URL_GET_TAGS_CSV_EXPORT_func({ pageableParameters, filterParameters }),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => {
		const url = window.URL.createObjectURL(createCsvBlob(response));
		const a = document.createElement('a');
		a.href = url;
		a.download = TAG_EXPORT_CSV_NAME;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		a.remove();
		setTimeout(() => window.URL.revokeObjectURL(url), 100);
	});
};

export const downloadDiversifyKeyJobReportAPI = payload => {
	//[DEBUG] console.log('downloadDiversifyKeyJobReportAPI payload = ', payload);
	const { keyDiversifyJob } = payload;
	const { jobId } = keyDiversifyJob;

	return request(
		KEY_URL_GET_DIVERSIFY_KEY_JOB_REPORT_func({
			jobId,
		}),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => {
		const url = window.URL.createObjectURL(createAscBlob(response.payload));
		const a = document.createElement('a');
		a.href = url;
		a.download = DIVERSIFY_JOB_RESPONSE;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		a.remove();
		setTimeout(() => window.URL.revokeObjectURL(url), 100);
	});
};

export const downloadPasswordGeneratorJobReportAPI = payload => {
	//[DEBUG] console.log('downloadPasswordGeneratorJobReportAPI payload = ', payload);
	const { passwordGeneratorJob } = payload;
	const { jobId } = passwordGeneratorJob;

	return request(
		KEY_URL_GET_PASSWORD_GENERATOR_JOB_REPORT_func({
			jobId,
		}),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => {
		const url = window.URL.createObjectURL(createAscBlob(response.payload));
		const a = document.createElement('a');
		a.href = url;
		a.download = PASSWORD_GENERATOR_JOB_RESPONSE;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		a.remove();
		setTimeout(() => window.URL.revokeObjectURL(url), 100);
	});
};

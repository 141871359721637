/* eslint-disable max-len */
/* eslint-disable array-bracket-spacing */
/* eslint-disable no-multi-spaces */
///* eslint-disable prettier/prettier */

export const NTAG_210u = 'NTAG_210u';
export const NTAG_213 = 'NTAG_213';
export const NTAG_213TT = 'NTAG_213TT';
export const NTAG_22X = 'NTAG_22X';
export const NTAG_22XTT = 'NTAG_22XTT';
export const NTAG_424 = 'NTAG_424';
export const NTAG_424TT = 'NTAG_424TT';

export const VALID = 'VALID'; // # top level
export const INVALID = 'INVALID'; // # top level
export const TAG_TAMPER = 'TAG_TAMPER'; // # subtype of VALID
export const TAG_TAMPER_NOW_CLOSED = 'TAG_TAMPER_NOW_CLOSED'; // # subtype of VALID
export const NOT_ORIGINAL = 'NOT_ORIGINAL'; // # subtype of INVALID
export const CAMPAIGN_INACTIVE = 'CAMPAIGN_INACTIVE'; // # subtype of INVALID
export const COUNTER_EXPIRED = 'COUNTER_EXPIRED'; // # subtype of INVALID
export const COUNTER_INVALID = 'COUNTER_INVALID'; // # subtype of INVALID

// caution not the same shorter version used by BE - maybe use longer version some day
export const TAG_STATUS_ACTIVE = 'ACTIVE';
export const TAG_STATUS_INACTIVE = 'INACTIVE';
export const TAG_STATUS_INVALID = 'INVALID';

export const ALL_TAG_STATUSES = [
	TAG_STATUS_ACTIVE,
	TAG_STATUS_INACTIVE,
	TAG_STATUS_INVALID,
];
export const TRANSITION_TO_TAG_STATUSES = [
	TAG_STATUS_ACTIVE,
	TAG_STATUS_INVALID,
];

export const ALL_TAG_TYPES = [
	NTAG_210u,
	NTAG_213,
	NTAG_213TT,
	NTAG_22X,
	NTAG_22XTT,
	NTAG_424,
	NTAG_424TT,
];

export const ALL_TAG_CAMPAIGN_STATUSES = [
	VALID,
	INVALID,
	TAG_TAMPER,
	TAG_TAMPER_NOW_CLOSED,
	NOT_ORIGINAL,
	CAMPAIGN_INACTIVE,
	COUNTER_EXPIRED,
	COUNTER_INVALID,
];

export const SUPPORT_VALID                   = [ NTAG_210u, NTAG_213, NTAG_213TT, NTAG_22X, NTAG_22XTT, NTAG_424, NTAG_424TT];
export const SUPPORT_TAG_TAMPER              = [                      NTAG_213TT,           NTAG_22XTT,           NTAG_424TT];
export const SUPPORT_TAG_TAMPER_NOW_CLOSED   = [                                            NTAG_22XTT,           NTAG_424TT];
export const SUPPORT_NOT_ORIGINAL            = [ NTAG_210u, NTAG_213, NTAG_213TT, NTAG_22X, NTAG_22XTT, NTAG_424, NTAG_424TT];
export const SUPPORT_INVALID                 = [ NTAG_210u, NTAG_213, NTAG_213TT, NTAG_22X, NTAG_22XTT, NTAG_424, NTAG_424TT];
export const SUPPORT_CAMPAIGN_INACTIVE       = [ NTAG_210u, NTAG_213, NTAG_213TT, NTAG_22X, NTAG_22XTT, NTAG_424, NTAG_424TT];
export const SUPPORT_COUNTER_INVALID         = [            NTAG_213, NTAG_213TT, NTAG_22X, NTAG_22XTT, NTAG_424, NTAG_424TT];
export const SUPPORT_COUNTER_EXPIRED         = [ NTAG_210u, NTAG_213, NTAG_213TT, NTAG_22X, NTAG_22XTT, NTAG_424, NTAG_424TT];

import React from 'react';
// import { requestTokenAuthorizedAction } from '../../../saga-redux-modules/auth/action';
import {
	LOCAL_URL_PATH,
	AUTH_URL_LOGIN_USER_WITH_ENCODED_URL,
} from '../../../../utils/constants/url-paths';

const LoginPage = ({ location }) => {
	// this has a priority now
	window.location = `${AUTH_URL_LOGIN_USER_WITH_ENCODED_URL}`;

	//[DEBUG] console.log('LoginPage start, location =', location);
	const path =
		location.pathname === '/login' || location.pathname === '/'
			? '/account'
			: location.pathname;
	const encodedURL = encodeURIComponent(`${LOCAL_URL_PATH}${path}`);
	console.log('LoginPage encodedURL', encodedURL);

	return <div></div>;
};

export default LoginPage;

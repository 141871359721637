import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
// import { push } from 'connected-react-router';
// import i18n from 'i18next';

import {
	getCompanyByIdAPI,
	getPageableCompaniesAPI,
	registerCompanyAPI,
	updateCompanyAPI,
	deleteCompanyAPI,
	getPageableUsersOfCompanyAPI,
} from '../../utils/api/user/company';
import {
	getPageableUsersAPI,
	registerUserAPI,
	updateUserAPI,
	deleteUserAPI,
	// getUserDetailsAPI,
	// getUsersListAPI,
} from '../../utils/api/user/user';
import { toggleLoaderAction, setGeneralErrorMessageAction } from '../general/action';
import {
	// setMyUserDataAction,
	setPageableCompaniesAction,
	setPageableUsersOfCompanyAction,
	setPageableUsersAction,
} from './action';
import {
	USER_GET_PAGEABLE_COMPANIES,
	// USER_SET_FORM_ERROR,
	USER_REGISTER_COMPANY,
	// USER_REGISTER_COMPANY_SUCCESS,
	// USER_REGISTER_COMPANY_ERROR,
	USER_UPDATE_COMPANY,
	// USER_UPDATE_COMPANY_SUCCESS,
	// USER_UPDATE_COMPANY_ERROR,
	USER_GET_COMPANY_BY_ID,
	USER_DELETE_COMPANY,
	USER_GET_PAGEABLE_USERS_OF_COMPANY,
	USER_GET_PAGEABLE_USERS_MY_COMPANY,
	USER_REGISTER_USER,
	USER_UPDATE_USER,
	USER_DELETE_USER_WITH_ID,
} from './constants';

// company saga worker

export function* getCompanyByIdWorkerSaga({ payload }) {
	//[DEBUG] console.log('getCompanyByIdWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(getCompanyByIdAPI, payload);
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success getCompanyByIdAS');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error getCompanyByIdAS');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on get company',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* getPageableCompaniesWorkerSaga({ payload }) {
	//[DEBUG] console.log('getPageableCompaniesWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		const companies = yield call(getPageableCompaniesAPI, payload);
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('getPageableCompaniesWorkerSaga companies =', companies);
		yield put(setPageableCompaniesAction(companies));
		//[DEBUG] console.log('Success getPageableCompaniesWorkerSaga');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error getPageableCompaniesWorkerSaga');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on pageable companies',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* registerCompanyWorkerSaga({ payload }) {
	//[DEBUG] console.log('registerCompanyWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(registerCompanyAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success registerCompanyWorkerSaga');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error registerCompanyWorkerSaga');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on register company',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* updateCompanyWorkerSaga({ payload }) {
	//[DEBUG] console.log('updateCompanyWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(updateCompanyAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success updateCompanyAS');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error updateCompanyAS');
		// eslint-disable-next-line
		console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);
	}
	yield put(toggleLoaderAction(false));
}

export function* deleteCompanyWorkerSaga({ payload }) {
	//[DEBUG] console.log('deleteCompanyWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(deleteCompanyAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success deleteCompanyAS');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error deleteCompanyAS');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on delete company',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* getPageableUsersOfCompanyWorkerSaga({ payload }) {
	//[DEBUG] console.log('getPageableUsersOfCompanyWorkerSaga payload =', payload);
	const { companyObject /*, pageableParameters*/ } = payload;
	try {
		yield put(toggleLoaderAction(true));
		const pageableUsers = yield call(getPageableUsersOfCompanyAPI, payload);
		//[DEBUG] console.log('getPageableUsersOfCompanyWorkerSaga pageableUsers =', pageableUsers);

		yield put(setPageableUsersOfCompanyAction({ companyObject, pageableUsers }));
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success getPageableUsersOfCompanyWorkerSaga');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error getPageableUsersOfCompanyWorkerSaga');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on get users of company',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

// user saga worker

export function* getPageableUsersWorkerSaga({ payload }) {
	//[DEBUG] console.log('getPageableUsersWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		const users = yield call(getPageableUsersAPI, payload);
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('getPageableUsersWorkerSaga users =', users);
		yield put(setPageableUsersAction(users));
		//[DEBUG] console.log('Success getPageableUsersWorkerSaga');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error getPageableUsersWorkerSaga');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on get pageable users',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* registerUserWorkerSaga({ payload }) {
	//[DEBUG] console.log('registerUserWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(registerUserAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success registerUserWorkerSaga');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error registerUserWorkerSaga');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on register user',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* updateUserWorkerSaga({ payload }) {
	//[DEBUG] console.log('updateUserWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(updateUserAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success updateUserAS');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error updateUserAS');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on get user data',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* deleteUserWorkerSaga({ payload }) {
	//[DEBUG] console.log('deleteUserWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(deleteUserAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success deleteUserWorkerSaga');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error deleteUserWorkerSaga');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on delete user',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

// company saga

export function* getCompanyByIdSaga() {
	yield takeLatest(USER_GET_COMPANY_BY_ID, getCompanyByIdWorkerSaga);
}

export function* getPageableCompaniesSaga() {
	yield takeLatest(USER_GET_PAGEABLE_COMPANIES, getPageableCompaniesWorkerSaga);
}

export function* registerCompanySaga() {
	yield takeLatest(USER_REGISTER_COMPANY, registerCompanyWorkerSaga);
}

export function* updateCompanySaga() {
	yield takeLatest(USER_UPDATE_COMPANY, updateCompanyWorkerSaga);
}

export function* deleteCompanySaga() {
	yield takeLatest(USER_DELETE_COMPANY, deleteCompanyWorkerSaga);
}

export function* getPageableUsersOfCompanySaga() {
	yield takeEvery(
		USER_GET_PAGEABLE_USERS_OF_COMPANY,
		getPageableUsersOfCompanyWorkerSaga
	);
}

// user saga

export function* getPageableUsersSaga() {
	yield takeLatest(USER_GET_PAGEABLE_USERS_MY_COMPANY, getPageableUsersWorkerSaga);
}

export function* registerUserSaga() {
	yield takeLatest(USER_REGISTER_USER, registerUserWorkerSaga);
}

export function* updateUserSaga() {
	yield takeLatest(USER_UPDATE_USER, updateUserWorkerSaga);
}

export function* deleteUserSaga() {
	yield takeLatest(USER_DELETE_USER_WITH_ID, deleteUserWorkerSaga);
}

import {
	USER_GET_COMPANY_BY_ID,
	USER_GET_PAGEABLE_COMPANIES,
	USER_SET_PAGEABLE_COMPANIES,
	USER_REGISTER_COMPANY,
	USER_UPDATE_COMPANY,
	USER_DELETE_COMPANY,
} from '../constants';

// ########## ########## ########## ##########
// saga actions

export const getCompanyByIdAction = payload => {
	return {
		type: USER_GET_COMPANY_BY_ID,
		payload,
	};
};

export const getPageableCompaniesAction = payload => {
	//[DEBUG] console.log('getPageableCompaniesAction payload =', payload);
	return {
		type: USER_GET_PAGEABLE_COMPANIES,
		payload,
	};
};

export const registerCompanyAction = payload => {
	//[DEBUG] console.log('registerCompanyAction payload =', payload);
	return {
		type: USER_REGISTER_COMPANY,
		payload,
	};
};

export const updateCompanyAction = payload => {
	//[DEBUG] console.log('updateCompanyAction payload =', payload);
	return {
		type: USER_UPDATE_COMPANY,
		payload,
	};
};

export const deleteCompanyAction = payload => {
	//[DEBUG] console.log('deleteCompanyAction payload =', payload);
	return {
		type: USER_DELETE_COMPANY,
		payload,
	};
};

// ########## ########## ########## ##########
// redux actions

export const setPageableCompaniesAction = pageableCompanies => {
	//[DEBUG] console.log('setPageableCompaniesAction pageableCompanies = ', pageableCompanies);
	return {
		type: USER_SET_PAGEABLE_COMPANIES,
		pageableCompanies,
	};
};

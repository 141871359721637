import { takeLatest, call, put } from 'redux-saga/effects';

import { getPageableFeaturesAPI } from '../../utils/api/feature';
import { toggleLoaderAction, setGeneralErrorMessageAction } from '../general/action';
import { FEATURES_GET_PAGEABLE_FEATURES } from './constants';
import { setAllFeaturesAction } from './action';

export function* getPageableFeaturesWorkerSaga({ payload }) {
	yield put(toggleLoaderAction(true));
	console.log('getPageableFeaturesWorkerSaga start');
	try {
		const features = yield call(getPageableFeaturesAPI, payload);
		yield put(setAllFeaturesAction(features));
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on get pageable features',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* getPageableFeaturesSaga() {
	yield takeLatest(FEATURES_GET_PAGEABLE_FEATURES, getPageableFeaturesWorkerSaga);
}

import request from '../request';
import {
	APIKEY_URL_SHOW_APIKEY_WITH_ROLE_func,
	APIKEY_URL_GET_PAGEABLE_APIKEYS_QUERY_func,
	APIKEY_URL_GENERATE_APIKEY_WITH_ROLE_func,
	APIKEY_URL_DELETE_APIKEY_WITH_ROLE_func,
} from '../constants/url-paths';
import { authToken } from '../session-helpers';

export const showApikeyInPageableGetAPI = apikeyObject => {
	//[DEBUG] console.log('showApikeyInPageableGetAPI start, apikeyObject = ', apikeyObject);
	const { apikeyRole } = apikeyObject;

	return request(APIKEY_URL_SHOW_APIKEY_WITH_ROLE_func({ apikeyRole }), {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authToken()}`,
		},
	}).then(response => Promise.resolve(response));
};

export const getPageableApikeysAPI = payload => {
	//[DEBUG] console.log('getPageableApikeysAPI payload = ', payload);

	const { pageableParameters, filterParameters } = payload;

	return request(
		APIKEY_URL_GET_PAGEABLE_APIKEYS_QUERY_func({ pageableParameters, filterParameters }),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

export const registerApikeyAPI = payload => {
	//[DEBUG] console.log('registerApikeyAPI payload =', payload);
	const { apikeyObject } = payload;
	const { apikeyRole } = apikeyObject;

	return request(APIKEY_URL_GENERATE_APIKEY_WITH_ROLE_func({ apikeyRole: apikeyRole }), {
		// method: 'POST',
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authToken()}`,
		},
	}).then(response => Promise.resolve(response));
};

export const deleteApikeyAPI = payload => {
	//[DEBUG] console.log('deleteApikeyAPI payload =', payload);
	const { apikeyObject } = payload;
	const { apikeyRole } = apikeyObject;

	return request(APIKEY_URL_DELETE_APIKEY_WITH_ROLE_func({ apikeyRole }), {
		method: 'DELETE',
		headers: {
			Authorization: `Bearer ${authToken()}`,
		},
	}).then(response => Promise.resolve(response));
};

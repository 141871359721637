import { makeStyles } from '@material-ui/styles';

const createStyles = makeStyles({
	root: {
		margin: 0,
		padding: '24px',
	},
	closeButton: {
		position: 'absolute',
		right: '25px',
		top: '10px',
		color: '#808fa3',
		'&:hover': {
			backgroundColor: 'inherit',
		},
	},
	headerWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: '1px solid #E3EDF1',
	},
	titleWrapper: {
		paddingLeft: 26,
	},
	title: {
		color: '#4C677E',
		fontSize: 20,
	},
	subtitle: {
		color: '#9BA5B7',
		fontSize: 11,
	},
	dialogActionsWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		height: 70,
		backgroundColor: '#F4F5F7',
		paddingLeft: 50,
		paddingRight: 35,
		margin: 0,
		borderTop: '1px solid #E3EDF1',
	},
	dialogActionsRoot: {
		margin: 0,
		padding: 0,
	},
	firstButton: {
		height: 30,
		minWidth: '100px',
		backgroundColor: '#0086C8',
		padding: '0 10px',
		color: '#FFF',
		marginRight: 10,
		fontSize: 11,
		borderRadius: 3,
		textTransform: 'capitalize',
	},
	secondButton: {
		backgroundColor: '#FFF',
		padding: 0,
		height: 32, // 2px more than 1st because of borders
		fontSize: 11,
		color: '#EC5C57',
		borderRadius: 3,
		border: '1px solid #E3EDF1',
		textTransform: 'capitalize',
		'&:hover': {
			backgroundColor: '#E3EDF1',
		},
	},
	iconMenuButton: {
		padding: 0,
		backgroundColor: '#FFF',
		height: 32,
		fontSize: 16,
		color: '#0086C8',
		border: '1px solid #E3EDF1',
		borderLeft: 'none',
		borderRadius: 3,
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
	},
	borderRadiusChange: {
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
	},
	cancelButton: {
		backgroundColor: 'transparent',
		width: 40,
		height: 30,
		color: '#0086C8',
		fontSize: 11,
		textTransform: 'capitalize',
		'&:hover': {
			backgroundColor: 'transparent',
			opacity: 0.7,
		},
	},
	menuButton: {
		color: '#0086C8',
		fontSize: 11,
		textTransform: 'capitalize',
		height: 30,
		padding: 0,
		paddingLeft: 24,
		paddingRight: 24,
		backgroundColor: '#FFF',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.08)',
			opacity: 1,
		},
	},
	buttonDisabled: {
		color: 'rgba(0,0,0,0.5) !important',
	},
});

export default createStyles;

// ACTION CONSTANTS

export const USER_SET_FORM_ERROR = 'USER_SET_FORM_ERROR';

// -> COMPANY

export const USER_GET_PAGEABLE_COMPANIES = 'USER_GET_PAGEABLE_COMPANIES';
export const USER_SET_PAGEABLE_COMPANIES = 'USER_SET_PAGEABLE_COMPANIES';

export const USER_REGISTER_COMPANY = 'USER_REGISTER_COMPANY';
export const USER_REGISTER_COMPANY_SUCCESS = 'USER_REGISTER_COMPANY_SUCCESS';
export const USER_REGISTER_COMPANY_ERROR = 'USER_REGISTER_COMPANY_ERROR';

export const USER_UPDATE_COMPANY = 'USER_UPDATE_COMPANY';
export const USER_UPDATE_COMPANY_SUCCESS = 'USER_UPDATE_COMPANY_SUCCESS';
export const USER_UPDATE_COMPANY_ERROR = 'USER_UPDATE_COMPANY_ERROR';

export const USER_GET_COMPANY_BY_ID = 'USER_GET_COMPANY_BY_ID';
export const USER_GET_COMPANY_BY_ID_SUCCESS = 'USER_GET_COMPANY_BY_ID_SUCCESS';
export const USER_GET_COMPANY_BY_ID_ERROR = 'USER_GET_COMPANY_BY_ID_ERROR';

export const USER_DELETE_COMPANY = 'USER_DELETE_COMPANY';
export const USER_DELETE_COMPANY_SUCCESS = 'USER_DELETE_COMPANY_SUCCESS';
export const USER_DELETE_COMPANY_ERROR = 'USER_DELETE_COMPANY_ERROR';

export const USER_GET_PAGEABLE_USERS_OF_COMPANY = 'USER_GET_PAGEABLE_USERS_OF_COMPANY';
export const USER_SET_PAGEABLE_USERS_OF_COMPANY = 'USER_SET_PAGEABLE_USERS_OF_COMPANY';

// -> USER

export const USER_GET_PAGEABLE_USERS_MY_COMPANY = 'USER_GET_PAGEABLE_USERS_MY_COMPANY';
export const USER_SET_PAGEABLE_USERS_MY_COMPANY = 'USER_SET_PAGEABLE_USERS_MY_COMPANY';

export const USER_REGISTER_USER = 'USER_REGISTER_USER';
export const USER_UPDATE_USER = 'USER_UPDATE_USER';
export const USER_DELETE_USER_WITH_ID = 'USER_DELETE_USER_WITH_ID';

import { authToken } from '../../session-helpers';
import request from '../../request';
import {
	USER_URL_GET_PAGEABLE_COMPANIES_QUERY_func,
	USER_URL_REGISTER_COMPANY,
	USER_URL_UPDATE_COMPANY,
	USER_URL_GET_COMPANY_BY_ID_func,
	USER_URL_DELETE_COMPANY_WITH_ID_func,
	USER_URL_GET_PAGEABLE_USERS_OF_COMPANY_ID_QUERY_func,
} from '../../constants/url-paths';
import { USER_COMPANY_ADMIN } from '../../constants/user';
// import { AUTHENTICATION_SERVICE, REDIRECTION_SERVICE } from '../../constants/services';

export const getCompanyByIdAPI = id => {
	//[DEBUG] console.log('getCompanyByIdAPI param = ', id);

	return request(USER_URL_GET_COMPANY_BY_ID_func({ companyId: id }), {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authToken()}`,
		},
	}).then(response => Promise.resolve(response));
};

export const getPageableCompaniesAPI = payload => {
	//[DEBUG] console.log('getPageableCompaniesAPI payload = ', payload);
	const { pageableParameters, filterParameters } = payload;

	return request(
		USER_URL_GET_PAGEABLE_COMPANIES_QUERY_func({ pageableParameters, filterParameters }),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

export const registerCompanyAPI = payload => {
	//[DEBUG] console.log('registerCompanyAPI payload = ', payload);
	const { createCompanyObject } = payload;

	const {
		companyName,
		expirationDate,
		companyServices,
		email,
		status,
	} = createCompanyObject;

	const company = {
		name: companyName,
		status: status,
		features: companyServices,
		expirationDate: expirationDate.toISOString(),
	};

	const companyAdmin = {
		email,
		role: USER_COMPANY_ADMIN,
		company,
	};

	const body = JSON.stringify({
		company,
		companyAdmin,
	});

	return request(USER_URL_REGISTER_COMPANY, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${authToken()}`,
			'Content-Type': 'application/json',
		},
		body,
	}).then(response => Promise.resolve(response));
};

export const updateCompanyAPI = payload => {
	//[DEBUG] console.log('updateCompanyAPI payload = ', payload);
	const { companyObject } = payload;

	const {
		companyId,
		deleted,
		companyName,
		address,
		companyServices,
		creationDate,
		expirationDate,
	} = companyObject;

	const company = {
		id: companyId,
		deleted,
		name: companyName,
		address,
		features: companyServices,
		creationDate,
		expirationDate,
	};

	const body = JSON.stringify({ ...company });
	return request(USER_URL_UPDATE_COMPANY, {
		method: 'PUT',
		headers: {
			Authorization: `Bearer ${authToken()}`,
			'Content-Type': 'application/json',
		},
		body,
	}).then(response => Promise.resolve(response));
};

export const deleteCompanyAPI = payload => {
	//[DEBUG] console.log('deleteCompanyAPI payload =', payload);
	const { companyObject } = payload;
	const { companyId } = companyObject;

	return request(USER_URL_DELETE_COMPANY_WITH_ID_func({ companyId }), {
		method: 'DELETE',
		headers: {
			Authorization: `Bearer ${authToken()}`,
		},
	}).then(response => Promise.resolve(response));
};

export const getPageableUsersOfCompanyAPI = payload => {
	//[DEBUG] console.log('getPageableUsersOfCompanyAPI payload =', payload);
	const { companyObject, pageableParameters } = payload;
	const { companyId } = companyObject;

	return request(
		USER_URL_GET_PAGEABLE_USERS_OF_COMPANY_ID_QUERY_func({
			companyId,
			pageableParameters,
		}),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

export const requestCompaniesForFilterAPI = payload => {
	//[DEBUG] console.log('requestCompaniesForFilterAPI payload =', payload);
	const { companyObject } = payload;
	const pageableParameters = {
		page: 0,
		size: 5,
		sortBy: 'name',
		order: 'ASC',
		// sortBy: 'createdTs',
		// order: 'DESC',
		deleted: 0,
	};
	const filterParameters = { nameParam: companyObject.name };

	return request(
		USER_URL_GET_PAGEABLE_COMPANIES_QUERY_func({ pageableParameters, filterParameters }),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

import accountEN from './en/account.json';
import apikeyEN from './en/apikey.json';
import bootstrapEN from './en/bootstrap.json';
import campaignEN from './en/campaign.json';
import dashboardEN from './en/dashboard.json';
import errorEN from './en/error.json';
import formEN from './en/form.json';
import generalEN from './en/general.json';
import keyEN from './en/key.json';
import privacykeyEN from './en/privacykey.json';
import legalEN from './en/legal.json';
import sidebarEN from './en/sidebar.json';
import serviceEN from './en/service.json';
import tagEN from './en/tag.json';
import outcomeEN from './en/outcome.json';
import userEN from './en/user.json';

const resources = {
	en: {
		account: accountEN,
		apikey: apikeyEN,
		bootstrap: bootstrapEN,
		campaign: campaignEN,
		dashboard: dashboardEN,
		error: errorEN,
		form: formEN,
		general: generalEN,
		key: keyEN,
		privacykey: privacykeyEN,
		tag: tagEN,
		outcome: outcomeEN,
		legal: legalEN,
		sidebar: sidebarEN,
		service: serviceEN,
		user: userEN,
	},
};

export default resources;

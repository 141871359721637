// this file can be modified by non developers
// it should be exported/used only to/in "utils/constants/*"

// comment switch
/*
export * from './config.local'; // TODO remove on final version
/*/
// eslint-disable-next-line
/* eslint-disable-next-line */
export const SERVER = window.globalConfig
	? window.globalConfig.BACKEND_SERVER
	: /* eslint-disable-next-line no-undef */
	  process.env.REACT_APP_SERVER_URL;

// eslint-disable-next-line
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL || '/v2';

// eslint-disable-next-line
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

// eslint-disable-next-line
export const FRONTEND_APPLICATION_VERSION = process.env.REACT_APP_APPLICATION_VERSION;

// eslint-disable-next-line
export const NO_ACCOUNT_PAGE_URL = process.env.REACT_APP_NO_ACCOUNT_PAGE;

export const SUPPORT_EMAIL = 'nfcservices.support@nxp.com';
export const NUM_MINIMAL_PHONE_LENGTH = 5;
//*/

/**
 * When other service that UI backend fails, this will be shown as an error
 */
export const SERVER_ERROR = 'SERVER_ERROR';

/**
 * When UI Backend stops working for any reason and is not reachable,
 * this will be shown as an error
 */
export const SERVER_UNAVAILABLE = 'SERVER_UNAVAILABLE';

/**
 * If the end point doesn't exist, this will be shown as an error
 */
export const URL_NOT_FOUND = 'URL_NOT_FOUND';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
	//[DEBUG] console.log('parseJSON response', response);

	if (response.status === 204 || response.status === 205) {
		return Promise.resolve('Success');
	}
	return response.text().then(function(text) {
		if (text) {
			try {
				const json = JSON.parse(text);
				return json;
			} catch (error) {
				return text;
			}
		} else {
			return {};
		}
	});
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response  A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
async function checkStatus(response) {
	//[DEBUG] console.log('checkStatus response', response);

	if (response.status >= 200 && response.status < 300) {
		return response;
	}

	//[THINK] should we use this
	if (response.status >= 500) {
		return Promise.reject(SERVER_ERROR);
	}

	let message = await response.json();
	message.prevStatus = response.status;
	if (message && message.error && message.error === 'Not Found') {
		//[THINK] should we use this
		message = URL_NOT_FOUND;
	} else if (message.status) {
		message = message.status;
	}

	return Promise.reject(message);
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
	//[DEBUG] console.log('request url', url);
	//[DEBUG] console.log('request options', options);
	return fetch(url, options)
		.then(checkStatus)
		.then(parseJSON)
		.catch(error => {
			if (String(error).startsWith('TypeError')) {
				//[DEBUG] console.log('error =', error);
				return Promise.reject(SERVER_UNAVAILABLE);
			}
			return Promise.reject(error);
		});
}

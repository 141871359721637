import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import globalStore from './reducers';
import sagas from './sagas';
import App from './App';

import './globalStyles.css';
import './i18n';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
	globalStore(history),
	applyMiddleware(routerMiddleware(history), sagaMiddleware)
);
sagaMiddleware.run(sagas);

ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<App history={history} />
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root')
);

import { call, put, takeEvery } from 'redux-saga/effects';

import { requestDashboardAPI } from '../../utils/api/dashboard';
import {
	AUTHENTICATION_SERVICE,
	REDIRECTION_SERVICE,
} from '../../utils/constants/services';
import { USER_LOG_VIEWER, USER_NXP_ADMIN } from '../../utils/constants/user';
import { toggleLoaderAction, setGeneralErrorMessageAction } from '../general/action';
import { setDashboardAction } from './action';
import { DASHBOARD_REQUEST_DASHBOARDS } from './constants';

export function* requestDashboardsWorkerSaga({ payload }) {
	//[DEBUG] console.log('requestDashboardsWorkerSaga payload =', payload);
	const { myRole, myFeatures } = payload;

	try {
		yield put(toggleLoaderAction(true));

		if ([USER_NXP_ADMIN, USER_LOG_VIEWER].includes(myRole)) {
			const dashboardNxpAdmin = yield call(requestDashboardAPI, {
				tabType: 'ADMIN_DASHBOARD',
			});
			yield put(
				setDashboardAction({
					dashboardType: 'dashboardNxpAdmin',
					dashboardUrl: dashboardNxpAdmin,
				})
			);
		}

		if (myFeatures.includes(AUTHENTICATION_SERVICE)) {
			const dashboardAuthentication = yield call(requestDashboardAPI, {
				tabType: 'AUTHENTICATOR_DASHBOARD',
			});
			yield put(
				setDashboardAction({
					dashboardType: 'dashboardAuthentication',
					dashboardUrl: dashboardAuthentication,
				})
			);
		}

		if (myFeatures.includes(REDIRECTION_SERVICE)) {
			const dashboardRedirection = yield call(requestDashboardAPI, {
				tabType: 'REDIRECTOR_DASHBOARD',
			});
			yield put(
				setDashboardAction({
					dashboardType: 'dashboardRedirection',
					dashboardUrl: dashboardRedirection,
				})
			);
		}
		yield put(toggleLoaderAction(false));

		//[DEBUG] console.log('Success requestDashboardsWorkerSaga');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error requestDashboardsWorkerSaga');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on request dashboard',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* requestDashboardsSaga() {
	yield takeEvery(DASHBOARD_REQUEST_DASHBOARDS, requestDashboardsWorkerSaga);
}

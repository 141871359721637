import { DASHBOARD_SET_DASHBOARD } from './constants';

export const initialState = {
	dashboardNxpAdmin: '',
	dashboardRedirection: '',
	dashboardAuthentication: '',
};

export default (state = initialState, action) => {
	switch (action.type) {
		case DASHBOARD_SET_DASHBOARD: {
			switch (action.dashboardType) {
				case 'dashboardNxpAdmin':
				case 'dashboardRedirection':
				case 'dashboardAuthentication':
					return {
						...state,
						[action.dashboardType]: action.dashboardUrl,
					};
				default:
					return state;
			}
		}
		default:
			return state;
	}
};

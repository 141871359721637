import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
// import i18n from 'i18next';
import loadable from '@loadable/component';

import { myCurrentRoleView } from '../../utils/functions/redux-supporters';
import { tokenTermsAccepted } from '../../utils/session-helpers';

import {
	NO_ACCOUNT_PAGE_URL,
	ACCOUNT_LOGOUT_ERROR_URL,
} from '../../utils/constants/constants';

import LoginPage from '../pages/common-special/login-page';
import LogoutPage from '../pages/common-special/logout-page';

import iconUsers from '../../images/icon-users.svg';
import iconCompanies from '../../images/company.png';
import iconPeople from '../../images/users.svg';
import iconDashboard from '../../images/dashboard.svg';
import iconApikeys from '../../images/apikeys.svg';
import iconKeys from '../../images/keys.svg';
import iconLegal from '../../images/legal.svg';
import iconTags from '../../images/tags.svg';
import iconCampaigns from '../../images/campaigns.svg';
import iconPrivacykeys from '../../images/incognito.svg';
import {
	USER_COMPANY_ADMIN,
	USER_KDS_OPERATOR,
	USER_COMPANY_VIEWER,
	USER_LOG_VIEWER,
	USER_NXP_ADMIN,
} from '../../utils/constants/user';

const MainLayout = loadable(() => import('../layout/layout-main'));

const AccountDoesNotExists = loadable(() =>
	import('../pages/common-special/account-does-not-exist')
);
const ErrorAutomaticLogoutFailed = loadable(() =>
	import('../pages/common-special/error-automatic-logout-failed')
);
const InitializeApp = loadable(() =>
	import('../pages/common-special/bootstrap-initialize-app')
);
const InitializeAppSuccess = loadable(() =>
	import('../pages/common-special/bootstrap-initialize-app-success')
);
// const NotFound = loadable(() => import('../pages/common-special/not-found'));
// const ErrorServerNotAvailable = loadable(() =>
// 	import('../pages/common-special/error-server-not-available')
// );

const DashboardPage = loadable(() => import('../pages/common/dashboard'));
const AccountPage = loadable(() => import('../pages/common/account'));
const KeysPage = loadable(() => import('../pages/common/keys'));

const CompaniesPage = loadable(() => import('../pages/global-admin/companies'));
const PrivacykeysPage = loadable(() => import('../pages/global-admin/privacykeys'));

const LegalPage = loadable(() => import('../pages/company-admin/legal/index'));
const UsersPage = loadable(() => import('../pages/company-admin/users'));
const ApikeysPage = loadable(() => import('../pages/company-admin/apikeys'));
const TagsPage = loadable(() => import('../pages/company-admin/tags'));
const CampaignsPage = loadable(() => import('../pages/company-admin/campaigns'));

const DASHBOARD = [
	{
		id: 'dashboard',
		url: '/dashboard',
		name: 'Dashboard', // i18n.t('menu_item__dashboard'),
		image: iconDashboard,
		component: DashboardPage,
		routes: [],
	},
];

export const LEGAL = [
	{
		id: 'legaldocuments',
		url: '/legal-documents',
		name: 'Legal documents', // i18n.t('menu_item__legaldocuments'),
		image: iconLegal,
		component: LegalPage,
		routes: [],
	},
];

const ACCOUNT_SETTINGS = [
	{
		id: 'myaccount',
		url: '/account',
		name: 'My Account', // i18n.t('menu_item__myaccount'),
		image: iconUsers,
		component: AccountPage,
		routes: [],
	},
];

const USER_NXP_ADMIN_VIEW = [
	{
		id: 'companies',
		url: '/companies',
		name: 'Companies', // i18n.t('menu_item__companies'),
		image: iconCompanies,
		component: CompaniesPage,
		routes: [],
	},
	{
		id: 'keys',
		url: '/keys',
		name: 'Keys', // i18n.t('menu_item__keys'),
		image: iconKeys,
		component: KeysPage,
		routes: [],
	},
	{
		id: 'privacykeys',
		url: '/privacykeys',
		name: 'Privacy keys', // i18n.t('menu_item__privacykeys'),
		image: iconPrivacykeys,
		component: PrivacykeysPage,
		routes: [],
	},
];

const USER_KDS_OPERATOR_VIEW = [
	{
		id: 'keys',
		url: '/keys',
		name: 'Keys', // i18n.t('menu_item__keys'),
		image: iconKeys,
		component: KeysPage,
		routes: [],
	},
];

const COMPANY_ADMIN_VIEW = [
	{
		id: 'users',
		url: '/users',
		name: 'Users', // i18n.t('menu_item__users'),
		image: iconPeople,
		component: UsersPage,
		routes: [],
	},
	{
		id: 'keys',
		url: '/keys',
		name: 'Keys', // i18n.t('menu_item__keys'),
		image: iconKeys,
		component: KeysPage,
		routes: [],
	},
	{
		id: 'apikeys',
		url: '/apikeys',
		name: 'API Keys', // i18n.t('menu_item__apikeys'),
		image: iconApikeys,
		component: ApikeysPage,
		routes: [],
	},
];

const REDIRECTION_VIEW = [
	{
		id: 'tags',
		url: '/tags',
		name: 'Tags', // i18n.t('menu_item__tags'),
		image: iconTags,
		component: TagsPage,
		routes: [],
	},
	{
		id: 'campaigns',
		url: '/campaigns',
		name: 'Campaigns', // i18n.t('menu_item__campaigns'),
		image: iconCampaigns,
		component: CampaignsPage,
		routes: [],
	},
];

const COMPANY_VIEWER_VIEW = [
	{
		id: 'users',
		url: '/users',
		name: 'Users', // i18n.t('menu_item__users'),
		image: iconPeople,
		component: UsersPage,
		routes: [],
	},
	{
		id: 'apikeys',
		url: '/apikeys',
		name: 'API Keys', // i18n.t('menu_item__apikeys'),
		image: iconApikeys,
		component: ApikeysPage,
		routes: [],
	},
];

const GlobalAdminMenu = {
	DASHBOARD,
	ACCOUNT_SETTINGS,
	USER_NXP_ADMIN_VIEW,
};

const KdsOperatorMenu = {
	ACCOUNT_SETTINGS,
	LEGAL,
	USER_KDS_OPERATOR_VIEW,
};

const CompanyAdminMenu = {
	DASHBOARD,
	ACCOUNT_SETTINGS,
	LEGAL,
	COMPANY_ADMIN_VIEW,
};

const CompanyViewerMenu = {
	ACCOUNT_SETTINGS,
	LEGAL,
	COMPANY_VIEWER_VIEW,
};

const RedirectionMenu = {
	REDIRECTION_VIEW,
};

const LegalMenu = {
	LEGAL,
};

const createSelectedMenu = ({ currentRoleView, hasRedirectionService }) => {
	let selectedMenu = [];
	switch (currentRoleView) {
		case USER_NXP_ADMIN:
			selectedMenu = GlobalAdminMenu;
			break;
		case USER_KDS_OPERATOR:
			selectedMenu = KdsOperatorMenu;
			break;
		case USER_LOG_VIEWER:
			// for now same as GA
			selectedMenu = GlobalAdminMenu;
			break;
		case USER_COMPANY_ADMIN:
			selectedMenu = CompanyAdminMenu;
			selectedMenu = {
				...selectedMenu,
				...(hasRedirectionService ? RedirectionMenu : {}),
			};
			break;
		case USER_COMPANY_VIEWER:
			// for now same as CS
			selectedMenu = CompanyViewerMenu;
			selectedMenu = {
				...selectedMenu,
				...(hasRedirectionService ? RedirectionMenu : {}),
			};
			break;
		default:
			return [];
	}

	if (!tokenTermsAccepted()) {
		selectedMenu = LegalMenu;
	}

	return selectedMenu;
};

export const routesPerRole = ({ currentRoleView, hasRedirectionService }) => {
	const selectedMenu = createSelectedMenu({ currentRoleView, hasRedirectionService });
	const routes = [];
	for (const menuName in selectedMenu) {
		for (const menu of selectedMenu[menuName]) {
			const index = routes.findIndex(existingRoute => existingRoute.id === menu.id);
			if (index > -1) {
				const routesToAdd = [];
				for (const menuRoute of menu.routes) {
					const routesIndex = routes[index].routes.findIndex(
						existingRoute => existingRoute.id === menuRoute.id
					);
					if (routesIndex < 0) {
						routesToAdd.push(menuRoute);
					}
				}
				routes[index].routes = [...routes[index].routes, ...routesToAdd];
			} else {
				routes.push(menu);
			}
		}
	}
	return routes;
};

export const RouteWithSubroute = route => {
	return (
		<Route
			data-route={route.url}
			key={route.id}
			path={`${route.url}`}
			render={props => <route.component {...props} routes={route.routes} />}
		/>
	);
};

export const ProtectedRoutesComponent = ({
	location,
	breadcrumbs,
	currentRoleView,
	hasRedirectionService,
}) => {
	//[DEBUG] console.log('ProtectedRoutes location =', location);
	//[DEBUG] console.log('ProtectedRoutes currentRoleView =', currentRoleView);
	const routes = routesPerRole({ currentRoleView, hasRedirectionService });
	//[DEBUG] console.log('routes =', routes);
	//[DEBUG] console.log('tokenTermsAccepted =', tokenTermsAccepted());
	return (
		<MainLayout breadcrumbs={breadcrumbs}>
			<Switch key={location.key} location={location}>
				{routes.map(route => RouteWithSubroute(route))}
				<Route key="logout" exact path="/logout" component={LogoutPage} />
				{!!tokenTermsAccepted() && <Route key="default" to="*" component={AccountPage} />}
				{!tokenTermsAccepted() && <Route key="default" to="*" component={LegalPage} />}
			</Switch>
		</MainLayout>
	);
};

export const mapStateToProps = ({ accountReducer }) => ({
	currentRoleView: myCurrentRoleView(accountReducer),
	hasRedirectionService: Object.keys(accountReducer.myUserData.company.features).reduce(
		(current, index) =>
			current ||
			accountReducer.myUserData.company.features[index].feature === 'REDIRECTION_SERVICE',
		false
	),
});

export const ProtectedRoutes = connect(mapStateToProps)(ProtectedRoutesComponent);

export const UnprotectedRoutes = location => {
	//[DEBUG] console.log('UnprotectedRoutes location =', location);
	return (
		<Switch key={location.key} location={location}>
			<Route
				key="account_does_not_exists"
				path={NO_ACCOUNT_PAGE_URL}
				render={() => <AccountDoesNotExists />}
			/>
			<Route
				key="logout_error"
				path={ACCOUNT_LOGOUT_ERROR_URL}
				render={() => <ErrorAutomaticLogoutFailed />}
			/>
			<Route key="login" to="*" render={() => <LoginPage location={location} />} />
		</Switch>
	);
};

export const InitialRoutes = location => {
	//[DEBUG] console.log('InitialRoutes location =', location);
	return (
		<Switch key={location.key} location={location}>
			<Route
				key="initialize_app_success"
				path="/initialize-app-success"
				render={() => <InitializeAppSuccess />}
			/>
			<Route
				key="initialize_app"
				path="/initialize-app"
				render={() => <InitializeApp />}
			/>
			<Redirect from="*" to="/initialize-app" />
		</Switch>
	);
};

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
// import { CheckCircle, ErrorOutline } from '@material-ui/icons';

import NXPDialog from '../../standalone/dialog';

import styles from './styles.css';

export class NXPPopup extends Component {
	state = {
		buttonState: null,
	};
	componentDidUpdate(prevProps) {
		const { actionButtonStatus, open } = this.props;

		if (prevProps.open !== this.props.open && !open) {
			setTimeout(() => {
				this.setState({ buttonState: actionButtonStatus });
			}, 200);
		} else if (this.state.buttonState !== actionButtonStatus) {
			this.setState({ buttonState: actionButtonStatus });
		}
	}

	render() {
		const {
			open,
			title,
			subtitle,
			classes,
			customClass,
			description,
			t,
			actionButtonLabel,
			onCancel,
			action,
			colorRed,
			hideCloseElements,
			buttonStyle,
			hint,
		} = this.props;
		// const { buttonState } = this.state;
		// const buttonClass = '';
		const isLoading = false;
		// const actionBtnIcon = '';
		/*
		if (buttonState === DIALOG_ACTION_BUTTON_STATUS.SUCCESS) {
			buttonClass = classes.successBtn;
			actionBtnIcon = <CheckCircle className={classes.actionBtnIcon} />;
		} else if (buttonState === DIALOG_ACTION_BUTTON_STATUS.FAIL) {
			buttonClass = classes.failBtn;
			actionBtnIcon = <ErrorOutline className={classes.actionBtnIcon} />;
		} else if (buttonState === DIALOG_ACTION_BUTTON_STATUS.LOADING) {
			buttonClass = classes.loadingBtn;
			actionBtnIcon = <div className={classes.loader} />;
			isLoading = true;
		}
		*/
		const titleEl = (
			<div className={classes.titleWrapper}>
				<Typography className={classes.title}>{title}</Typography>
				<Typography className={classes.subtitle}>{subtitle}</Typography>
			</div>
		);
		return (
			<NXPDialog
				contentClass={classes.customContent}
				customClass={`${classes.dialogWrapper} ${customClass ? customClass : ''}`}
				open={open}
				title={titleEl}
				customHeaderClass={classes.dialogHeader}
				onClose={() => onCancel()}
				hideCloseElements
			>
				<div className={classes.content}>
					<Typography
						className={classes.description}
						style={{ whiteSpace: 'break-spaces' }}
					>
						{description}
					</Typography>
					<Typography className={classes.confirmationText}>
						{hint ? hint : t('are_you_sure_you_want_to_proceed#q')}
					</Typography>
				</div>
				<div className={classes.actionRow}>
					<Button
						onClick={() => action()}
						className={`${classes.confirmButton} ${
							colorRed ? classes.backgroundRed : ''
						}`}
						classes={{
							disabled: classes.btnDisabled,
						}}
						style={{ ...buttonStyle }}
					>
						{/* {actionBtnIcon} */}
						{actionButtonLabel}
					</Button>
					{!hideCloseElements && (
						<Button
							disableRipple
							disabled={isLoading}
							onClick={() => onCancel()}
							className={classes.cancelButton}
						>
							{t('button__cancel')}
						</Button>
					)}
				</div>
			</NXPDialog>
		);
	}
}

NXPPopup.propTypes = {
	open: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	customClass: PropTypes.object,
	// description: PropTypes.string.isRequired,
	actionButtonLabel: PropTypes.any.isRequired,
	action: PropTypes.func,
	// onConfirm: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
};

export const mapStateToProps = ({ main }) => ({
	// actionButtonStatus: main.dialogActionStatus,
});

export const mapDispatchToProps = dispatch => {
	return {
		// clearButtonState: () => dispatch(actionButtonStatusClearAction()),
	};
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation(['form']),
	withStyles(styles)
)(NXPPopup);

// ACTION CONSTANTS

export const APIKEY_SHOW_APIKEY_IN_PAGEABLE_GET = 'APIKEY_SHOW_APIKEY_IN_PAGEABLE_GET';
export const APIKEY_SHOW_APIKEY_IN_PAGEABLE_SET = 'APIKEY_SHOW_APIKEY_IN_PAGEABLE_SET';

export const APIKEY_GET_PAGEABLE_APIKEYS = 'APIKEY_GET_PAGEABLE_APIKEYS';
export const APIKEY_SET_PAGEABLE_APIKEYS = 'APIKEY_SET_PAGEABLE_APIKEYS';

export const APIKEY_REGISTER_APIKEY = 'APIKEY_REGISTER_APIKEY';
export const APIKEY_DELETE_APIKEY = 'APIKEY_DELETE_APIKEY';

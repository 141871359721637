import { MINIMAL_AUTH_TOKEN_LENGTH } from '../constants/constants';

// -> token

export const authToken = () => {
	const token = sessionStorage.getItem('token');
	//[DEBUG] console.log('authToken -> token = ', token);
	return token || '';
};

//[IMPROVE] add time check - token from backend should have valid time
export const authenticatedUser = () => {
	const authenticated = !!authToken() && authToken().length >= MINIMAL_AUTH_TOKEN_LENGTH;
	//[DEBUG] console.log('authenticatedUser -> authenticated = ', authenticated);
	return authenticated;
};

const parseJwt = token => {
	try {
		return JSON.parse(atob(token.split('.')[1]));
	} catch (e) {
		return null;
	}
};

export const userDataFromToken = token => {
	const companyAndUserId = parseJwt(token).sub.split('.');
	return {
		myCompanyId: parseInt(companyAndUserId[0], 16),
		myUserId: parseInt(companyAndUserId[1], 16),
	};
};

export const tokenTermsAccepted = () => {
	const token = sessionStorage.getItem('token');
	//[DEBUG] console.log('parseJwt(token) = ', parseJwt(token));
	//[DEBUG] console.log('parseJwt(token).cfg = ', parseJwt(token).cfg);

	const tokenData = parseJwt(token);
	return tokenData && tokenData.cfg === 1;
};

// -> status

export const bootstrapStatus = () => {
	const bootstrapstatus = sessionStorage.getItem('bootstrapstatus');
	//[DEBUG] console.log('bootstrapStatus -> bootstrapstatus = ', bootstrapstatus);
	return bootstrapstatus || '';
};

import { takeLatest, put, call } from 'redux-saga/effects';

import { authToken, userDataFromToken } from '../../utils/session-helpers';
import { getUserByIdAPI } from '../../utils/api/user/user';
import { toggleLoaderAction, setGeneralErrorMessageAction } from '../general/action';
import { setMyUserDataAction } from './action';
import { ACCOUNT_REQUEST_MY_USER_DATA } from './constants';

export function* requestMyUserDataWorkerSaga() {
	//[DEBUG] console.log('requestMyUserDataWorkerSaga payload = _');
	try {
		const userData = userDataFromToken(authToken());
		// yield put(setMyUserAndCompanyId(userData));

		yield put(toggleLoaderAction(true));
		const myUserData = yield call(getUserByIdAPI, userData.myUserId);
		//[DEBUG] console.log('requestMyUserDataWorkerSaga myUserData =', myUserData);
		yield put(setMyUserDataAction(myUserData));
		yield put(toggleLoaderAction(false));

		//[DEBUG] console.log('Success requestMyUserDataWorkerSaga');
	} catch (error) {
		//[DEBUG] console.log('Error requestMyUserDataWorkerSaga');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on get user data',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* requestMyUserDataSaga() {
	yield takeLatest(ACCOUNT_REQUEST_MY_USER_DATA, requestMyUserDataWorkerSaga);
}

import {
	FILE_GET_TERMS_HTML,
	FILE_SET_TERMS_HTML,
	FILE_GET_PRIVACY_HTML,
	FILE_SET_PRIVACY_HTML,
	FILE_GET_ATTRIBUTIONS_JSON,
	FILE_SET_ATTRIBUTIONS_OBJECT,
} from './constants';

// ########## ########## ########## ##########
// saga actions

export const getTermsHtmlAction = () => {
	//[DEBUG] console.log('getTermsHtmlAction start');
	return {
		type: FILE_GET_TERMS_HTML,
	};
};

export const getPrivacyHtmlAction = () => {
	//[DEBUG] console.log('getPrivacyHtmlAction start');
	return {
		type: FILE_GET_PRIVACY_HTML,
	};
};

export const getAttributionsJsonAction = () => {
	//[DEBUG] console.log('getAttributionsJsonAction start');
	return {
		type: FILE_GET_ATTRIBUTIONS_JSON,
	};
};

// ########## ########## ########## ##########
// redux actions

export const setTermsHtmlAction = termsHtml => {
	//[DEBUG] console.log('setTermsHtmlAction termsHtml =', termsHtml);
	return {
		type: FILE_SET_TERMS_HTML,
		termsHtml,
	};
};

export const setPrivacyHtmlAction = privacyHtml => {
	//[DEBUG] console.log('setPrivacyHtmlAction privacyHtml =', privacyHtml);
	return {
		type: FILE_SET_PRIVACY_HTML,
		privacyHtml,
	};
};

export const setAttributionsObjectAction = attributionsObject => {
	//[DEBUG] console.log('setAttributionsObjectAction attributionsObject =', attributionsObject);
	return {
		type: FILE_SET_ATTRIBUTIONS_OBJECT,
		attributionsObject,
	};
};

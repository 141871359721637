import { takeLeading, call, put } from 'redux-saga/effects';

import {
	getTermsHtmlAPI,
	getPrivacyHtmlAPI,
	getAttributionsJsonAPI,
} from '../../utils/api/file';
import { toggleLoaderAction } from '../general/action';
import {
	setAttributionsObjectAction,
	setPrivacyHtmlAction,
	setTermsHtmlAction,
} from './action';
import {
	FILE_GET_TERMS_HTML,
	FILE_GET_PRIVACY_HTML,
	FILE_GET_ATTRIBUTIONS_JSON,
} from './constants';

export function* getTermsHtmlWorkerSaga() {
	//[DEBUG] console.log('getTermsHtmlWorkerSaga start');
	yield put(toggleLoaderAction(true));
	try {
		const response = yield call(getTermsHtmlAPI);
		yield put(setTermsHtmlAction(atob(response.payload)));
	} catch (error) {
		// eslint-disable-next-line
		console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);
	}
	yield put(toggleLoaderAction(false));
}

export function* getPrivacyHtmlWorkerSaga() {
	//[DEBUG] console.log('getTermsHtmlWorkerSaga start');
	yield put(toggleLoaderAction(true));
	try {
		const response = yield call(getPrivacyHtmlAPI);
		yield put(setPrivacyHtmlAction(atob(response.payload)));
	} catch (error) {
		// eslint-disable-next-line
		console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);
	}
	yield put(toggleLoaderAction(false));
}

export function* getAttributionsJsonWorkerSaga() {
	//[DEBUG] console.log('getTermsHtmlWorkerSaga start');
	yield put(toggleLoaderAction(true));
	try {
		const response = yield call(getAttributionsJsonAPI);
		yield put(setAttributionsObjectAction(JSON.parse(atob(response.payload))));
	} catch (error) {
		// eslint-disable-next-line
		console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);
	}
	yield put(toggleLoaderAction(false));
}

export function* getTermsHtmlSaga() {
	yield takeLeading(FILE_GET_TERMS_HTML, getTermsHtmlWorkerSaga);
}

export function* getPrivacyHtmlSaga() {
	yield takeLeading(FILE_GET_PRIVACY_HTML, getPrivacyHtmlWorkerSaga);
}

export function* getAttributionsJsonSaga() {
	yield takeLeading(FILE_GET_ATTRIBUTIONS_JSON, getAttributionsJsonWorkerSaga);
}

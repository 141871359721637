import { takeLatest, call, put } from 'redux-saga/effects';

import {
	getPageablePrivacykeysAPI,
	registerPrivacykeyAPI,
	updatePrivacykeyAPI,
	deletePrivacykeyAPI,
} from '../../utils/api/privacykey';
import { toggleLoaderAction, setGeneralErrorMessageAction } from '../general/action';
import { setPageablePrivacykeysAction } from './action';
import {
	PRIVACYKEY_GET_PAGEABLE_PRIVACYKEYS,
	PRIVACYKEY_REGISTER_PRIVACYKEY,
	PRIVACYKEY_UPDATE_PRIVACYKEY,
	PRIVACYKEY_DELETE_PRIVACYKEY,
} from './constants';

export function* getPageablePrivacykeysWorkerSaga({ payload }) {
	//[DEBUG] console.log('getPageablePrivacykeysWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		const privacykeys = yield call(getPageablePrivacykeysAPI, payload);
		yield put(toggleLoaderAction(false));
		yield put(setPageablePrivacykeysAction(privacykeys));
	} catch (error) {
		yield put(toggleLoaderAction(false));

		const generalErrorObject = {
			title: 'API error on get pageable privacy keys',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* registerPrivacykeyWorkerSaga({ payload }) {
	//[DEBUG] console.log('registerPrivacykeyWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(registerPrivacykeyAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
	} catch (error) {
		yield put(toggleLoaderAction(false));

		const generalErrorObject = {
			title: 'API error on add privacy key',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* updatePrivacykeyWorkerSaga({ payload }) {
	//[DEBUG] console.log('updatePrivacykeyWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(updatePrivacykeyAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
	} catch (error) {
		yield put(toggleLoaderAction(false));

		const generalErrorObject = {
			title: 'API error on update privacy key',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* deletePrivacykeyWorkerSaga({ payload }) {
	//[DEBUG] console.log('deletePrivacykeyWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(deletePrivacykeyAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
	} catch (error) {
		yield put(toggleLoaderAction(false));

		const generalErrorObject = {
			title: 'API error on delete key',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* getPageablePrivacykeysSaga() {
	yield takeLatest(PRIVACYKEY_GET_PAGEABLE_PRIVACYKEYS, getPageablePrivacykeysWorkerSaga);
}

export function* registerPrivacykeySaga() {
	yield takeLatest(PRIVACYKEY_REGISTER_PRIVACYKEY, registerPrivacykeyWorkerSaga);
}

export function* updatePrivacykeySaga() {
	yield takeLatest(PRIVACYKEY_UPDATE_PRIVACYKEY, updatePrivacykeyWorkerSaga);
}

export function* deletePrivacykeySaga() {
	yield takeLatest(PRIVACYKEY_DELETE_PRIVACYKEY, deletePrivacykeyWorkerSaga);
}

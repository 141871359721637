import request from '../request';
import { authToken } from '../session-helpers';
import {
	KEY_URL_GET_KEY_BY_ID_func,
	KEY_URL_GET_PAGEABLE_KEYS_QUERY_func,
	KEY_URL_REGISTER_KEY,
	KEY_URL_UPDATE_KEY,
	KEY_URL_DELETE_KEY_WITH_ID_func,
	KEY_URL_ASSIGN_KEY_ID_TO_COMPANY_ID_func,
	KEY_URL_UNASSIGN_KEY_ID_FROM_COMPANY_ID_func,
	KEY_URL_GET_PAGEABLE_COMPANIES_OF_KEY_ID_QUERY_func,
	KEY_URL_GET_AVAILABLE_COMPANIES_OF_KEY_ID_QUERY_func,
	KEY_URL_UPLOAD_DIVERSIFY_KEY_func,
	KEY_URL_UPLOAD_PASSWORD_GENERATOR_func,
} from '../constants/url-paths';

// -> from saga

export const getKeyByIdAPI = id => {
	//[DEBUG] console.log('getKeyByIdAPI start, param = ', id);

	return request(KEY_URL_GET_KEY_BY_ID_func({ keyId: id }), {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authToken()}`,
		},
	}).then(response => Promise.resolve(response));
};

export const getPageableKeysAPI = payload => {
	//[DEBUG] console.log('getPageableKeysAPI payload = ', payload);
	const { pageableParameters, filterParameters } = payload;

	return request(
		KEY_URL_GET_PAGEABLE_KEYS_QUERY_func({ pageableParameters, filterParameters }),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

export const registerKeyAPI = payload => {
	//[DEBUG] console.log('registerKeyAPI payload = ', payload);
	const { keyObject } = payload;
	const {
		keyId,
		keyName,
		keyType,
		keySubType,
		keyValue,
		keySharingScope,
		keyMetadata,
	} = {
		keyMetadata: {},
		...keyObject,
	};

	const visibilityTransform = {
		SHARED_SHARING_SCOPE: 'DEFAULT',
		COMPANY_SPECIFIC_SHARING_SCOPE: 'PRIVATE',
	};

	const body = JSON.stringify({
		id: keyId,
		deleted: '',
		name: keyName,
		keyType,
		keySubType: keySubType || 'EMPTY!!!!',
		visibility: visibilityTransform[keySharingScope],
		data: keyValue,
		metaProductType: keyMetadata.metaProductType,
		metaOrderFrom: keyMetadata.metaOrderFrom,
		metaKeyNumber: keyMetadata.metaKeyNumber,
		meta12nc: keyMetadata.meta12nc,
	});

	//[DEBUG] console.log('KEY_URL_REGISTER_KEY, body =', body);
	return request(KEY_URL_REGISTER_KEY, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${authToken()}`,
			'Content-Type': 'application/json',
		},
		body,
	}).then(response => Promise.resolve(response));
};

export const updateKeyAPI = payload => {
	//[DEBUG] console.log('updateKeyAPI payload = ', payload);
	const { keyObject } = payload;
	const { keyId, keyName, keyValue, keyMetadata } = {
		keyMetadata: {},
		...keyObject,
	};

	const body = JSON.stringify({
		id: keyId,
		name: keyName,
		data: keyValue,
		metaProductType: keyMetadata.metaProductType,
		metaOrderFrom: keyMetadata.metaOrderFrom,
		metaKeyNumber: keyMetadata.metaKeyNumber,
		meta12nc: keyMetadata.meta12nc,
	});

	//[DEBUG] console.log('KEY_URL_UPDATE_KEY, body =', body);
	return request(KEY_URL_UPDATE_KEY, {
		method: 'PUT',
		headers: {
			Authorization: `Bearer ${authToken()}`,
			'Content-Type': 'application/json',
		},
		body,
	}).then(response => Promise.resolve(response));
};

export const deleteKeyAPI = payload => {
	//[DEBUG] console.log('deleteKeyAPI payload = ', payload);
	const { keyObject } = payload;
	const { keyId } = keyObject;

	//[DEBUG] console.log('KEY_URL_DELETE_KEY_WITH_ID_func, keyId =', keyId);
	return request(KEY_URL_DELETE_KEY_WITH_ID_func({ keyId: keyId }), {
		method: 'DELETE',
		headers: {
			Authorization: `Bearer ${authToken()}`,
		},
	}).then(response => Promise.resolve(response));
};

export const operationKeyCompanyAssignAPI = payload => {
	//[DEBUG] console.log('operationKeyCompanyAssignAPI payload = ', payload);
	const { keyObject, companyObject } = payload;
	const { keyId } = keyObject;
	const { companyId } = companyObject;

	return request(KEY_URL_ASSIGN_KEY_ID_TO_COMPANY_ID_func({ keyId, companyId }), {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authToken()}`,
		},
	}).then(response => Promise.resolve(response));
};

export const operationKeyCompanyUnassignAPI = payload => {
	//[DEBUG] console.log('operationKeyCompanyAssignAPI payload = ', payload);
	const { keyObject, companyObject } = payload;
	const { keyId } = keyObject;
	const { companyId } = companyObject;

	return request(KEY_URL_UNASSIGN_KEY_ID_FROM_COMPANY_ID_func({ keyId, companyId }), {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authToken()}`,
		},
	}).then(response => Promise.resolve(response));
};

export const getPageableCompaniesOfKeyAPI = payload => {
	//[DEBUG] console.log('getPageableCompaniesOfKeyAPI payload = ', payload);
	const { keyObject, pageableParameters } = payload;
	const keyId = keyObject.keyId || keyObject.id;

	return request(
		KEY_URL_GET_PAGEABLE_COMPANIES_OF_KEY_ID_QUERY_func({ keyId, pageableParameters }),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

// -> direct

export const requestAvailableCompaniesOfKeyAPI = payload => {
	//[DEBUG] console.log('requestAvailableCompaniesOfKeyAPI payload = ', payload);
	const { keyObject, companyObject } = payload;

	const pageableParameters = {
		size: 5,
	};
	const filterParameters = { nameParam: companyObject.name };

	return request(
		KEY_URL_GET_AVAILABLE_COMPANIES_OF_KEY_ID_QUERY_func({
			keyId: keyObject.keyId,
			pageableParameters,
			filterParameters,
		}),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

export const requestAvailableKeysAPI = payload => {
	//[DEBUG] console.log('requestAvailableKeysAPI payload = ', payload);
	const { keyObject } = payload;
	const pageableParameters = {
		page: 0,
		size: 5,
		// sortBy: 'name',
		// order: 'ASC',
		sortBy: 'createdTs',
		order: 'DESC',
		deleted: 0,
	};

	const filterParameters = {
		nameParam: keyObject.keyName,
		keyTypeParam: keyObject.keyType,
		keySubTypeParam: keyObject.keySubtype,
		keyVisibilityParam: keyObject.keyVisibilityParam,
	};
	return request(
		KEY_URL_GET_PAGEABLE_KEYS_QUERY_func({ pageableParameters, filterParameters }),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

export const uploadKeyDiversifyDataAPI = payload => {
	//[DEBUG] console.log('uploadKeyDiversifyDataAPI payload = ', payload);
	const { keyObject, keyDiversifyFile, pgpPublicKey, pgpPublicKeyFile } = payload;
	const { keyId } = keyObject;

	const formData = new FormData();
	formData.append('file', keyDiversifyFile);
	pgpPublicKey && formData.append('pgpPublicKey', pgpPublicKey);
	pgpPublicKeyFile && formData.append('pgpPublicKeyFile', pgpPublicKeyFile);
	return request(KEY_URL_UPLOAD_DIVERSIFY_KEY_func({ keyId }), {
		method: 'POST',
		body: formData,
		headers: {
			Authorization: `Bearer ${authToken()}`,
			// 'Content-Type': 'multipart/form-data',
		},
	}).then(response => Promise.resolve(response));
};

export const uploadPasswordGeneratorDataAPI = payload => {
	//[DEBUG] console.log('uploadKeyDiversifyDataAPI payload = ', payload);
	const { keyObject, passwordGeneratorFile, pgpPublicKey, pgpPublicKeyFile } = payload;
	const { keyId } = keyObject;

	const formData = new FormData();
	formData.append('file', passwordGeneratorFile);
	pgpPublicKey && formData.append('pgpPublicKey', pgpPublicKey);
	pgpPublicKeyFile && formData.append('pgpPublicKeyFile', pgpPublicKeyFile);
	return request(KEY_URL_UPLOAD_PASSWORD_GENERATOR_func({ keyId }), {
		method: 'POST',
		body: formData,
		headers: {
			Authorization: `Bearer ${authToken()}`,
			// 'Content-Type': 'multipart/form-data',
		},
	}).then(response => Promise.resolve(response));
};

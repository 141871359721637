import React, { Component } from 'react';
import { compose } from 'redux';
// import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { Link } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import styles from './styles.css';

// TODO maybe implement some day - but do not replace error dialogs
// replacement should be done case by case
// use notifications for small silent errors
/*
import success from '../../../images/success.svg';
import error from '../../../images/error.svg';
import info from '../../../images/info.svg';
import { ReactComponent as CloseLogo } from '../../../images/close.svg';
*/

export class NXPNotifications extends Component {
	static propTypes = {
		classes: PropTypes.shape({
			wrapper: PropTypes.string,
			className: PropTypes.string,
		}),
		notifications: PropTypes.array,
	};

	render() {
		const { notifications } = this.props;
		// const { notifications, classes } = this.props;
		if (notifications.length <= 0) {
			return null;
		}
		return (
			<h1>Notifications TODO</h1>
			/*
			<div>
				<h1>Notification TODO</h1>
				<div className={classes.wrapper}>
					{console.log('notifications', notifications),
					notifications.map((n, index) => (
						<div
							onMouseEnter={() => console.log('TODO', n.id)}
							onMouseLeave={() => console.log('TODO', n.id)}
							onClick={() => console.log('TODO', n.id)}
							key={`${n.id}_${index}`}
							className={classes.notification}
							data-notification
						>
							{console.log('TODO', n.id)}
							{(n.type === 'success' || n.type === 'error') && (
								<img
									className={classes.icon}
									alt={n.type}
									src={n.type === 'success' ? success : error}
								/>
							)}
							{n.type === 'info' && <img className={classes.icon} alt={n.type} src={info} />}
							<div className={classNames(classes.message)}>
								<span>{n.message}</span>
								{n.linkLabel && (
									<Link to={n.linkUrl} className={classes.link}>
										{n.linkLabel}
									</Link>
								)}
							</div>
							<div styles={{ display: 'flex', alignItems: 'center' }}>
								<CloseLogo className={classes.close} />
							</div>
						</div>
					))}
				</div>
			</div>
			*/
		);
	}
}

export const mapStateToProps = ({ main }) => {
	return { notifications: [] };
};

export default compose(connect(mapStateToProps), withStyles(styles))(NXPNotifications);

const styles = {
	wrapper: {
		position: 'absolute',
		top: '15px',
		right: '0px',
		left: '0px',
		margin: '0 auto',
		width: '415px',
		cursor: 'pointer',
		zIndex: 10000000,
		'@media (max-width: 600px)': {
			bottom: '15px',
			top: 'auto',
			right: '20px',
			left: '20px',
			width: 'calc(100% - 40px)',
		},
	},
	notification: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		backgroundColor: '#fff',
		alignItems: 'center',
		minHeight: '37px',
		height: '100%',
		marginBottom: '5px',
		boxShadow: '2px 2px 5px 0px #acacac',
		borderRadius: '3px',
	},
	message: {
		fontSize: '14px',
		lineHeight: 1.57,
		marginLeft: '15px',
		marginRight: '15px',
		display: 'flex',
		flexDirection: 'row',
		fontFamily: 'Lato',
		alignItems: 'center',
		width: '100%',
	},
	icon: {
		height: '16px',
		width: '16px',
		minWidth: '16px',
		minHeight: '16px',
		marginLeft: '15px',
	},
	closeIcon: {
		fontSize: '12px',
		marginRight: '15px',
		marginLeft: 'auto',
		maxWidth: '10px!important',
		cursor: 'pointer',
	},
	link: {
		fontSize: '14px',
		textDecoretion: 'none',
		color: '#0081c5',
		marginLeft: '5px',
	},
	close: {
		cursor: 'pointer',
		stroke: '#4c677e',
		marginLeft: 'auto',
		marginRight: 0,
		width: 30,
		height: 30,
	},
};

export default styles;

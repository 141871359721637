import { KEY_SET_PAGEABLE_KEYS, KEY_SET_PAGEABLE_COMPANIES_OF_KEY } from './constants';

export const initialState = {
	pageableKeys: {
		content: [],
	},
	editKeyDialog: {},
	pageableCompaniesByKey: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case KEY_SET_PAGEABLE_KEYS: {
			return {
				...state,
				pageableKeys: action.pageableKeys,
			};
		}
		case KEY_SET_PAGEABLE_COMPANIES_OF_KEY: {
			return {
				...state,
				pageableCompaniesByKey: {
					...state.pageableCompaniesByKey,
					[action.keyId]: action.pageableCompanies,
				},
			};
		}
		default:
			return state;
	}
};

import { takeLatest, call, put, takeEvery, select } from 'redux-saga/effects';

import {
	getPageableCampaignsAPI,
	registerCampaignAPI,
	updateCampaignAPI,
	deleteCampaignAPI,
	// campaignPublishAPI,
	// getPageableActivitiesOfCampaignAPI,
	getDefinitionsAPI,
	updateDefinitionsAPI,
	getOutcomesAPI,
	updateOutcomesAPI,
	getDefaultOutcomesAPI,
	updateDefaultOutcomesAPI,
} from '../../utils/api/campaign';
import {
	// transformOutcomeSubtypeToInitialized,
	transformOutcomeSubtypeToDefinitionsList,
	transformOutcomeSubtypeToAction,
	transformOutcomeSubtypeToWebhook,
} from '../../utils/functions/constants-transformers';
import { toggleLoaderAction, setGeneralErrorMessageAction } from '../general/action';
import {
	setPageableCampaignsAction,
	// setPageableActivitiesOfCampaignAction,
	setDefinitionsAction,
	setOutcomesAction,
	setDefaultOutcomesAction,
} from './action';
import {
	CAMPAIGN_GET_PAGEABLE_CAMPAIGNS,
	CAMPAIGN_REGISTER_CAMPAIGN,
	CAMPAIGN_UPDATE_CAMPAIGN,
	CAMPAIGN_DELETE_CAMPAIGN,
	// -> definitions
	CAMPAIGN_GET_DEFINITIONS,
	CAMPAIGN_REGISTER_DEFINITIONS,
	CAMPAIGN_UPDATE_DEFINITIONS,
	CAMPAIGN_DELETE_DEFINITIONS,
	// -> outcomes
	CAMPAIGN_GET_OUTCOMES,
	CAMPAIGN_UPDATE_OUTCOMES,
	CAMPAIGN_DELETE_OUTCOMES,
	CAMPAIGN_REGISTER_OUTCOMES,
	// -> default outcomes
	CAMPAIGN_GET_DEFAULT_OUTCOMES,
	CAMPAIGN_UPDATE_DEFAULT_OUTCOMES,
} from './constants';
import {
	VALID,
	INVALID,
	NOT_ORIGINAL,
	TAG_TAMPER,
} from '../../utils/constants/tags/index.const';
import { REDIRECTION, APPLICATION, BOTH } from '../../utils/constants/campaigns';

export const stateGetDefinitions = (state, campaignId, campaignType) => {
	return state.campaignReducer.campaignDefinitionsByCampaign[campaignId][campaignType];
};

export const stateGetOutcomes = (state, campaignId, campaignType) => {
	return state.campaignReducer.campaignOutcomesByCampaign[campaignId][campaignType];
};

export function* getPageableCampaignsWorkerSaga({ payload }) {
	//[DEBUG] console.log('getPageableCampaignsWorkerSaga payload = ', payload);

	try {
		yield put(toggleLoaderAction(true));
		const campaigns = yield call(getPageableCampaignsAPI, payload);
		yield put(setPageableCampaignsAction(campaigns));
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on get pageable campaigns',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* registerCampaignWorkerSaga({ payload }) {
	//[DEBUG] console.log('registerCampaignWorkerSaga start');

	try {
		yield put(toggleLoaderAction(true));
		yield call(registerCampaignAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on add campaign',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* updateCampaignWorkerSaga({ payload }) {
	//[DEBUG] console.log('updateCampaignWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(updateCampaignAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success updateCampaignWorkerSaga');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error updateCampaignWorkerSaga');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on update campaign',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* deleteCampaignWorkerSaga({ payload }) {
	//[DEBUG] console.log('deleteCampaignWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(deleteCampaignAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success deleteCampaignWorkerSaga');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error deleteCampaignWorkerSaga');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on delete campaign',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* getDefinitionsWorkerSaga({ payload }) {
	//[DEBUG] console.log('getDefinitionsWorkerSaga payload = ', payload);

	try {
		yield put(toggleLoaderAction(true));
		const definitionsObject = yield call(getDefinitionsAPI, payload);
		const { campaignObject } = payload;
		yield put(setDefinitionsAction({ campaignObject, definitionsObject }));
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on get definitions',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* registerDefinitionsWorkerSaga({ payload }) {
	//[DEBUG] console.log('registerDefinitionsWorkerSaga payload = ', payload);

	try {
		yield put(toggleLoaderAction(true));
		// register is done via update
		const definitionsObject = yield call(updateDefinitionsAPI, payload);
		const { campaignObject } = payload;
		yield put(setDefinitionsAction({ campaignObject, definitionsObject }));
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on add definitions',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* updateDefinitionsWorkerSaga({ payload }) {
	//[DEBUG] console.log('updateDefinitionsWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		const definitionsObject = yield call(updateDefinitionsAPI, payload);
		const { campaignObject } = payload;
		yield put(setDefinitionsAction({ campaignObject, definitionsObject }));
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on update definitions',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* deleteDefinitionAndOutcomeWorkerSaga({ payload }) {
	//[DEBUG] console.log('deleteDefinitionAndOutcomeWorkerSaga payload =', payload);

	const { campaignObject, outcomeSubtype } = payload;
	const { campaignId /*, campaignType*/ } = campaignObject;
	const outcomeDefinitionsOld = yield select(
		stateGetDefinitions,
		campaignId,
		BOTH
		// campaignType
	);
	// const outcomeActionsOld = yield select(stateGetOutcomes, campaignId, campaignType);
	// const outcomeActionsRed = yield select(stateGetOutcomes, campaignId, REDIRECTION);
	// const outcomeActionsApp = yield select(stateGetOutcomes, campaignId, APPLICATION);

	// const initialized = transformOutcomeSubtypeToInitialized({ outcomeSubtype });
	const initializedData = Object.fromEntries(
		transformOutcomeSubtypeToDefinitionsList({ outcomeSubtype }).map(x => [x, null])
	);
	const action = transformOutcomeSubtypeToAction({ outcomeSubtype });
	const webhook = transformOutcomeSubtypeToWebhook({ outcomeSubtype });

	//[DEBUG] console.log('initialized, action, webhook', initialized, action, webhook);

	let definitionsObjectNew;
	let outcomeObjectNew = null;
	let outcomeObjectRed = yield select(stateGetOutcomes, campaignId, REDIRECTION);
	let outcomeObjectApp = yield select(stateGetOutcomes, campaignId, APPLICATION);

	switch (outcomeSubtype) {
		case VALID:
			definitionsObjectNew = {
				initializedValid: false,
				initializedInvalid: false,
				checksumCheck: null,
				usedCountCheck: null,
				nfcCounterCheck: null,
				tamperCheck: null,
				tamperNowClosedCheck: null,
				uidEccCheck: null,
				sunCheck: null,
			};
			outcomeObjectNew = {
				valid: null,
				validWebHooks: null,
				tagTamper: null,
				tagTamperWebHooks: null,
				tagTamperNowClosed: null,
				tagTamperNowClosedWebHooks: null,
				invalid: null,
				invalidWebHooks: null,
				notOriginal: null,
				notOriginalWebHooks: null,
				campaignInactive: null,
				campaignInactiveWebHooks: null,
				counterExpired: null,
				counterExpiredWebHooks: null,
				counterInvalid: null,
				counterInvalidWebHooks: null,
			};
			break;

		case NOT_ORIGINAL:
			definitionsObjectNew = {
				...outcomeDefinitionsOld,
				...initializedData,
				tamperCheck: null,
				tamperNowClosedCheck: null,
			};
			outcomeObjectRed = {
				...outcomeObjectRed,
				notOriginal: null,
				notOriginalWebHooks: null,
				tagTamper: null,
				tagTamperWebHooks: null,
				tagTamperNowClosed: null,
				tagTamperNowClosedWebHooks: null,
			};
			outcomeObjectApp = {
				...outcomeObjectApp,
				notOriginal: null,
				notOriginalWebHooks: null,
				tagTamper: null,
				tagTamperWebHooks: null,
				tagTamperNowClosed: null,
				tagTamperNowClosedWebHooks: null,
			};
			break;
		case TAG_TAMPER:
			definitionsObjectNew = {
				...outcomeDefinitionsOld,
				...initializedData,
				tamperCheck: null,
				tamperNowClosedCheck: null,
			};
			outcomeObjectRed = {
				...outcomeObjectRed,
				tagTamper: null,
				tagTamperWebHooks: null,
				tagTamperNowClosed: null,
				tagTamperNowClosedWebHooks: null,
			};
			outcomeObjectApp = {
				...outcomeObjectApp,
				tagTamper: null,
				tagTamperWebHooks: null,
				tagTamperNowClosed: null,
				tagTamperNowClosedWebHooks: null,
			};
			break;

		case INVALID:
			definitionsObjectNew = {
				...outcomeDefinitionsOld,
				initializedInvalid: false,
				usedCountCheck: null,
				nfcCounterCheck: null,
			};
			outcomeObjectRed = {
				...outcomeObjectRed,
				invalid: null,
				invalidWebHooks: null,
				campaignInactive: null,
				campaignInactiveWebHooks: null,
				counterExpired: null,
				counterExpiredWebHooks: null,
				counterInvalid: null,
				counterInvalidWebHooks: null,
			};
			outcomeObjectApp = {
				...outcomeObjectApp,
				invalid: null,
				invalidWebHooks: null,
				campaignInactive: null,
				campaignInactiveWebHooks: null,
				counterExpired: null,
				counterExpiredWebHooks: null,
				counterInvalid: null,
				counterInvalidWebHooks: null,
			};
			break;

		default:
			definitionsObjectNew = {
				...outcomeDefinitionsOld,
				// [initialized]: false,
				...initializedData,
			};
			outcomeObjectRed = {
				...outcomeObjectRed,
				[action]: null,
				[webhook]: null,
			};
			outcomeObjectApp = {
				...outcomeObjectApp,
				[action]: null,
				[webhook]: null,
			};
			break;
	}

	try {
		yield put(toggleLoaderAction(true));
		// delete is done via update
		const campaignObjectRed = { ...campaignObject, campaignType: REDIRECTION };
		const campaignObjectApp = { ...campaignObject, campaignType: APPLICATION };
		/*
		const definitionsObjectRedUpdated = yield call(updateDefinitionsAPI, {
			campaignObject: campaignObjectRed,
			definitionsObject: definitionsObjectNew,
		});
		*/
		const outcomesObjectRedUpdated = yield call(updateOutcomesAPI, {
			campaignObject: campaignObjectRed,
			outcomesObject: outcomeObjectNew || outcomeObjectRed,
		});
		/*
		yield put(
			setDefinitionsAction({
				campaignObject: campaignObjectRed,
				definitionsObject: definitionsObjectRedUpdated,
			})
		);
		*/
		yield put(
			setOutcomesAction({
				campaignObject: campaignObjectRed,
				outcomesObject: outcomesObjectRedUpdated,
			})
		);
		/*
		const definitionsObjectAppUpdated = yield call(updateDefinitionsAPI, {
			campaignObject: campaignObjectApp,
			definitionsObject: definitionsObjectNew,
		});
		*/
		const outcomesObjectAppUpdated = yield call(updateOutcomesAPI, {
			campaignObject: campaignObjectApp,
			outcomesObject: outcomeObjectNew || outcomeObjectApp,
		});
		/*
		yield put(
			setDefinitionsAction({
				campaignObject: campaignObjectApp,
				definitionsObject: definitionsObjectAppUpdated,
			})
		);
		*/
		yield put(
			setOutcomesAction({
				campaignObject: campaignObjectApp,
				outcomesObject: outcomesObjectAppUpdated,
			})
		);

		// BOTH
		const campaignObjectBoth = { ...campaignObject, campaignType: BOTH };
		const definitionsObjectRedUpdated = yield call(updateDefinitionsAPI, {
			campaignObject: campaignObjectBoth,
			definitionsObject: definitionsObjectNew,
		});
		yield put(
			setDefinitionsAction({
				campaignObject: campaignObjectBoth,
				definitionsObject: definitionsObjectRedUpdated,
			})
		);
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on delete definitions',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* getOutcomesWorkerSaga({ payload }) {
	//[DEBUG] console.log('getOutcomesWorkerSaga payload = ', payload);

	try {
		yield put(toggleLoaderAction(true));
		const outcomesObject = yield call(getOutcomesAPI, payload);
		const { campaignObject } = payload;
		yield put(setOutcomesAction({ campaignObject, outcomesObject }));
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on get outcome',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* registerOutcomesWorkerSaga({ payload }) {
	//[DEBUG] console.log('registerOutcomesWorkerSaga payload = ', payload);

	try {
		yield put(toggleLoaderAction(true));
		// register is done via update
		const outcomesObject = yield call(updateOutcomesAPI, payload);
		const { campaignObject } = payload;
		yield put(setOutcomesAction({ campaignObject, outcomesObject }));
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on add outcome',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* updateOutcomesWorkerSaga({ payload }) {
	//[DEBUG] console.log('updateOutcomesWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		const outcomesObject = yield call(updateOutcomesAPI, payload);
		const { campaignObject } = payload;
		yield put(setOutcomesAction({ campaignObject, outcomesObject }));
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on update outcome',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* deleteOutcomeWorkerSaga({ payload }) {
	//[DEBUG] console.log('deleteOutcomeWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		// delete is done via update
		const outcomesObject = yield call(updateOutcomesAPI, payload);
		const { campaignObject } = payload;
		yield put(setOutcomesAction({ campaignObject, outcomesObject }));
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on delete outcome',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* getDefaultOutcomesWorkerSaga() {
	//[DEBUG] console.log('getDefaultOutcomesWorkerSaga payload = _');

	try {
		yield put(toggleLoaderAction(true));
		const defaultOutcomesObject = yield call(getDefaultOutcomesAPI, null);
		yield put(setDefaultOutcomesAction({ defaultOutcomesObject }));
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on get default outcomes',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* updateDefaultOutcomesWorkerSaga({ payload }) {
	//[DEBUG] console.log('updateDefaultOutcomesWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		const defaultOutcomesObject = yield call(updateDefaultOutcomesAPI, payload);
		yield put(setDefaultOutcomesAction({ defaultOutcomesObject }));
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on update default outcomes',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

// campaigns

export function* getPageableCampaignsSaga() {
	yield takeLatest(CAMPAIGN_GET_PAGEABLE_CAMPAIGNS, getPageableCampaignsWorkerSaga);
}

export function* registerCampaignSaga() {
	yield takeLatest(CAMPAIGN_REGISTER_CAMPAIGN, registerCampaignWorkerSaga);
}

export function* updateCampaignSaga() {
	yield takeLatest(CAMPAIGN_UPDATE_CAMPAIGN, updateCampaignWorkerSaga);
}

export function* deleteCampaignSaga() {
	yield takeLatest(CAMPAIGN_DELETE_CAMPAIGN, deleteCampaignWorkerSaga);
}

// definitions

export function* getDefinitionsSaga() {
	yield takeEvery(CAMPAIGN_GET_DEFINITIONS, getDefinitionsWorkerSaga);
}

export function* registerDefinitionsSaga() {
	yield takeEvery(CAMPAIGN_REGISTER_DEFINITIONS, registerDefinitionsWorkerSaga);
}

export function* updateDefinitionsSaga() {
	yield takeEvery(CAMPAIGN_UPDATE_DEFINITIONS, updateDefinitionsWorkerSaga);
}

export function* deleteDefinitionAndOutcomeSaga() {
	yield takeEvery(CAMPAIGN_DELETE_DEFINITIONS, deleteDefinitionAndOutcomeWorkerSaga);
}

// outcomes

export function* getOutcomesSaga() {
	yield takeEvery(CAMPAIGN_GET_OUTCOMES, getOutcomesWorkerSaga);
}

export function* registerOutcomesSaga() {
	yield takeEvery(CAMPAIGN_REGISTER_OUTCOMES, registerOutcomesWorkerSaga);
}

export function* updateOutcomesSaga() {
	yield takeEvery(CAMPAIGN_UPDATE_OUTCOMES, updateOutcomesWorkerSaga);
}

export function* deleteOutcomeSaga() {
	yield takeEvery(CAMPAIGN_DELETE_OUTCOMES, deleteOutcomeWorkerSaga);
}

// default outcome settings

export function* getDefaultOutcomesSaga() {
	yield takeEvery(CAMPAIGN_GET_DEFAULT_OUTCOMES, getDefaultOutcomesWorkerSaga);
}

export function* updateDefaultOutcomesSaga() {
	yield takeEvery(CAMPAIGN_UPDATE_DEFAULT_OUTCOMES, updateDefaultOutcomesWorkerSaga);
}

import { takeLatest, call, put, takeEvery } from 'redux-saga/effects';

import {
	getPageableTagsAPI,
	registerTagAPI,
	updateTagAPI,
	deleteTagAPI,
	getTagCountersAPI,
	updateTagCountersAPI,
	assignTagsToCampaignAPI,
	unassignTagsAPI,
	bulkTagStatusChangeAPI,
	deleteTagsAPI,
	updateTagOutcomesAPI,
	getTagOutcomesAPI,
	getTagsImportJobReportAPI,
} from '../../utils/api/tag';
import { toggleLoaderAction, setGeneralErrorMessageAction } from '../general/action';
import {
	setPageableTagsAction,
	setTagCountersAction,
	setTagOutcomesAction,
	setTagsImportJobReportAction,
} from './action';
import {
	TAG_GET_PAGEABLE_TAGS,
	TAG_REGISTER_TAG,
	TAG_UPDATE_TAG,
	TAG_DELETE_TAG,
	TAG_GET_TAG_COUNTERS,
	TAG_UPDATE_TAG_COUNTERS,
	// TAG_ASSIGN_TAG_TO_CAMPAIGN,
	TAG_ASSIGN_TAGS_TO_CAMPAIGN,
	TAG_UNASSIGN_TAGS,
	TAG_BULK_TAG_STATUS_CHANGE,
	TAG_DELETE_TAGS,
	TAG_REGISTER_TAG_OUTCOMES,
	TAG_GET_TAG_OUTCOMES,
	TAG_GET_TAGS_IMPORT_JOB_REPORT,
} from './constants';

export function* getPageableTagsWorkerSaga({ payload }) {
	//[DEBUG] console.log('getPageableTagsWorkerSaga payload =', payload);
	try {
		yield put(toggleLoaderAction(true));
		const tags = yield call(getPageableTagsAPI, payload);
		yield put(toggleLoaderAction(false));
		/*
		const { filterParameters } = payload;
		//[IMPROVE] on backend make API return this also (filter parameters)
		const { campaignIdParam } = filterParameters;
		yield put(setPageableTagsAction({ ...tags, campaignIdParam }));
		*/
		yield put(setPageableTagsAction(tags));
	} catch (error) {
		yield put(toggleLoaderAction(false));

		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);
		const generalErrorObject = {
			title: 'API error on get pageable tags',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* registerTagWorkerSaga({ payload }) {
	//[DEBUG] console.log('registerTagWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(registerTagAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
	} catch (error) {
		yield put(toggleLoaderAction(false));

		// eslint-disable-next-line
		console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on add tag',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* updateTagWorkerSaga({ payload }) {
	//[DEBUG] console.log('updateTagWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(updateTagAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
	} catch (error) {
		yield put(toggleLoaderAction(false));

		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on update tag',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* deleteTagWorkerSaga({ payload }) {
	//[DEBUG] console.log('deleteKeyWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(deleteTagAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
	} catch (error) {
		yield put(toggleLoaderAction(false));

		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on delete tag',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* getTagCountersWorkerSaga({ payload }) {
	//[DEBUG] console.log('getTagCountersWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		const tagCounters = yield call(getTagCountersAPI, payload);
		const { tagObject } = payload;
		yield put(setTagCountersAction({ tagObject, tagCounters }));
		yield put(toggleLoaderAction(false));
	} catch (error) {
		yield put(toggleLoaderAction(false));

		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on tag request counters',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* updateTagCountersWorkerSaga({ payload }) {
	//[DEBUG] console.log('updateTagCountersWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		const tagCounters = yield call(updateTagCountersAPI, payload);
		const { tagObject } = payload;
		yield put(setTagCountersAction({ tagObject, tagCounters }));
		yield put(toggleLoaderAction(false));
	} catch (error) {
		yield put(toggleLoaderAction(false));

		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on tag update counters',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* assignTagsToCampaignWorkerSaga({ payload }) {
	//[DEBUG] console.log('assignTagsToCampaignWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(assignTagsToCampaignAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
	} catch (error) {
		yield put(toggleLoaderAction(false));

		const generalErrorObject = {
			title: 'API error on tag assign to campaign',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* unassignTagsWorkerSaga({ payload }) {
	//[DEBUG] console.log('unassignTagsWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(unassignTagsAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
	} catch (error) {
		yield put(toggleLoaderAction(false));

		const generalErrorObject = {
			title: 'API error on tag assign to campaign',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* bulkTagStatusChangeWorkerSaga({ payload }) {
	//[DEBUG] console.log('bulkTagStatusChangeWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(bulkTagStatusChangeAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
	} catch (error) {
		yield put(toggleLoaderAction(false));

		const generalErrorObject = {
			title: 'API error on tag assign to campaign',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* deleteTagsWorkerSaga({ payload }) {
	//[DEBUG] console.log('deleteTagsWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(deleteTagsAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
	} catch (error) {
		yield put(toggleLoaderAction(false));

		const generalErrorObject = {
			title: 'API error on delete tags',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* getTagOutcomesWorkerSaga({ payload }) {
	//[DEBUG] console.log('getTagOutcomesWorkerSaga payload = ', payload);

	try {
		yield put(toggleLoaderAction(true));
		const outcomesObject = yield call(getTagOutcomesAPI, payload);
		const { tagObject } = payload;
		yield put(setTagOutcomesAction({ tagObject, outcomesObject }));
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on get outcome',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* registerTagOutcomesWorkerSaga({ payload }) {
	//[DEBUG] console.log('registerTagOutcomesWorkerSaga payload = ', payload);

	try {
		yield put(toggleLoaderAction(true));
		// register is done via update
		const outcomesObject = yield call(updateTagOutcomesAPI, payload);
		const { tagObject } = payload;
		yield put(setTagOutcomesAction({ tagObject, outcomesObject }));
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on add outcome',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* getTagsImportJobReportWorkerSaga({ payload }) {
	//[DEBUG] console.log('getTagsImportJobReportWorkerSaga payload = ', payload);

	try {
		yield put(toggleLoaderAction(true));
		const importTagsReportData = yield call(getTagsImportJobReportAPI, payload);
		const { campaignImportJob } = payload;
		yield put(setTagsImportJobReportAction({ campaignImportJob, importTagsReportData }));
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on get import tags job report',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* getPageableTagsSaga() {
	yield takeLatest(TAG_GET_PAGEABLE_TAGS, getPageableTagsWorkerSaga);
}

export function* registerTagSaga() {
	yield takeLatest(TAG_REGISTER_TAG, registerTagWorkerSaga);
}

export function* updateTagSaga() {
	yield takeLatest(TAG_UPDATE_TAG, updateTagWorkerSaga);
}

export function* deleteTagSaga() {
	yield takeLatest(TAG_DELETE_TAG, deleteTagWorkerSaga);
}

export function* getTagCountersSaga() {
	yield takeLatest(TAG_GET_TAG_COUNTERS, getTagCountersWorkerSaga);
}

export function* updateTagCountersSaga() {
	yield takeLatest(TAG_UPDATE_TAG_COUNTERS, updateTagCountersWorkerSaga);
}

export function* assignTagsToCampaignSaga() {
	yield takeLatest(TAG_ASSIGN_TAGS_TO_CAMPAIGN, assignTagsToCampaignWorkerSaga);
}

export function* unassignTagsSaga() {
	yield takeLatest(TAG_UNASSIGN_TAGS, unassignTagsWorkerSaga);
}

export function* bulkTagStatusChangeSaga() {
	yield takeLatest(TAG_BULK_TAG_STATUS_CHANGE, bulkTagStatusChangeWorkerSaga);
}

export function* deleteTagsSaga() {
	yield takeLatest(TAG_DELETE_TAGS, deleteTagsWorkerSaga);
}

export function* getTagOutcomesSaga() {
	yield takeLatest(TAG_GET_TAG_OUTCOMES, getTagOutcomesWorkerSaga);
}

export function* registerTagOutcomesSaga() {
	yield takeLatest(TAG_REGISTER_TAG_OUTCOMES, registerTagOutcomesWorkerSaga);
}

export function* getTagsImportJobReportSaga() {
	yield takeEvery(TAG_GET_TAGS_IMPORT_JOB_REPORT, getTagsImportJobReportWorkerSaga);
}

import { authToken } from '../session-helpers';
import request from '../request';
import { FEATURE_URL_GET_PAGEABLE_FEATURES_QUERY_func } from '../constants/url-paths';

export const getPageableFeaturesAPI = payload => {
	//[DEBUG] console.log('getPageableFeaturesAPI start, payload = ', payload);

	const { pageableParameters, filterParameters } = payload;

	return request(
		FEATURE_URL_GET_PAGEABLE_FEATURES_QUERY_func({
			pageableParameters,
			filterParameters,
		}),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

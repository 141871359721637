import request from '../request';
import {
	TAG_URL_GET_PAGEABLE_TAGS_QUERY_func,
	TAG_URL_REGISTER_TAG,
	TAG_URL_UPDATE_TAG,
	TAG_URL_DELETE_TAG_WITH_ID_func,
	TAG_URL_REQUEST_COUNTER_FOR_TAG_WITH_ID_func,
	TAG_URL_UPDATE_COUNTER_FOR_TAG_WITH_ID_func,
	TAG_URL_ASSIGN_TAGS_TO_CAMPAIGN_func,
	TAG_URL_UNASSIGN_TAGS,
	TAG_URL_BULK_TAG_STATUS_CHANGE,
	TAG_URL_DELETE_TAGS,
	TAG_URL_GET_AVAILABLE_CAMPAIGNS_OF_TAG_func,
	TAG_URL_UPLOAD_TAGS_ON_CAMPAIGN_func,
	TAG_URL_UPDATE_TAG_OUTCOMES_func,
	TAG_URL_GET_TAG_OUTCOMES_func,
	TAG_URL_GET_TAGS_IMPORT_JOB_REPORT_func,
} from '../constants/url-paths';
import { authToken } from '../session-helpers';
import { TAG_STATUS_INACTIVE } from '../constants/tags/index.const';

// -> from saga

export const getPageableTagsAPI = payload => {
	//[DEBUG] console.log('getPageableTagsAPI payload = ', payload);
	const { pageableParameters, filterParameters } = payload;

	return request(
		TAG_URL_GET_PAGEABLE_TAGS_QUERY_func({ pageableParameters, filterParameters }),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

export const registerTagAPI = payload => {
	//[DEBUG] console.log('registerTagAPI payload = ', payload);
	const { tagObject } = payload;
	const {
		tagUid,
		tagType,
		// companyId, // should be assigned on backend
		// campaignId, // should be assigned afterward
		// tagStatus,
		tagIdentifier,
		tagCustomData,
	} = tagObject;

	const body = JSON.stringify({
		uid: tagUid,
		tagType,
		// companyId,
		// campaignId,
		tagStatus: TAG_STATUS_INACTIVE,
		identifier: tagIdentifier,
		customData: tagCustomData,
	});

	return request(TAG_URL_REGISTER_TAG, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${authToken()}`,
			'Content-Type': 'application/json',
		},
		body,
	}).then(response => Promise.resolve(response));
};

export const updateTagAPI = payload => {
	//[DEBUG] console.log('updateTagAPI payload = ', payload);
	const { tagObject } = payload;
	const { tagId, tagStatus, tagCampaignId } = tagObject;

	const body = JSON.stringify({
		id: tagId,
		tagStatus,
		...(typeof tagCampaignId !== 'undefined' ? { campaignId: tagCampaignId } : {}),
	});

	//[DEBUG] console.log('TAG_URL_UPDATE_TAG, body =', body);
	return request(TAG_URL_UPDATE_TAG, {
		method: 'PUT',
		headers: {
			Authorization: `Bearer ${authToken()}`,
			'Content-Type': 'application/json',
		},
		body,
	}).then(response => Promise.resolve(response));
};

export const deleteTagAPI = payload => {
	//[DEBUG] console.log('deleteTagAPI payload = ', payload);
	const { tagObject } = payload;
	const { tagId } = tagObject;

	//[DEBUG] console.log('TAG_URL_DELETE_TAG_WITH_ID_func, tagId =', tagId);
	return request(TAG_URL_DELETE_TAG_WITH_ID_func({ tagId: tagId }), {
		method: 'DELETE',
		headers: {
			Authorization: `Bearer ${authToken()}`,
		},
	}).then(response => Promise.resolve(response));
};

export const getTagCountersAPI = payload => {
	//[DEBUG] console.log('getTagCountersAPI payload = ', payload);
	const { tagObject } = payload;
	const { tagId } = tagObject;

	return request(TAG_URL_REQUEST_COUNTER_FOR_TAG_WITH_ID_func({ tagId: tagId }), {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authToken()}`,
		},
	}).then(response => Promise.resolve(response));
};

export const updateTagCountersAPI = payload => {
	//[DEBUG] console.log('updateTagCountersAPI payload = ', payload);
	const { tagCounter, tagObject } = payload;
	const { tagId } = tagObject;
	const body = JSON.stringify(tagCounter);

	return request(TAG_URL_UPDATE_COUNTER_FOR_TAG_WITH_ID_func({ tagId: tagId }), {
		method: 'PUT',
		body,
		headers: {
			Authorization: `Bearer ${authToken()}`,
			'Content-Type': 'application/json',
		},
	}).then(response => Promise.resolve(response));
};

export const uploadTagsDataAPI = payload => {
	//[DEBUG] console.log('uploadTagsDataAPI payload = ', payload);
	const { campaignObject, tagsFile } = payload;
	const { campaignId } = campaignObject;
	// console.log('tagsFile');
	// console.log(tagsFile);

	const formData = new FormData();
	formData.append('file', tagsFile);
	return request(TAG_URL_UPLOAD_TAGS_ON_CAMPAIGN_func({ campaignId }), {
		method: 'POST',
		body: formData,
		headers: {
			Authorization: `Bearer ${authToken()}`,
			// 'Content-Type': 'multipart/form-data',
		},
	}).then(response => Promise.resolve(response));
};

// this can be also used for one tag
export const assignTagsToCampaignAPI = payload => {
	//[DEBUG] console.log('assignTagsToCampaignAPI payload = ', payload);
	const { tagIdList, campaignObject } = payload;
	const { campaignId } = campaignObject;

	const body = JSON.stringify({
		arrayOfIDs: tagIdList,
	});

	return request(TAG_URL_ASSIGN_TAGS_TO_CAMPAIGN_func({ campaignId: campaignId }), {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${authToken()}`,
			'Content-Type': 'application/json',
		},
		body,
	}).then(response => Promise.resolve(response));
};

export const unassignTagsAPI = payload => {
	//[DEBUG] console.log('unassignTagsAPI payload = ', payload);
	const { tagIdList } = payload;

	const body = JSON.stringify({
		arrayOfIDs: tagIdList,
	});

	return request(TAG_URL_UNASSIGN_TAGS, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${authToken()}`,
			'Content-Type': 'application/json',
		},
		body,
	}).then(response => Promise.resolve(response));
};

export const bulkTagStatusChangeAPI = payload => {
	//[DEBUG] console.log('bulkTagStatusChangeAPI payload = ', payload);
	const { status, tagIdList } = payload;

	const body = JSON.stringify({
		status,
		arrayOfIDs: tagIdList,
	});

	return request(TAG_URL_BULK_TAG_STATUS_CHANGE, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${authToken()}`,
			'Content-Type': 'application/json',
		},
		body,
	}).then(response => Promise.resolve(response));
};

export const deleteTagsAPI = payload => {
	//[DEBUG] console.log('deleteTagsAPI payload = ', payload);
	const { tagIdList } = payload;

	const body = JSON.stringify({
		arrayOfIDs: tagIdList,
	});

	return request(TAG_URL_DELETE_TAGS, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${authToken()}`,
			'Content-Type': 'application/json',
		},
		body,
	}).then(response => Promise.resolve(response));
};

export const requestAvailableCampaignsOfTagAPI = payload => {
	const { tagObject, campaignObject } = payload;

	const pageableParameters = {
		page: 0,
		size: 5,
		// sortBy: 'name',
		// order: 'ASC',
		sortBy: 'createdTs',
		order: 'DESC',
		deleted: 0,
	};
	const filterParameters = {
		nameParam: campaignObject.name,
		tagTypeParam: tagObject.tagType,
	};

	return request(
		TAG_URL_GET_AVAILABLE_CAMPAIGNS_OF_TAG_func({
			tagId: tagObject.tagId,
			pageableParameters,
			filterParameters,
		}),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

export const getTagOutcomesAPI = payload => {
	//[DEBUG] console.log('getTagOutcomesAPI payload = ', payload);
	const { tagObject } = payload;
	const { tagId } = tagObject;

	return request(
		TAG_URL_GET_TAG_OUTCOMES_func({
			tagId,
		}),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

export const updateTagOutcomesAPI = payload => {
	//[DEBUG] console.log('updateTagOutcomesAPI payload = ', payload);
	const { tagObject, outcomesObject } = payload;
	const { tagId } = tagObject;

	const body = JSON.stringify(outcomesObject);

	//[DEBUG] console.log('CAMPAIGN_URL_UPDATE_OUTCOMES_func, body =', body);
	return request(
		TAG_URL_UPDATE_TAG_OUTCOMES_func({
			tagId,
		}),
		{
			method: 'PUT',
			headers: {
				Authorization: `Bearer ${authToken()}`,
				'Content-Type': 'application/json',
			},
			body,
		}
	).then(response => Promise.resolve(response));
};

export const getTagsImportJobReportAPI = payload => {
	//[DEBUG] console.log('getTagsImportJobReportAPI payload = ', payload);
	const { campaignImportJob } = payload;
	const { jobId } = campaignImportJob;

	return request(
		TAG_URL_GET_TAGS_IMPORT_JOB_REPORT_func({
			jobId,
		}),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

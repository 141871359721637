import {
	FILE_SET_TERMS_HTML,
	FILE_SET_PRIVACY_HTML,
	FILE_SET_ATTRIBUTIONS_OBJECT,
} from './constants';

const initialState = {
	termsHtml: null,
	privacyHtml: null,
	attributionsObject: [],
};

export default (state = initialState, action) => {
	switch (action.type) {
		case FILE_SET_TERMS_HTML: {
			return {
				...state,
				termsHtml: action.termsHtml,
			};
		}
		case FILE_SET_PRIVACY_HTML: {
			return {
				...state,
				privacyHtml: action.privacyHtml,
			};
		}
		case FILE_SET_ATTRIBUTIONS_OBJECT: {
			return {
				...state,
				attributionsObject: action.attributionsObject,
			};
		}
		default:
			return {
				...state,
			};
	}
};

// ACTION CONSTANTS

export const CAMPAIGN_GET_PAGEABLE_CAMPAIGNS = 'CAMPAIGN_GET_PAGEABLE_CAMPAIGNS';
export const CAMPAIGN_SET_PAGEABLE_CAMPAIGNS = 'CAMPAIGN_SET_PAGEABLE_CAMPAIGNS';

export const CAMPAIGN_REGISTER_CAMPAIGN = 'CAMPAIGN_REGISTER_CAMPAIGN';
export const CAMPAIGN_UPDATE_CAMPAIGN = 'CAMPAIGN_UPDATE_CAMPAIGN';
export const CAMPAIGN_DELETE_CAMPAIGN = 'CAMPAIGN_DELETE_CAMPAIGN';
// export const CAMPAIGN_PUBLISH_CAMPAIGN = 'CAMPAIGN_PUBLISH_CAMPAIGN';

export const CAMPAIGN_GET_PAGEABLE_ACTIVITIES_OF_CAMPAIGN =
	'CAMPAIGN_GET_PAGEABLE_ACTIVITIES_OF_CAMPAIGN';
export const CAMPAIGN_SET_PAGEABLE_ACTIVITIES_OF_CAMPAIGN =
	'CAMPAIGN_SET_PAGEABLE_ACTIVITIES_OF_CAMPAIGN';

export const CAMPAIGN_GET_DEFINITIONS = 'CAMPAIGN_GET_DEFINITIONS';
export const CAMPAIGN_SET_DEFINITIONS = 'CAMPAIGN_SET_DEFINITIONS';

export const CAMPAIGN_REGISTER_DEFINITIONS = 'CAMPAIGN_REGISTER_DEFINITIONS';
export const CAMPAIGN_UPDATE_DEFINITIONS = 'CAMPAIGN_UPDATE_DEFINITIONS';
export const CAMPAIGN_DELETE_DEFINITIONS = 'CAMPAIGN_DELETE_DEFINITIONS';

export const CAMPAIGN_GET_OUTCOMES = 'CAMPAIGN_GET_OUTCOMES';
export const CAMPAIGN_SET_OUTCOMES = 'CAMPAIGN_SET_OUTCOMES';

export const CAMPAIGN_REGISTER_OUTCOMES = 'CAMPAIGN_REGISTER_OUTCOMES';
export const CAMPAIGN_UPDATE_OUTCOMES = 'CAMPAIGN_UPDATE_OUTCOMES';
export const CAMPAIGN_DELETE_OUTCOMES = 'CAMPAIGN_DELETE_OUTCOMES';

export const CAMPAIGN_SET_TAGS_PAGE_CAMPAIGN = 'CAMPAIGN_SET_TAGS_PAGE_CAMPAIGN';

export const CAMPAIGN_GET_DEFAULT_OUTCOMES = 'CAMPAIGN_GET_DEFAULT_OUTCOMES';
export const CAMPAIGN_UPDATE_DEFAULT_OUTCOMES = 'CAMPAIGN_UPDATE_DEFAULT_OUTCOMES';
export const CAMPAIGN_SET_DEFAULT_OUTCOMES = 'CAMPAIGN_SET_DEFAULT_OUTCOMES';

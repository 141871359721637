/* eslint-disable max-len */
import { SERVER, BASE_API_URL, FRONTEND_URL } from '../../../config';

const BASE = BASE_API_URL;
export const GENERAL_URL_PATH = `${SERVER}${BASE}`;

// ###############################################################################
// APIKEY

export const APIKEY_URL_SHOW_APIKEY_WITH_ROLE_func = ({ apikeyRole }) => {
	return `${SERVER}${BASE}/apikey/show/${apikeyRole}`;
};

export const APIKEY_URL_GET_PAGEABLE_APIKEYS_QUERY_func = ({ pageableParameters }) => {
	const { page, size, sortBy, order, deleted } = pageableParameters;
	const parametersP = `page=${page}&size=${size}&sortBy=${sortBy}&order=${order}&deleted=${deleted}`;
	return `${SERVER}${BASE}/apikey?${parametersP}`;
};

export const APIKEY_URL_GENERATE_APIKEY_WITH_ROLE_func = ({ apikeyRole }) => {
	//[DEBUG] console.log('url apikeyRole =', apikeyRole);
	return `${SERVER}${BASE}/apikey/${apikeyRole}`;
};

export const APIKEY_URL_DELETE_APIKEY_WITH_ROLE_func = ({ apikeyRole }) => {
	//[DEBUG] console.log('url apikeyRole =', apikeyRole);
	return `${SERVER}${BASE}/apikey/${apikeyRole}`;
};

// ###############################################################################
// AUTH

export const LOCAL_URL_PATH = FRONTEND_URL || GENERAL_URL_PATH;

export const AUTH_URL_LOGIN_USER = `${SERVER}${BASE}/login`;
export const AUTH_URL_LOGIN_USER_WITH_ENCODED_URL = `${GENERAL_URL_PATH}/login`;
export const AUTH_URL_LOGOUT_USER = `${SERVER}${BASE}/logout`;
export const AUTH_URL_LOGOUT_USER_ON_CAS = `${GENERAL_URL_PATH}/logout/cas`;
export const AUTH_URL_REQUEST_TOKEN = `${SERVER}${BASE}/application/token`;
export const AUTH_URL_ACCEPT_TERMS_FOR_COMPANY_func = ({ companyId }) =>
	`${SERVER}${BASE}/company/${companyId}/accepttnc`;
export const AUTH_URL_REVOKE_TERMS_FOR_COMPANY_func = ({ companyId }) =>
	`${SERVER}${BASE}/company/${companyId}/revoketnc`;

// ###############################################################################
// BOOTSTRAP

export const BOOTSTRAP_URL_REQUEST_APP_STATUS = `${SERVER}${BASE}/application/status`;
export const BOOTSTRAP_URL_REGISTER_APP = `${SERVER}${BASE}/application/bootstrap`;

// ###############################################################################
// DASHBOARD

export const DASHBOARD_URL_REQUEST_DASHBOARD_func = ({ tabType }) => {
	return `${SERVER}${BASE}/dashboard/${tabType}`;
};

// ###############################################################################
// KEY

export const KEY_URL_GET_KEY_BY_ID_func = ({ keyId }) => {
	return `${SERVER}${BASE}/key/${keyId}`;
};

export const KEY_URL_GET_PAGEABLE_KEYS_QUERY_func = ({
	pageableParameters,
	filterParameters,
}) => {
	const { page, size, sortBy, order, deleted } = pageableParameters;
	//EXAMPLE:
	//&idParam=12742374
	//&nameParam=imeHHHHHHH
	//&keyTypeParam=HSM
	//&keySubTypeParam=SECP_224R1
	const {
		idParam,
		nameParam,
		keyTypeParam,
		keySubTypeParam,
		keyVisibilityParam,
		companyIdParam,
	} = filterParameters;

	const parametersP = `page=${page}&size=${size}&sortBy=${sortBy}&order=${order}&deleted=${deleted}`;
	let parametersF = '';
	parametersF += idParam ? `&idParam=${idParam}` : '';
	parametersF += nameParam ? `&nameParam=${nameParam}` : '';
	parametersF += keyTypeParam ? `&keyTypeParam=${keyTypeParam}` : '';
	parametersF += keySubTypeParam ? `&keySubTypeParam=${keySubTypeParam}` : '';
	parametersF += keyVisibilityParam ? `&keyVisibilityParam=${keyVisibilityParam}` : '';
	parametersF += companyIdParam ? `&companyIdParam=${companyIdParam}` : '';

	return `${SERVER}${BASE}/key?${parametersP}${parametersF}`;
};

export const KEY_URL_REGISTER_KEY = `${SERVER}${BASE}/key`;
export const KEY_URL_UPDATE_KEY = `${SERVER}${BASE}/key`;

export const KEY_URL_DELETE_KEY_WITH_ID_func = ({ keyId }) => {
	return `${SERVER}${BASE}/key/${keyId}`;
};

export const KEY_URL_ASSIGN_KEY_ID_TO_COMPANY_ID_func = ({ keyId, companyId }) => {
	return `${SERVER}${BASE}/key/${keyId}/assign/${companyId}`;
};

export const KEY_URL_UNASSIGN_KEY_ID_FROM_COMPANY_ID_func = ({ keyId, companyId }) => {
	return `${SERVER}${BASE}/key/${keyId}/remove/${companyId}`;
};

export const KEY_URL_GET_PAGEABLE_COMPANIES_OF_KEY_ID_QUERY_func = ({
	keyId,
	pageableParameters,
}) => {
	const { page, size, sortBy, order, deleted } = pageableParameters;
	const parametersP = `page=${page}&size=${size}&sortBy=${sortBy}&order=${order}&deleted=${deleted}`;
	return `${SERVER}${BASE}/key/${keyId}/assignments?${parametersP}`;
};

export const KEY_URL_GET_AVAILABLE_COMPANIES_OF_KEY_ID_QUERY_func = ({
	keyId,
	pageableParameters,
	filterParameters,
}) => {
	const { size } = pageableParameters;
	const { nameParam } = filterParameters;

	const parametersP = `size=${size}`;
	let parametersF = '';
	parametersF += nameParam ? `&nameParam=${nameParam}` : '';

	return `${SERVER}${BASE}/key/${keyId}/unassigned?${parametersP}${parametersF}`;
};

export const KEY_URL_UPLOAD_DIVERSIFY_KEY_func = ({ keyId }) => {
	return `${SERVER}${BASE}/key/diversify/${keyId}`;
};

export const KEY_URL_UPLOAD_PASSWORD_GENERATOR_func = ({ keyId }) => {
	return `${SERVER}${BASE}/key/testmodepassgen/${keyId}`;
};

export const KEY_URL_GET_DIVERSIFY_KEY_JOB_REPORT_func = ({ jobId }) => {
	return `${SERVER}${BASE}/key/diversify/file/${jobId}`;
};

export const KEY_URL_GET_PASSWORD_GENERATOR_JOB_REPORT_func = ({ jobId }) => {
	return `${SERVER}${BASE}/key/diversify/file/${jobId}`;
};

// ###############################################################################
// PRIVACYKEY

export const PRIVACYKEY_URL_GET_PRIVACYKEY_BY_ID_func = ({ privacykeyId }) => {
	return `${SERVER}${BASE}/privacykey/${privacykeyId}`;
};

export const PRIVACYKEY_URL_GET_PAGEABLE_PRIVACYKEYS_QUERY_func = ({
	pageableParameters,
	filterParameters,
}) => {
	const { page, size, sortBy, order, deleted } = pageableParameters;
	const { versionParam, nameParam } = filterParameters;

	const parametersP = `page=${page}&size=${size}&sortBy=${sortBy}&order=${order}&deleted=${deleted}`;
	let parametersF = '';
	parametersF += versionParam ? `&versionParam=${versionParam}` : '';
	parametersF += nameParam ? `&nameParam=${nameParam}` : '';

	return `${SERVER}${BASE}/privacykey?${parametersP}${parametersF}`;
};

export const PRIVACYKEY_URL_REGISTER_PRIVACYKEY = `${SERVER}${BASE}/privacykey`;
export const PRIVACYKEY_URL_UPDATE_PRIVACYKEY = `${SERVER}${BASE}/privacykey`;

export const PRIVACYKEY_URL_DELETE_PRIVACYKEY_WITH_ID_func = ({ privacykeyId }) => {
	return `${SERVER}${BASE}/privacykey/${privacykeyId}`;
};

// ###############################################################################
// TAG

export const TAG_URL_GET_PAGEABLE_TAGS_QUERY_func = ({
	pageableParameters,
	filterParameters,
}) => {
	const { page, size, sortBy, order, deleted } = pageableParameters;
	const {
		uidParam,
		tagTypeParam,
		// tagTamperParam,
		statusParam,
		creationRangeStartParam,
		creationRangeEndParam,
		modificationRangeStartParam,
		modificationRangeEndParam,
		assignedParam,
		campaignIdParam,
		campaignNameParam,
		nfcRangeFromParam,
		nfcRangeToParam,
		usedRangeFromParam,
		usedRangeToParam,
		lastOutcomeParam,
	} = filterParameters;

	const parametersP = `page=${page}&size=${size}&sortBy=${sortBy}&order=${order}&deleted=${deleted}`;
	let parametersF = '';
	parametersF += uidParam ? `&uidParam=${uidParam}` : '';
	parametersF += tagTypeParam ? `&tagTypeParam=${tagTypeParam}` : '';
	// parametersF += tagTamperParam ? `&tagTamperParam=${tagTamperParam}` : '';
	parametersF += statusParam ? `&statusParam=${statusParam}` : '';

	parametersF += creationRangeStartParam
		? `&creationRangeStartParam=${creationRangeStartParam.toISOString()}`
		: '';
	parametersF += creationRangeEndParam
		? `&creationRangeEndParam=${creationRangeEndParam.toISOString()}`
		: '';
	parametersF += modificationRangeStartParam
		? `&modificationRangeStartParam=${modificationRangeStartParam.toISOString()}`
		: '';
	parametersF += modificationRangeEndParam
		? `&modificationRangeEndParam=${modificationRangeEndParam.toISOString()}`
		: '';

	parametersF += assignedParam ? `&assignedParam=${assignedParam}` : '';
	parametersF += campaignIdParam ? `&campaignIdParam=${campaignIdParam}` : '';
	parametersF += campaignNameParam ? `&campaignNameParam=${campaignNameParam}` : '';

	parametersF += nfcRangeFromParam ? `&nfcRangeFromParam=${nfcRangeFromParam}` : '';
	parametersF += nfcRangeToParam ? `&nfcRangeToParam=${nfcRangeToParam}` : '';
	parametersF += usedRangeFromParam ? `&usedRangeFromParam=${usedRangeFromParam}` : '';
	parametersF += usedRangeToParam ? `&usedRangeToParam=${usedRangeToParam}` : '';
	parametersF += lastOutcomeParam ? `&lastOutcomeParam=${lastOutcomeParam}` : '';

	return `${SERVER}${BASE}/tag?${parametersP}${parametersF}`;
};

export const TAG_URL_REGISTER_TAG = `${SERVER}${BASE}/tag`;
export const TAG_URL_UPDATE_TAG = `${SERVER}${BASE}/tag`;

export const TAG_URL_DELETE_TAG_WITH_ID_func = ({ tagId }) => {
	return `${SERVER}${BASE}/tag/${tagId}/id`;
};

export const TAG_URL_REQUEST_COUNTER_FOR_TAG_WITH_ID_func = ({ tagId }) => {
	return `${SERVER}${BASE}/tag/${tagId}/counter`;
};

export const TAG_URL_UPDATE_COUNTER_FOR_TAG_WITH_ID_func = ({ tagId }) => {
	return `${SERVER}${BASE}/tag/${tagId}/counter`;
};

export const TAG_URL_ASSIGN_TAGS_TO_CAMPAIGN_func = ({ campaignId }) => {
	return `${SERVER}${BASE}/campaign/${campaignId}/assign`;
};

// be aware should not clash with assign (url campaignId != 'unassign') //[THINK] think about it
export const TAG_URL_UNASSIGN_TAGS = `${SERVER}${BASE}/campaign/unassign`;

export const TAG_URL_BULK_TAG_STATUS_CHANGE = `${SERVER}${BASE}/tag/bulk/status`;

export const TAG_URL_DELETE_TAGS = `${SERVER}${BASE}/tag/bulk/delete`;

export const TAG_URL_GET_AVAILABLE_CAMPAIGNS_OF_TAG_func = ({
	// tagId,
	pageableParameters,
	filterParameters,
}) => {
	const { page, size, sortBy, order, deleted } = pageableParameters;
	const parametersP = `page=${page}&size=${size}&sortBy=${sortBy}&order=${order}&deleted=${deleted}`;
	const { nameParam, tagTypeParam } = filterParameters;

	// in the future we could use tagId to filter out from existing campaign
	// but as tag is assigned only to one campaign this is not a problem
	let parametersF = '';
	parametersF += nameParam ? `&nameParam=${nameParam}` : '';
	parametersF += tagTypeParam ? `&tagTypeParam=${tagTypeParam}` : '';

	return `${SERVER}${BASE}/campaign?${parametersP}${parametersF}`;
};

export const TAG_URL_UPLOAD_TAGS_ON_CAMPAIGN_func = ({ campaignId }) => {
	return `${SERVER}${BASE}/tag/import/${campaignId}`;
};

export const TAG_URL_GET_TAG_OUTCOMES_func = ({ tagId }) => {
	return `${SERVER}${BASE}/tag/${tagId}/outcome/redirection`;
};

export const TAG_URL_UPDATE_TAG_OUTCOMES_func = ({ tagId }) => {
	return `${SERVER}${BASE}/tag/${tagId}/outcome/redirection`;
};

export const TAG_URL_GET_TAGS_IMPORT_JOB_REPORT_func = ({ jobId }) => {
	return `${SERVER}${BASE}/tag/import/report/${jobId}`;
};

// ###############################################################################
// CAMPAIGNS

export const CAMPAIGN_URL_GET_PAGEABLE_CAMPAIGNS_QUERY_func = ({
	pageableParameters,
	filterParameters,
}) => {
	const { page, size, sortBy, order, deleted } = pageableParameters;
	const {
		nameParam,
		tagTypeParam,
		statusParam,
		creationRangeStartParam,
		creationRangeEndParam,
	} = filterParameters;

	const parametersP = `page=${page}&size=${size}&sortBy=${sortBy}&order=${order}&deleted=${deleted}`;
	let parametersF = '';
	parametersF += nameParam ? `&nameParam=${nameParam}` : '';
	parametersF += tagTypeParam ? `&tagTypeParam=${tagTypeParam}` : '';
	parametersF += statusParam ? `&statusParam=${statusParam}` : '';
	parametersF += creationRangeStartParam
		? `&creationRangeStartParam=${creationRangeStartParam.toISOString()}`
		: '';
	parametersF += creationRangeEndParam
		? `&creationRangeEndParam=${creationRangeEndParam.toISOString()}`
		: '';

	return `${SERVER}${BASE}/campaign?${parametersP}${parametersF}`;
};

export const CAMPAIGN_URL_REGISTER_CAMPAIGN = `${SERVER}${BASE}/campaign`;
export const CAMPAIGN_URL_UPDATE_CAMPAIGN = `${SERVER}${BASE}/campaign`;
export const CAMPAIGN_URL_DELETE_CAMPAIGN_WITH_ID_func = ({ campaignId }) => {
	return `${SERVER}${BASE}/campaign/${campaignId}`;
};

/* THIS SHOULD NOT BE REMOVED AS IT WAS A WANTED FEATURE FROM MANAGEMENT (but then withdrawn for some time)
export const CAMPAIGN_URL_GET_PAGEABLE_ACTIVITIES_OF_CAMPAIGN_ID_QUERY_func = ({
	campaignId,
	pageableParameters,
}) => {
	const { page, size, sortBy, order, deleted } = pageableParameters;
	const parametersP = `page=${page}&size=${size}&sortBy=${sortBy}&order=${order}&deleted=${deleted}`;

	return `${SERVER}${BASE}/key/${campaignId}/assignments?${parametersP}`;
};
*/

export const CAMPAIGN_URL_GET_DEFINITIONS_func = ({ campaignId, campaignType }) => {
	return `${SERVER}${BASE}/campaign/${campaignId}/definition/${campaignType}`;
};

export const CAMPAIGN_URL_UPDATE_DEFINITIONS_func = ({ campaignId, campaignType }) => {
	return `${SERVER}${BASE}/campaign/${campaignId}/definition/${campaignType}`;
};

export const CAMPAIGN_URL_GET_OUTCOMES_func = ({ campaignId, campaignType }) => {
	return `${SERVER}${BASE}/campaign/${campaignId}/outcome/${campaignType}`;
};

export const CAMPAIGN_URL_UPDATE_OUTCOMES_func = ({ campaignId, campaignType }) => {
	return `${SERVER}${BASE}/campaign/${campaignId}/outcome/${campaignType}`;
};

export const CAMPAIGN_URL_GET_DEFAULT_OUTCOMES = `${SERVER}${BASE}/campaign/default`;
export const CAMPAIGN_URL_UPDATE_DEFAULT_OUTCOMES = `${SERVER}${BASE}/campaign/default`;

// ###############################################################################
// USER

// -> company

export const USER_URL_GET_PAGEABLE_COMPANIES_QUERY_func = ({
	pageableParameters,
	filterParameters,
}) => {
	const { page, size, sortBy, order, deleted } = pageableParameters;
	const { nameParam, statusParam } = filterParameters;

	const parametersP = `page=${page}&size=${size}&sortBy=${sortBy}&order=${order}&deleted=${deleted}`;
	let parametersF = '';
	parametersF += nameParam ? `&nameParam=${nameParam}` : '';
	parametersF += statusParam ? `&statusParam=${statusParam}` : '';

	return `${SERVER}${BASE}/company?${parametersP}${parametersF}`;
};

export const USER_URL_REGISTER_COMPANY = `${SERVER}${BASE}/company`;
export const USER_URL_UPDATE_COMPANY = `${SERVER}${BASE}/company`;
export const USER_URL_GET_COMPANY_BY_ID_func = ({ companyId }) => {
	return `${SERVER}${BASE}/company/${companyId}`;
};
export const USER_URL_DELETE_COMPANY_WITH_ID_func = ({ companyId }) => {
	return `${SERVER}${BASE}/company/${companyId}`;
};

export const USER_URL_GET_PAGEABLE_USERS_OF_COMPANY_ID_QUERY_func = ({
	companyId,
	pageableParameters,
}) => {
	const { page, size, sortBy, order, deleted } = pageableParameters;
	const parametersP = `page=${page}&size=${size}&sortBy=${sortBy}&order=${order}&deleted=${deleted}`;
	return `${SERVER}${BASE}/company/${companyId}/user?${parametersP}`;
};

// -> user

export const USER_URL_GET_PAGEABLE_USERS_QUERY_func = ({
	pageableParameters,
	filterParameters,
}) => {
	const { page, size, sortBy, order, deleted } = pageableParameters;
	const { nameParam, roleParam, emailParam } = filterParameters;

	const parametersP = `page=${page}&size=${size}&sortBy=${sortBy}&order=${order}&deleted=${deleted}`;
	let parametersF = '';
	parametersF += nameParam ? `&nameParam=${nameParam}` : '';
	parametersF += roleParam ? `&roleParam=${roleParam}` : '';
	parametersF += emailParam ? `&emailParam=${emailParam}` : '';

	return `${SERVER}${BASE}/user?${parametersP}${parametersF}`;
};
export const USER_URL_GET_PAGEABLE_USERS = `${SERVER}${BASE}/user`;

export const USER_URL_REGISTER_USER = `${SERVER}${BASE}/user`;
export const USER_URL_UPDATE_USER = `${SERVER}${BASE}/user`;
export const USER_URL_GET_USER_BY_ID_func = ({ userId }) => {
	return `${SERVER}${BASE}/user/${userId}`;
};
export const USER_URL_DELETE_USER_WITH_ID_func = ({ userId }) => {
	return `${SERVER}${BASE}/user/${userId}`;
};

// ###############################################################################
// FEATURE

export const FEATURE_URL_GET_PAGEABLE_FEATURES_QUERY_func = ({
	pageableParameters,
	// filterParameters,
}) => {
	const { page, size, sortBy, order, deleted } = pageableParameters;

	const parameters = `page=${page}&size=${size}&sortBy=${sortBy}&order=${order}&deleted=${deleted}`;
	return `${SERVER}${BASE}/feature?${parameters}`;
};

// ###############################################################################
// fileDownloads

export const FILE_DOWNLOAD_URL_DOCUMENT_BY_TYPE_func = ({ documentType }) => {
	return `${SERVER}${BASE}/document/${documentType}`;
};

export const TAG_URL_GET_TAGS_CSV_EXPORT_func = ({
	pageableParameters,
	filterParameters,
}) => {
	const { sortBy, order, deleted } = pageableParameters;
	const {
		uidParam,
		tagTypeParam,
		// tagTamperParam,
		statusParam,
		creationRangeStartParam,
		creationRangeEndParam,
		modificationRangeStartParam,
		modificationRangeEndParam,
		assignedParam,
		campaignIdParam,
		campaignNameParam,
		nfcRangeFromParam,
		nfcRangeToParam,
		usedRangeFromParam,
		usedRangeToParam,
		lastOutcomeParam,
	} = filterParameters;

	const parametersP = `sortBy=${sortBy}&order=${order}&deleted=${deleted}`;
	let parametersF = '';
	parametersF += uidParam ? `&uidParam=${uidParam}` : '';
	parametersF += tagTypeParam ? `&tagTypeParam=${tagTypeParam}` : '';
	// parametersF += tagTamperParam ? `&tagTamperParam=${tagTamperParam}` : '';
	parametersF += statusParam ? `&statusParam=${statusParam}` : '';

	parametersF += creationRangeStartParam
		? `&creationRangeStartParam=${creationRangeStartParam.toISOString()}`
		: '';
	parametersF += creationRangeEndParam
		? `&creationRangeEndParam=${creationRangeEndParam.toISOString()}`
		: '';
	parametersF += modificationRangeStartParam
		? `&modificationRangeStartParam=${modificationRangeStartParam.toISOString()}`
		: '';
	parametersF += modificationRangeEndParam
		? `&modificationRangeEndParam=${modificationRangeEndParam.toISOString()}`
		: '';

	parametersF += assignedParam ? `&assignedParam=${assignedParam}` : '';
	parametersF += campaignIdParam ? `&campaignIdParam=${campaignIdParam}` : '';
	parametersF += campaignNameParam ? `&campaignNameParam=${campaignNameParam}` : '';

	parametersF += nfcRangeFromParam ? `&nfcRangeFromParam=${nfcRangeFromParam}` : '';
	parametersF += nfcRangeToParam ? `&nfcRangeToParam=${nfcRangeToParam}` : '';
	parametersF += usedRangeFromParam ? `&usedRangeFromParam=${usedRangeFromParam}` : '';
	parametersF += usedRangeToParam ? `&usedRangeToParam=${usedRangeToParam}` : '';
	parametersF += lastOutcomeParam ? `&lastOutcomeParam=${lastOutcomeParam}` : '';

	return `${SERVER}${BASE}/tag/export?${parametersP}${parametersF}`;
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';

import createStyles from './createStyles.css';

const NXPDialog = props => {
	const {
		open = false,
		onClose,
		title,
		subtitle,
		children,
		actionButtons,
		fullWidth,
		customStyle,
		contentClass,
		customClass,
		customHeaderClass,
		handleEnter,
		handleExit,
		disableBackdropClose,
		disableEscapeKeyClose,
		hideCloseElements,
	} = props;
	const classes = createStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const openMenu = Boolean(anchorEl);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const primaryButtons = actionButtons ? actionButtons.slice(0, 2) : [];
	const secondaryButtons = actionButtons
		? actionButtons.slice(2, actionButtons.length)
		: [];
	const secondaryToShow = secondaryButtons.filter(button => !button.hide);
	const justPrimary = secondaryToShow.length === 0;
	return (
		<Dialog
			onClose={() => onClose()}
			aria-labelledby="customized-dialog-title"
			classes={{ paper: customClass ? customClass : '' }}
			open={open}
			fullWidth={fullWidth}
			onEntered={handleEnter}
			disableBackdropClick={disableBackdropClose}
			disableEscapeKeyDown={disableEscapeKeyClose}
			onExit={handleExit}
		>
			{title && (
				<MuiDialogTitle
					className={`${classes.headerWrapper} ${
						customHeaderClass ? customHeaderClass : ''
					}`}
					style={customStyle}
					disableTypography
				>
					<div className={classes.titleWrapper}>
						<Typography className={classes.title}>{title}</Typography>
						{subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
					</div>
					{onClose && !hideCloseElements ? (
						<IconButton
							aria-label="Close"
							data-close-dialog
							className={classes.closeButton}
							onClick={onClose}
							disableRipple
						>
							<CloseIcon />
						</IconButton>
					) : null}
				</MuiDialogTitle>
			)}
			<MuiDialogContent className={contentClass ? contentClass : ''}>
				{children}
			</MuiDialogContent>
			{actionButtons && (
				<MuiDialogActions classes={{ root: classes.dialogActionsRoot }}>
					<div className={classes.dialogActionsWrapper}>
						<div className={classes.actionButtons}>
							{primaryButtons.map((button, i) => (
								<Button
									className={`${i === 0 ? classes.firstButton : classes.secondButton} ${
										button.class ? button.class : ''
									}${!justPrimary && i === 1 ? classes.borderRadiusChange : ''}`}
									classes={{
										disabled: i === 1 ? classes.buttonDisabled : '',
									}}
									key={`${button.label}${i}`}
									onClick={() => button.action()}
									disabled={button.disabled}
								>
									{button.label}
								</Button>
							))}
							{secondaryToShow.length > 0 && (
								<Fragment>
									<IconButton
										aria-label="more"
										aria-controls="long-menu"
										aria-haspopup="true"
										onClick={handleClick}
										className={classes.iconMenuButton}
									>
										<MoreVertIcon style={{ fontSize: 17 }} />
									</IconButton>
									<Menu
										id="long-menu"
										anchorEl={anchorEl}
										keepMounted
										open={openMenu}
										onClose={handleClose}
									>
										{secondaryToShow.map((button, i) => (
											<Button
												classes={{
													root: `${classes.menuButton} ${
														button.class ? button.class : ''
													}`,
													disabled: classes.buttonDisabled,
												}}
												key={`${button.label}${i}`}
												onClick={() => button.action()}
												disabled={button.disabled}
												disableRipple
												disableFocusRipple
											>
												{button.label}
											</Button>
										))}
									</Menu>
								</Fragment>
							)}
						</div>
						<Button
							disableRipple
							disableFocusRipple
							onClick={() => onClose()}
							className={classes.cancelButton}
						>
							{'cancel'}
						</Button>
					</div>
				</MuiDialogActions>
			)}
		</Dialog>
	);
};

NXPDialog.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	title: PropTypes.any,
	children: PropTypes.node,
	fullWidth: PropTypes.bool,
	customStyle: PropTypes.object,
	customClass: PropTypes.any,
	contentClass: PropTypes.any,
	customHeaderClass: PropTypes.any,
	actionButtons: PropTypes.array,
	disableBackdropClose: PropTypes.bool,
	disableEscapeKeyClose: PropTypes.bool,
};

export default NXPDialog;

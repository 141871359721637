import { takeLatest, call, put } from 'redux-saga/effects';
// import { push } from 'connected-react-router';

import {
	showApikeyInPageableGetAPI,
	getPageableApikeysAPI,
	registerApikeyAPI,
	deleteApikeyAPI,
} from '../../utils/api/apikey';
import { showApikeyInPageableSetAction, setPageableApikeysAction } from './action';
import {
	APIKEY_SHOW_APIKEY_IN_PAGEABLE_GET,
	APIKEY_GET_PAGEABLE_APIKEYS,
	APIKEY_REGISTER_APIKEY,
	APIKEY_DELETE_APIKEY,
} from './constants';
import { toggleLoaderAction, setGeneralErrorMessageAction } from '../general/action';

export function* showApikeyInPageableGetWorkerSaga({ payload }) {
	//[DEBUG] console.log('showApikeyInPageableGetWorkerSaga start, payload =', payload);
	try {
		yield put(toggleLoaderAction(true));
		const apikeyObject = yield call(showApikeyInPageableGetAPI, payload);
		yield put(showApikeyInPageableSetAction(apikeyObject));
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on show Api Key',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* getPageableApikeysWorkerSaga({ payload }) {
	//[DEBUG] console.log('getPageableApikeysWorkerSaga start, payload =', payload);
	try {
		yield put(toggleLoaderAction(true));
		const apikeys = yield call(getPageableApikeysAPI, payload);
		//[DEBUG] console.log('getPageableApikeysWorkerSaga apikeys =', apikeys);
		yield put(setPageableApikeysAction(apikeys));
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on get pageable API Keys',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* registerApikeyWorkerSaga({ payload }) {
	//[DEBUG] console.log('registerApikeyWorkerSaga start, payload =', payload);
	try {
		yield put(toggleLoaderAction(true));
		yield call(registerApikeyAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);
		const generalErrorObject = {
			title: 'API error on generate Api Key',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* deleteApikeyWorkerSaga({ payload }) {
	console.log('deleteApikeyWorkerSaga payload =', payload);

	try {
		yield put(toggleLoaderAction(true));
		yield call(deleteApikeyAPI, payload);
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Success deleteApikeyWorkerSaga');
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error deleteApikeyWorkerSaga');
		// eslint-disable-next-line
		// console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);

		const generalErrorObject = {
			title: 'API error on delete Api Key',
			subtitle: error.description || '',
			text: error.details || '',
		};
		yield put(setGeneralErrorMessageAction(generalErrorObject));
	}
	yield put(toggleLoaderAction(false));
}

export function* showApikeyInPageableGetSaga() {
	yield takeLatest(APIKEY_SHOW_APIKEY_IN_PAGEABLE_GET, showApikeyInPageableGetWorkerSaga);
}

export function* getPageableApikeysSaga() {
	yield takeLatest(APIKEY_GET_PAGEABLE_APIKEYS, getPageableApikeysWorkerSaga);
}

export function* registerApikeySaga() {
	yield takeLatest(APIKEY_REGISTER_APIKEY, registerApikeyWorkerSaga);
}

export function* deleteApikeySaga() {
	yield takeLatest(APIKEY_DELETE_APIKEY, deleteApikeyWorkerSaga);
}

import { BOOTSTRAP_SET_APP_STATUS } from './constants';

const initialState = {
	status: '',
	formError: '',
	fieldErrors: [],
};

export default (state = initialState, action) => {
	switch (action.type) {
		case BOOTSTRAP_SET_APP_STATUS: {
			return {
				...state,
				status: action.status,
			};
		}
		default:
			return {
				...state,
			};
	}
};

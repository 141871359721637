// ACTION CONSTANTS

export const FILE_GET_TERMS_HTML = 'FILE_GET_TERMS_HTML';
export const FILE_SET_TERMS_HTML = 'FILE_SET_TERMS_HTML';

export const FILE_GET_PRIVACY_HTML = 'FILE_GET_PRIVACY_HTML';
export const FILE_SET_PRIVACY_HTML = 'FILE_SET_PRIVACY_HTML';

export const FILE_GET_ATTRIBUTIONS_JSON = 'FILE_GET_ATTRIBUTIONS_JSON';
export const FILE_SET_ATTRIBUTIONS_OBJECT = 'FILE_SET_ATTRIBUTIONS_OBJECT';

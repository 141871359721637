import { authToken } from '../../session-helpers';
import request from '../../request';
import {
	USER_URL_GET_PAGEABLE_USERS_QUERY_func,
	USER_URL_REGISTER_USER,
	USER_URL_UPDATE_USER,
	USER_URL_GET_USER_BY_ID_func,
	USER_URL_DELETE_USER_WITH_ID_func,
} from '../../constants/url-paths';

// DO NOT REMOVE THIS ONE!!!
export const getUserByIdAPI = id => {
	//[DEBUG] console.log('getUserByIdAPI start, param = ', id);
	return request(USER_URL_GET_USER_BY_ID_func({ userId: id }), {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authToken()}`,
		},
	}).then(response => Promise.resolve(response));
};

export const getPageableUsersAPI = payload => {
	//[DEBUG] console.log('getPageableUsersAPI start, param = ', payload);

	const { pageableParameters, filterParameters } = payload;

	return request(
		USER_URL_GET_PAGEABLE_USERS_QUERY_func({
			pageableParameters,
			filterParameters,
		}),
		{
			method: 'GET',
			headers: {
				Authorization: `Bearer ${authToken()}`,
			},
		}
	).then(response => Promise.resolve(response));
};

export const registerUserAPI = payload => {
	//[DEBUG] console.log('registerUserAPI start, param = ', payload);
	const { userObject } = payload;
	const { email, role, company } = userObject;

	const user = {
		id: '',
		email,
		role,
		company,
	};

	const body = JSON.stringify({
		...user,
	});

	return request(USER_URL_REGISTER_USER, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${authToken()}`,
			'Content-Type': 'application/json',
		},
		body,
	}).then(response => Promise.resolve(response));
};

export const updateUserAPI = payload => {
	//[DEBUG] console.log('updateUserAPI start, param = ', payload);
	const { userObject } = payload;

	const { id, userId, userRole } = userObject;

	const user = {
		id: id || userId, //[IMPROVE] unite this - use userId
		role: userRole,
	};

	const body = JSON.stringify({
		...user,
	});

	return request(USER_URL_UPDATE_USER, {
		method: 'PUT',
		headers: {
			Authorization: `Bearer ${authToken()}`,
			'Content-Type': 'application/json',
		},
		body,
	}).then(response => Promise.resolve(response));
};

export const deleteUserAPI = payload => {
	//[DEBUG] console.log('deleteUserAPI payload =', payload);
	const { userObject } = payload;
	const { userId } = userObject;

	return request(USER_URL_DELETE_USER_WITH_ID_func({ userId }), {
		method: 'DELETE',
		headers: {
			Authorization: `Bearer ${authToken()}`,
		},
	}).then(response => Promise.resolve(response));
};

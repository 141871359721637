import {
	KEY_GET_PAGEABLE_KEYS,
	KEY_SET_PAGEABLE_KEYS,
	KEY_REGISTER_KEY,
	KEY_UPDATE_KEY,
	KEY_DELETE_KEY,
	KEY_OPERATION_KEY_COMPANY_ASSIGN,
	KEY_OPERATION_KEY_COMPANY_UNASSIGN,
	KEY_GET_PAGEABLE_COMPANIES_OF_KEY,
	KEY_SET_PAGEABLE_COMPANIES_OF_KEY,
	KEY_DOWNLOAD_DIVERSIFY_KEY_JOB_REPORT,
	KEY_DOWNLOAD_GENERATED_PASSWORD_JOB_REPORT,
} from './constants';

// ########## ########## ########## ##########
// saga actions

export const getPageableKeysAction = payload => {
	//[DEBUG] console.log('getPageableKeysAction, payload =', payload);
	return {
		type: KEY_GET_PAGEABLE_KEYS,
		payload,
	};
};

export const registerKeyAction = payload => {
	//[DEBUG] console.log('registerKeyAction, payload =', payload);
	return {
		type: KEY_REGISTER_KEY,
		payload,
	};
};

export const updateKeyAction = payload => {
	//[DEBUG] console.log('updateKeyAction, payload =', payload);
	return {
		type: KEY_UPDATE_KEY,
		payload,
	};
};

export const deleteKeyAction = payload => {
	//[DEBUG] console.log('deleteKeyAction, payload =', payload);
	return {
		type: KEY_DELETE_KEY,
		payload,
	};
};

export const operationKeyCompanyAssignAction = payload => {
	//[DEBUG] console.log('operationKeyCompanyAssignAction payload =', payload);
	return {
		type: KEY_OPERATION_KEY_COMPANY_ASSIGN,
		payload,
	};
};

export const operationKeyCompanyUnassignAction = payload => {
	//[DEBUG] console.log('operationKeyCompanyUnassignAction payload =', payload);
	return {
		type: KEY_OPERATION_KEY_COMPANY_UNASSIGN,
		payload,
	};
};

export const getPageableCompaniesOfKeyAction = payload => {
	//[DEBUG] console.log('getPageableCompaniesOfKeyAction payload =', payload);
	return {
		type: KEY_GET_PAGEABLE_COMPANIES_OF_KEY,
		payload,
	};
};

export const downloadDiversifyKeyJobReportAction = payload => {
	//[DEBUG] console.log('downloadDiversifyKeyJobReportAction payload =', payload);
	return {
		type: KEY_DOWNLOAD_DIVERSIFY_KEY_JOB_REPORT,
		payload,
	};
};

export const downloadPasswordGeneratorJobReportAction = payload => {
	console.log('downloadPasswordGeneratorJobReportAction payload =', payload);
	return {
		type: KEY_DOWNLOAD_GENERATED_PASSWORD_JOB_REPORT,
		payload,
	};
};

// ########## ########## ########## ##########
// redux actions

export const setPageableKeysAction = pageableKeys => {
	//[DEBUG] console.log('setPageableKeysAction, pageableKeys =', pageableKeys);
	return {
		type: KEY_SET_PAGEABLE_KEYS,
		pageableKeys,
	};
};

export const setPageableCompaniesOfKeyAction = ({ keyObject, pageableCompanies }) => {
	/*[DEBUG]
	console.log('setPageableUsersOfCompanyAction ({ keyObject, pageableCompanies }) =', {
		keyObject,
		pageableCompanies,
	});
	*/
	return {
		type: KEY_SET_PAGEABLE_COMPANIES_OF_KEY,
		keyId: keyObject.keyId,
		pageableCompanies,
	};
};

import {
	USER_SET_FORM_ERROR,
	USER_GET_PAGEABLE_USERS_OF_COMPANY,
	USER_SET_PAGEABLE_USERS_OF_COMPANY,
} from '../constants';

export * from './action-company';
export * from './action-user';

export const setFormErrorAction = payload => {
	return {
		type: USER_SET_FORM_ERROR,
		payload,
	};
};

export const getPageableUsersOfCompanyAction = payload => {
	//[DEBUG] console.log('getPageableUsersOfCompanyAction payload', payload);
	return {
		type: USER_GET_PAGEABLE_USERS_OF_COMPANY,
		payload,
	};
};

export const setPageableUsersOfCompanyAction = ({ companyObject, pageableUsers }) => {
	/*[DEBUG]
	console.log('setPageableUsersOfCompanyAction ({ companyObject, pageableUsers }) =', {
		companyObject,
		pageableUsers,
	});
	*/
	return {
		type: USER_SET_PAGEABLE_USERS_OF_COMPANY,
		companyId: companyObject.companyId,
		pageableUsers,
	};
};

export const USER_NXP_ADMIN = 'USER_NXP_ADMIN';
export const USER_KDS_OPERATOR = 'USER_KDS_OPERATOR';
export const USER_LOG_VIEWER = 'USER_LOG_VIEWER';
export const USER_COMPANY_ADMIN = 'USER_COMPANY_ADMIN';
export const USER_COMPANY_VIEWER = 'USER_COMPANY_VIEWER';

export const ALL_USER_ROLES = [
	USER_NXP_ADMIN,
	USER_KDS_OPERATOR,
	USER_LOG_VIEWER,
	USER_COMPANY_ADMIN,
	USER_COMPANY_VIEWER,
];

export const NOT_SENT = 'NOT_SENT';
export const SENT = 'SENT';
export const ERROR = 'ERROR';

export const MINIMAL_EMAIL_LENGTH = 6;

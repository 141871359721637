const styles = {
	loader: {
		color: '#b1bac5',
	},
	wrapper: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'rgba(0,0,0,0.1)',
		overflow: 'hidden',
		zIndex: 2147483638,
	},
};

export default styles;

import {
	SUPPORT_EMAIL as CONSTANT_CONFIG_SUPPORT_EMAIL,
	NUM_MINIMAL_PHONE_LENGTH as CONSTANT_CONFIG_NUM_MINIMAL_PHONE_LENGTH,
	FRONTEND_APPLICATION_VERSION as CONSTANT_CONFIG_FRONTEND_APPLICATION_VERSION,
	NO_ACCOUNT_PAGE_URL as CONSTANT_CONFIG_NO_ACCOUNT_PAGE_URL,
} from '../../config';

// global constants
/* eslint-disable-next-line */
export const SERVER = window.globalConfig
	? window.globalConfig.BACKEND_SERVER
	: /* eslint-disable-next-line no-undef */
	  process.env.REACT_APP_SERVER_URL;

export const SUPPORT_EMAIL = CONSTANT_CONFIG_SUPPORT_EMAIL;
export const NUM_MINIMAL_PHONE_LENGTH = CONSTANT_CONFIG_NUM_MINIMAL_PHONE_LENGTH;
export const NO_ACCOUNT_PAGE_URL = CONSTANT_CONFIG_NO_ACCOUNT_PAGE_URL || '/no-account';
export const ACCOUNT_LOGOUT_ERROR_URL = '/logout-error';
export const FRONTEND_APPLICATION_VERSION = String(
	CONSTANT_CONFIG_FRONTEND_APPLICATION_VERSION || '0.01 (not given, online)'
);
export const MINIMAL_AUTH_TOKEN_LENGTH = 20;
// Delay till refreshing of elements (600 if it will work ok) - we also need some time to receive it
// export const GLOBAL_DELAY_MEDIUM = 600; // this should be resolved with better approach
export const GLOBAL_DELAY_MEDIUM = 750; // this should be resolved with better approach

export const NXP_HOMEPAGE = 'https://www.nxp.com/';
export const NXP_LOGIN_URL = 'https://www.nxp.com/security/login';
export const NXP_LOGOUT_URL = 'https://www.nxp.com/security/logout';

export const TERMS_PDF_NAME = 'NTAG Secure Services Terms and Conditions.pdf';
export const PRIVACY_PDF_NAME = 'NTAG Secure Services Privacy Policy.pdf';
export const IMPORT_JOB_REPORT_NAME = 'import_job_report.csv';
export const TAG_EXPORT_CSV_NAME = 'tag_export.csv';
export const DIVERSIFY_JOB_RESPONSE = 'key_diversify_response.asc';
export const PASSWORD_GENERATOR_JOB_RESPONSE = 'password_generator_response.asc';
export const ATTRIBUTIONS_JSON_NAME = 'attributions.json';

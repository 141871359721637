import { takeLeading, takeEvery, call, put } from 'redux-saga/effects';
// import { push } from 'connected-react-router';

import { AUTH_URL_LOGOUT_USER_ON_CAS } from '../../utils/constants/url-paths';
import {
	requestTokenAPI,
	requestTokenAuthorizatedAPI,
	acceptTermsForCompanyAPI,
	revokeTermsForCompanyAPI,
	logoutUserAPI,
} from '../../utils/api/auth';
import { requestAppStatusAPI } from '../../utils/api/bootstrap';
// import { SERVER_UNAVAILABLE } from '../../utils/request';
import {
	authenticatedUser,
	bootstrapStatus,
	userDataFromToken,
} from '../../utils/session-helpers';
import { toggleLoaderAction } from '../general/action';
import { setAppStatusAction } from '../bootstrap/action';
import { setTokenDataAction, requestMyUserDataAction } from '../account/action';
import { setAuthenticatedAction, requestBasicDataAction } from './action';
import {
	AUTH_REQUEST_BASIC_DATA,
	AUTH_REQUEST_TOKEN_AUTHORIZED,
	AUTH_ACCEPT_TERMS_FOR_COMPANY,
	AUTH_REVOKE_TERMS_FOR_COMPANY,
	AUTH_LOGOUT_USER,
} from './constants';

export function* requestBasicDataWorkerSaga() {
	//[DEBUG] console.log('requestBasicDataWorkerSaga start');
	let responseStatus = {};
	try {
		yield put(toggleLoaderAction(true));
		responseStatus = yield call(requestAppStatusAPI);
	} catch (error) {
		//[DEBUG] console.log('Error requestAppStatusAPI');
		//[DEBUG] console.log('Error = ', error);
	}

	let responseToken = {};
	if (responseStatus.stage !== 'CLEAR') {
		// maybe remove this condition in future as this is almost always true
		try {
			if (!authenticatedUser()) {
				responseToken = yield call(requestTokenAPI);
			} else {
				responseToken = yield call(requestTokenAuthorizatedAPI);
			}
		} catch (error) {
			//[DEBUG] console.log('Error requestTokenAPI');
			//[DEBUG] console.log('Error = ', error);
		}
	}

	//[DEBUG] console.log('responseStatus =', responseStatus);
	sessionStorage.setItem('bootstrapstatus', responseStatus.stage || '');

	//[DEBUG] console.log('responseToken=', responseToken);
	sessionStorage.setItem('token', responseToken.token || '');

	//[DEBUG] console.log('parsing token...');
	if (responseToken.token) {
		//[DEBUG] console.log('parsing token');
		const myTokenData = userDataFromToken(responseToken.token);

		//[DEBUG] console.log('myTokenData=', myTokenData);
		yield put(setTokenDataAction(myTokenData));
	}

	yield put(setAuthenticatedAction(authenticatedUser()));
	yield put(setAppStatusAction(bootstrapStatus()));
	yield put(toggleLoaderAction(false));

	if (authenticatedUser()) {
		yield put(requestMyUserDataAction());
	}
}

export function* requestTokenAuthorizedWorkerSaga() {
	//[DEBUG] console.log('requestTokenAuthorizedWorkerSaga start');
	try {
		const responseToken = yield call(requestTokenAuthorizatedAPI);
		//[DEBUG] console.log('responseToken=', responseToken);
		responseToken &&
			responseToken.token.length > 20 &&
			sessionStorage.setItem('token', responseToken.token);
		yield put(setAuthenticatedAction(authenticatedUser()));
	} catch (error) {
		// eslint-disable-next-line
		console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);
	}
	yield put(toggleLoaderAction(false));
}

export function* acceptTermsForCompanyWorkerSaga({ payload }) {
	//[DEBUG] console.log('acceptTermsForCompanyWorkerSaga payload =', payload);

	try {
		yield call(acceptTermsForCompanyAPI, payload);
		yield put(requestBasicDataAction());
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
	} catch (error) {
		// eslint-disable-next-line
		console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);
	}
	yield put(toggleLoaderAction(false));
}

export function* revokeTermsForCompanyWorkerSaga({ payload }) {
	//[DEBUG] console.log('revokeTermsForCompanyWorkerSaga payload =', payload);

	try {
		yield call(revokeTermsForCompanyAPI, payload);
		yield put(requestBasicDataAction());
		const { afterCalls = [] } = payload;
		for (const afterCall of afterCalls) {
			yield put(afterCall);
		}
	} catch (error) {
		// eslint-disable-next-line
		console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);
	}
	yield put(toggleLoaderAction(false));
}

export function* logoutUserWorkerSaga() {
	//[DEBUG] console.log('logoutUserWorkerSaga start');
	try {
		yield put(toggleLoaderAction(true));
		yield call(logoutUserAPI);
		//[DEBUG] console.log('Success logout backend UI');
		sessionStorage.setItem('token', '');
		window.location = `${AUTH_URL_LOGOUT_USER_ON_CAS}`;
		yield put(toggleLoaderAction(false));
	} catch (error) {
		yield put(toggleLoaderAction(false));
		//[DEBUG] console.log('Error');
		// eslint-disable-next-line
		console.log('This is error for which the error dialog is not yet implemented. Please report next text to the developers: error -> ', error);
		sessionStorage.setItem('token', '');
		window.location = `${AUTH_URL_LOGOUT_USER_ON_CAS}`;
	}
	yield put(toggleLoaderAction(false));
}

export function* requestBasicDataSaga() {
	yield takeLeading(AUTH_REQUEST_BASIC_DATA, requestBasicDataWorkerSaga);
}

export function* requestTokenAuthorizedSaga() {
	yield takeEvery(AUTH_REQUEST_TOKEN_AUTHORIZED, requestTokenAuthorizedWorkerSaga);
}

export function* acceptTermsForCompanySaga() {
	yield takeLeading(AUTH_ACCEPT_TERMS_FOR_COMPANY, acceptTermsForCompanyWorkerSaga);
}

export function* revokeTermsForCompanySaga() {
	yield takeLeading(AUTH_REVOKE_TERMS_FOR_COMPANY, revokeTermsForCompanyWorkerSaga);
}

export function* logoutUserSaga() {
	yield takeLeading(AUTH_LOGOUT_USER, logoutUserWorkerSaga);
}

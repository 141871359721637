import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import NXPPopup from '../popup'; // Depends on NXPPopup which has redux state!!!
import createStyles from './createStyles.css';

const IdleTimerPopup = ({ email, open, onCancel, onConfirm, action, time }) => {
	const classes = createStyles();
	const { t } = useTranslation(['form', 'general']);

	const idleText = () => {
		const timer = time < 10 ? `0${time}` : time;
		return (
			<Fragment>
				<span className={classes.header} style={{ marginBottom: 10 }}>
					{t('idle_dialog_text')}
				</span>
				<span className={classes.header}>
					<span className={classes.timer}>{timer}</span> {t('seconds')}
				</span>
			</Fragment>
		);
	};

	return (
		<NXPPopup
			title={t('idle_dialog_title')}
			subtitle={email}
			description={idleText()}
			open={open}
			onCancel={onCancel}
			onConfirm={onConfirm}
			action={action}
			actionButtonLabel={t('idle_dialog_button')}
			hint={t('idle_dialog_hint')}
			hideCloseElements
			colorRed={false}
			buttonStyle={{ width: 115 }}
		/>
	);
};

IdleTimerPopup.propTypes = {
	email: PropTypes.string,
	open: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	action: PropTypes.func.isRequired,
	time: PropTypes.number.isRequired,
};

export const mapStateToProps = ({ accountReducer }) => {
	return {
		email: accountReducer.myUserData.email,
	};
};

export default connect(mapStateToProps, null)(IdleTimerPopup);

import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles.css';

export class Loader extends Component {
	static propTypes = {
		classes: PropTypes.object,
		showLoader: PropTypes.bool,
	};

	render() {
		const { showLoader, classes } = this.props;

		return (
			<Fragment>
				{showLoader && (
					<div className={classes.wrapper} data-loader-wrapper>
						<CircularProgress className={classes.loader} />
					</div>
				)}
			</Fragment>
		);
	}
}

export const mapStateToProps = ({ generalReducer }) => {
	return { showLoader: generalReducer.showLoader };
};

export default compose(connect(mapStateToProps), withStyles(styles))(Loader);

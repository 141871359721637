import {
	TAG_SET_PAGEABLE_TAGS,
	// TAG_SET_PAGEABLE_OUTCOMES_OF_TAG,
	TAG_SET_TAG_COUNTERS,
	TAG_SET_TAG_OUTCOMES,
	TAG_SET_TAGS_IMPORT_JOB_REPORT,
} from './constants';

export const initialState = {
	pageableTags: {
		content: [],
	},
	tagCountersByTag: {},
	pageableOutcomesByTag: {},
	campaignOutcomesByTag: {},
	importTagsReportByJobId: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case TAG_SET_PAGEABLE_TAGS: {
			return {
				...state,
				pageableTags: action.pageableTags,
			};
		}
		case TAG_SET_TAG_COUNTERS: {
			return {
				...state,
				tagCountersByTag: {
					...state.tagCountersByTag,
					[action.tagId]: action.tagCounters,
				},
			};
		}
		/*
		case TAG_SET_PAGEABLE_OUTCOMES_OF_TAG: {
			return {
				...state,
				pageableOutcomesByTag: {
					...state.pageableOutcomesByTag,
					[action.tagId]: action.pageableOutcomes,
				},
			};
		}
		*/
		case TAG_SET_TAG_OUTCOMES: {
			// {} -> tagId -> definitionType -> outcomes
			return {
				...state,
				campaignOutcomesByTag: {
					...state.campaignOutcomesByTag,
					[action.tagId]: {
						...state.campaignOutcomesByTag[action.tagId],
						[action.definitionType]: action.outcomesObject,
					},
				},
			};
		}
		case TAG_SET_TAGS_IMPORT_JOB_REPORT: {
			// {} -> jobId -> definitionType -> outcomes
			return {
				...state,
				importTagsReportByJobId: {
					...state.importTagsReportByJobId,
					[action.jobId]: action.importTagsReportData,
				},
			};
		}
		default:
			return state;
	}
};
